<template>
	<carousel
		:key="carouselKey"
		:adjustableHeight="false"
		v-if="showComboModal && combos.length > 0"
		:perPage="perPage"
		:navigationEnabled="true"
		:navigationNextLabel="navigationNext"
		:navigationPrevLabel="navigationPrev"
		ref="carousel"
		data-cy="carousel-0"
		pagination-active-color="#D9D9D9"
		:pagination-size="paginationSize"
		:space-padding="isDesktopBreakpoint ? 24 : 0"
	>
		<slide v-for="(comboItem,index) in combos" :key="'combo-item-'+index" data-cy="slide-0" @slideclick="handleSlideClick(comboItem)">
			<ComboItem
				:key="'combo-item-'+index"
				:combo="comboItem.data"
				:cancelModal="cancelModal"
				data-cy="ComboItem-0"
				:showDetail="false"
				class="combo-item mx-4"
			/>
		</slide>
	</carousel>
</template>

<script>
import ComboItem from '@/components/ComboItemExperimental';
import navigationNext from '@/assets/images/navigation-next.svg';
import navigationPrev from '@/assets/images/navigation-prev.svg';
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'CombosList',
  components: {
	ComboItem,
	Carousel,
	Slide,
  },
  props: {
	showComboModal: {
	  type: Boolean,
	  default: false
	},
	combos: {
	  type: Array,
	  default: () => []
	},
	cancelModal: {
	  type: Function,
	  default: () => {}
	}
  },
  data() {
	return {
		navigationNext: '<img src="' + navigationNext + '">',
		navigationPrev: '<img src="' + navigationPrev + '">',
		carouselKey: 0,
	}
  },
  emits: ['showDetail'],
  computed: {
	isDesktopBreakpoint() {
		return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
	},
	paginationSize() {
		return this.isDesktopBreakpoint ? 20 : 12;
	},
	perPage() {
		return this.isDesktopBreakpoint ? 3 : 1;
	}
  },
  methods: {
	  handleSlideClick(comboItem) {
			this.$emit('showDetail', comboItem.data.id);
	  },
  },
}
</script>

<style lang="scss">
	#combo-carousel-experimental {
		.VueCarousel-slide {
			display: block !important;
		}
		.VueCarousel-navigation-button {
			top: 97% !important;
		}
		.VueCarousel-navigation-next, .VueCarousel-navigation-prev {
			display: flex;
			align-items: center;
			img {
				height: 40px;
			}
		}
		.VueCarousel-navigation-next {
			right: 55px;
		}

		.VueCarousel-navigation-prev {
			left: 55px;
		}

		.VueCarousel-navigation {
			z-index: 100;
			bottom: 32px;
			position: absolute;
			left: 50px;
			right: 50px;
		}
		.VueCarousel-pagination {
			position: relative !important;
			bottom: 10px !important;
		}
		.VueCarousel-inner {
			display: flex;
			align-items: flex-start;
		}
	}

  /* Your styles here */
  @media only screen and (max-width: 600px) {
	#combo-carousel-experimental {
		.VueCarousel-navigation--disabled {
			display: none !important;
		}

		.VueCarousel-navigation {
			bottom: 27px;
		}

		.VueCarousel-navigation-next, .VueCarousel-navigation-prev {
			display: flex;
			align-items: center;
			img {
				height: 23px;
			}
		}

		.VueCarousel-navigation-button img {
			width: 15px;
		}

		.VueCarousel-dot-container {
			margin-top: 0;
		}

		.VueCarousel-slide {
			display: block !important;
			padding-left: 8px;
			padding-right: 8px;
		}

	}
  }
</style>
