<template data-cy="template-0">
	<v-sheet :color="!is_b2b ? 'secondaryDark' : ''" class="py-8 d-print-none" dark data-cy="v-sheet-0">
		<v-container style="max-width: 50rem" data-cy="v-container-0">
			<v-row data-cy="v-row-0">
				<v-col cols="12" :md="socialLinks.length > 0 ? 6 : 12" class="text-center text-md-left d-flex justify-center" data-cy="v-col-0">
					<v-btn :to="{ name: 'home' }" height="48" class="pa-0" text data-cy="v-btn-0">
						<img :src="logo" alt="Logo" height="42" style="max-width: 15rem"  data-cy="img-0"/>
					</v-btn>
				</v-col>
				<v-col v-if="socialLinks.length > 0" cols="12" md="6" class="d-flex flex-wrap align-center justify-center" data-cy="v-col-1">
					<v-tooltip :key="linkIdx" v-for="(link, linkIdx) in socialLinks" bottom data-cy="v-tooltip-0">
						<template v-slot:activator="{ on, attrs }" data-cy="template-1">
							<v-btn :href="link.href" target="_blank" :color="link.color" class="mx-2" icon large v-bind="attrs" v-on="on" data-cy="v-btn-1">
								<v-icon v-text="link.icon" data-cy="v-icon-0"></v-icon>
							</v-btn>
						</template>
						<span v-text="link.label"></span>
					</v-tooltip>
				</v-col>
				<v-col v-if="links.length > 0" cols="12" class="d-flex flex-wrap align-center justify-center links-container">
					<template v-for="(link, linkIdx) in links">
						<span :key="linkIdx + '_'" class="d-none d-md-inline mx-md-4" v-if="linkIdx > 0">•</span>
						<v-btn :key="linkIdx" :to="link.to" :href="link.href" :target="link.target" class="px-0 mx-1 mx-md-0 text-none w-100" text>
							<span class="w-100 text-center" v-text="link.label"></span>
						</v-btn>
					</template>
				</v-col>
				<v-col cols="12" class="text-center d-flex flex-column flex-md-row align-center justify-center">
					<div class="caption opacity-75" v-text="$t('footer.copyright', {
						company: $options.filters.translatable($root.websiteConfig.data.name, $root.websiteConfig.data.name_i18n, $i18n.locale),
						year,
					})"></div>
					<span class="mx-md-4 d-none d-md-inline">•</span>
					<a href="https://www.connectngo.com" class="white--text text-decoration-none" target="_blank">
						<div class="d-flex align-center justify-center mt-3 mt-md-0">
							<div class="caption mr-4">
								<i18n path="footer.poweredBy" tag="span">
								</i18n>
							</div>
							<img :src="konnectLogo" alt="Logo" height="24" style="max-width: 8rem" />
						</div>
					</a>
				</v-col>
			</v-row>
		</v-container>
	</v-sheet>
</template>

<script>
import logo from '@/assets/images/logo_dark.svg';

export default {
	name: 'Footer',

	props: {
		is_b2b: {
			type: Boolean,
			default: false
		},
		year: {
			type: Number,
			default: new Date().getFullYear(),
		},
		company: {
			type: String,
			default: 'Connect&Go',
		},
		links: {
			type: Array,
			default: () => ([])
		},
		socialLinks: {
			type: Array,
			default: () => ([])
		},
	},
	computed: {
		logo() {
			return this.$root.websiteConfig.data.images['logo-footer']
				? this.$root.websiteConfig.data.images['logo-footer']
				: this.$root.websiteConfig.data.images['logo-header']
					? this.$root.websiteConfig.data.images['logo-header']
					: logo;
		},
	},

	data: () => ({
		konnectLogo: logo,
	}),
}
</script>

<style lang="scss" scoped>
.v-btn--active::before {
	opacity: 0;
}

*::v-deep .v-btn__content{
	width:100% !important;
}

@media (max-width: 700px) {
	.links-container {
		flex-direction: column;
	}
}
</style>
