<template data-cy="template-0">
		<v-container style="max-width: 35rem" data-cy="v-container-0">
			<v-form :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="create_account_form" v-model="formIsValid" @submit="submit" lazy-validation data-cy="v-form-0">

				<h3 class="font-weight-regular text-center ma-4" v-text="$route.query.is_creation ? $t('loginForm.createPassword') : $t('loginForm.resetPassword') " data-cy="h3-0"></h3>

				<v-text-field
					v-model="data.password"
					:label="$t('input.password')"
					:error-messages="formErrors.password"
					:rules="[rules.required, rules.min, rules.password]"
					:type="showPassword ? 'text' : 'password'"
					:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					autocomplete="password"
					outlined
					background-color="white"
					@click:append="showPassword = !showPassword"
					@input="formErrors = {}"
				 data-cy="v-text-field-0"></v-text-field>

				<v-text-field
					v-model="data.confirmation"
					:label="$t('input.confirmation')"
					:error-messages="formErrors.password"
					:rules="[rules.required, rules.min, rules.password]"
					:type="showPassword ? 'text' : 'password'"
					:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					autocomplete="password"
					outlined
					background-color="white"
					@click:append="showPassword = !showPassword"
					@input="formErrors = {}"
				 data-cy="v-text-field-1"></v-text-field>

				<StrengthIndicator class="my-4" :title="$t('createAccountForm.passwordMustContain')" :conditions="passwordConditions" data-cy="StrengthIndicator-0"></StrengthIndicator>

				<v-btn type="submit" color="button" data-test-selector="create_account_form_sign_int" :disabled="loading" :loading="loading" block data-cy="v-btn-0">
					<span v-text="$t('resetPasswordForm.resetBtn')" data-cy="span-0"></span>
				</v-btn>

			</v-form>

		</v-container>
</template>

<script>
import {EComService, Rules} from "@connectngo/sdk";
import StrengthIndicator from "@/components/StrengthIndicator";
import PasswordConditions from "@/mixins/PasswordConditions";

export default {
	name: "ResetPasswordForm",

	components: { StrengthIndicator },

	mixins: [ PasswordConditions() ],

	data() {
		return {
			loading: false,
			formIsValid: false,
			formErrors: {},
			data: {
				email :this.$route.query.email,
				token : this.$route.query.token,
				password: '',
				confirmation: '',
			},
			showPassword: false,
			ecomService : new EComService(),
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
				password: () => Rules.identical(this.data.password, this.data.confirmation) || this.$t('rules.identical'),
				conditions : () => Rules.conditions(this.passwordConditions, this.data.password)
			},

		};
	},

	methods: {

		submit(event) {

			event.preventDefault();
			this.formErrors = {};

			if (this.$refs.form.validate() && this.rules.conditions()) {
				this.loading = true;
				new EComService().resetPassword(
					this.data.email,
					this.data.token,
					this.data.password,
				)
					.then(account => {

						this.$snack(this.$i18n.t('alert.passwordUpdated'), 'success');
					})
					.catch(error => {
            this.$handleError(this, error)
          })
					.finally(() => this.loading = false);
			}
		},
	}

	}
</script>

<style scoped>

</style>
