<template data-cy="template-0">
<div data-cy="div-0">
	<v-skeleton-loader boilerplate v-if="loading" type="list-item, list-item, list-item" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-container v-else data-cy="v-container-0">
		<v-row  v-for="(reservation,index) in reservations" class="w-full mx-0 pa-4 white rounded mb-6" :key="index" :class="{'pa-2': isMobile}" data-cy="v-row-0">
			<v-col class="title teal--text font-weight-medium pa-0 col-3" :class="{'col-6': mediumWindow, 'col-12 caption':  isMobile }" data-cy="v-col-0">
				<v-icon :size="isMobile && 'small' || 'xl'" color="teal	" data-cy="v-icon-0">mdi-calendar-plus</v-icon>
				{{ reservation.data.status === 'PAID' && reservation.data.transaction_id || reservation.data.reservation_id}}
			</v-col>
			<v-col class="subtitle-1 grey--text ma-0 text--lighten-1 font-weight-normal pa-0 col-4" :class="{'col-6': mediumWindow, 'col-12 caption':  isMobile }" data-cy="v-col-1">
				{{ $t('reservation.myaccount.visiting_date') }}<span class="font-weight-bold" data-cy="span-0">{{ formatDate(reservation.data.reservation_date)	}}</span>
			</v-col>

			<v-col class="subtitle-1 grey--text ma-0 text-lighten-1 text-left font-weight-normal pa-0 col-3" :class="{'col-6': mediumWindow, 'col-12 caption':  isMobile }" data-cy="v-col-2">
				{{ $t('reservation.myaccount.status') }}<span class="font-weight-bold text--lighten-1"
				:class="statusData[reservation.data.status].color"
				v-text="statusData[reservation.data.status].text"></span>
			</v-col>

			<v-col class="pa-0 col-2 subtitle-1" :class="{'text-right': normalWindow , 'col-6 text-left': mediumWindow, 'col-12 caption':  isMobile }" data-cy="v-col-3">
				<router-link class="text-decoration-none  teal--text" :to="{ name: 'reservation', params: { resId: reservation.data.id }}" data-cy="router-link-0">
					<span v-text="$t('reservation.see_details')" data-cy="span-2"></span>
					<v-icon :size="isMobile && 'small' || 'large'"  color="teal" data-cy="v-icon-1">mdi-arrow-right</v-icon>
				</router-link>
			</v-col>
		</v-row>
	</v-container>
</div>
</template>

<script>
import { WindowWidthMixin } from '@/mixins/ProductMixin';
import globalVariables from '@/global';
import { EComService, TransactionModel } from '@connectngo/sdk';

export default {
	name: 'Reservations',

	mixins: [WindowWidthMixin],

	data: () => ({
		loading: false,
		reservations: new TransactionModel(),
	}),

	computed: {
		mediumWindow() {
			return this.windowWidth > 960 && this.windowWidth < 1265 || this.windowWidth < 860 && !this.isMobile;
		},
		normalWindow() {
			return this.windowWidth > 1240 || this.windowWidth > 860 && this.windowWidth < 960 || this.isMobile;
		},
		statusData(){
			return {
				QUOTE: {
					text: this.$t('reservation.quote'),
					color: 'purple--text'
				},
				CONFIRMED: {
					text: this.$t('reservation.confirmed'),
					color: 'warning--text'
				},
				PAID: {
					text: this.$t('reservation.paid'),
					color: 'success--text'
				},
				CANCELLED: {
					text: this.$t('reservation.cancelled'),
					color: 'error--text'
				}
			};
		}
	},
	methods: {
		load() {
			this.loading = true;
			new EComService().getReservationsByUserAccount(this.$root.user.data.id)
			.then((reservations) => {
				this.reservations = reservations;
				this.$breadcrumbs.refresh();
			})
			.catch(reason => {this.$handleError(this, reason)}
			)
			.finally(() => (this.loading = false));

		},
		formatDate(date) {
			return this.$options.filters.tsToFormat(date, this.$t('date.format.ymd'));
		}
	},
	created() {
		if (globalVariables.websiteConfig.featureFlagEnabled('reservation-v2')) {
				this.load();
			} else {
				this.$router.push({ name: 'home' });
			}
	},

}
</script>

<style lang="scss" scoped>

</style>
