<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="list-item" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card data-testid="transactionCard" v-else v-bind="$attrs" v-on="$listeners" :to="to" data-cy="v-card-0">
		<div v-if="!value.data.exchange" class="d-flex" style="gap: 0.5rem" data-cy="div-0">
			<QrCode data-testid="transactionQRCode" v-if="_hiddeQrCode" v-model="value.data.barcode"  data-cy="QrCode-0"/>
			<v-container v-if="value.data.type !== 'RESERVATION'" data-cy="v-container-0">
				<v-row data-cy="v-row-0">
					<v-col sm="4" class="py-md-6" data-cy="v-col-0">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.orderNumber')" data-cy="div-1"></div>
						<h3 data-testid="orderNumber" class="secondaryDark--text my-1" v-text="(value.data.transaction_id || '').toUpperCase()" data-cy="h3-0"></h3>
						<h5 class="red--text" v-if="this.value.data.isReturned" data-cy="h5-0">Rescheduled</h5>
						<h6 data-testid="transactionNumber" v-else v-text="$t('transactionCard.id', {
							number: value.data.barcode,
						})" data-cy="h6-0"></h6>
					</v-col>
					<v-col sm="4" class="py-md-6" data-cy="v-col-1">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.date')" data-cy="div-2"></div>
						<h3 data-testid="transactionDate" class="my-1" v-text="$options.filters.tsToFormat(value.data.created_at, $t('date.format.ymd'))" data-cy="h3-1"></h3>
					</v-col>
					<v-col sm="4" class="py-md-6" data-cy="v-col-2">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.amount')" data-cy="div-3"></div>
						<h3 data-testid="transactionTotal" class="my-1" v-text="$options.filters.currency(value.data.total || value.data.amount)" data-cy="h3-2"></h3>

						<div v-if="!hideDetails && to" class="d-flex justify-end mt-8 mt-md-1" data-cy="div-4">
							<router-link class="text-decoration-none d-flex align-center" :to="to" data-cy="router-link-0">
								<span v-text="$t('transactionCard.viewDetails')" data-cy="span-0"></span>
								<v-icon right x-small data-cy="v-icon-0">mdi-arrow-right</v-icon>
							</router-link>
						</div>
					</v-col>
				</v-row>
			</v-container>

			<v-container v-if="value.data.type === 'RESERVATION'" data-cy="v-container-1">
				<v-row data-cy="v-row-1">
					<v-col sm="2" class="py-md-6" data-cy="v-col-3">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationNumber')" data-cy="div-5"></div>
						<h3 class="secondaryDark--text my-1" v-text="(value.data.reservation_id || '').toUpperCase()" data-cy="h3-3"></h3>
						<h6 v-text="$t('transactionCard.id', {
							number: value.data.uuid,
						})" data-cy="h6-1"></h6>
					</v-col>
					<v-col sm="2" class="py-md-6" data-cy="v-col-4">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservedOn')" data-cy="div-6"></div>
						<h3 class="my-1" v-text="$options.filters.tsToFormat(value.data.created_at, $t('date.format.ymd'))" data-cy="h3-4"></h3>
					</v-col>
					<v-col sm="2" class="py-md-6" data-cy="v-col-5">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationDate')" data-cy="div-7"></div>
						<h3 class="my-1" v-text="$options.filters.tsToFormat(value.data.reservation_date, $t('date.format.ymd'))" data-cy="h3-5"></h3>
					</v-col>
					<v-col sm="2" class="py-md-6" data-cy="v-col-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.amount')" data-cy="div-8"></div>
						<h3 class="my-1" v-text="$options.filters.currency(value.data.total)" data-cy="h3-6"></h3>
					</v-col>
					<v-col sm="4" class="py-md-6" data-cy="v-col-7">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationBalance')" data-cy="div-9"></div>
						<h3 class="my-1" v-text="$options.filters.currency(value.data.balance)" data-cy="h3-7"></h3>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-card>
</template>

<script>
import QrCode from '@/components/QrCode';
import { BaseModel } from '@connectngo/sdk';

export default {
	name: 'TransactionCard',

	components: { QrCode },

	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		hideQrCode: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		to: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'transactions' } }),
		},
	},
	computed: {
		_hiddeQrCode() {
			return !((this.value.data.isReturned) || this.hideQrCode) && this.value.data.type !== 'RESERVATION'
		},
	}
}
</script>
