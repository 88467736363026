<template data-cy="template-0">
	<div class="container-carousel">
		<v-skeleton-loader v-if="skeleton" type="image" :height="_height"  data-cy="v-skeleton-loader-0"/>
		<v-carousel v-else-if="value.results" ref="carousel" v-model="index" v-bind="$attrs" v-on="$listeners" :cycle="internalCycle" :interval="interval" :height="_height" :show-arrows="false" hide-delimiters data-cy="v-carousel-0">
			<v-sheet v-if="value.results.length > 0" class="w-100" color="rgba(0, 0, 0, 0.2)" style="position: absolute; bottom: 0; left: 0; z-index: 3; backdrop-filter: blur(0.25rem); max-width:100%;" dark @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" data-cy="v-sheet-0">
				<v-container class="container-text text-center pa-4" data-cy="v-container-0">
					<h4 class="subtitle-2 font-weight-bold text-uppercase" data-cy="h4-0"></h4>
					<h3 class="text-md-h4 text-h5 my-md-3 my-1 font-weight-light"  data-cy="h3-0"></h3>

					<div class="d-flex align-center justify-content-center mt-md-6 mt-2" data-cy="div-0">
						<div v-if="canShowArrows" class="mr-4" style="flex: 0" data-cy="div-1">
							<v-btn icon x-large @click="handlePrevClick" data-cy="v-btn-0">
								<v-icon x-large data-cy="v-icon-0">mdi-chevron-left</v-icon>
							</v-btn>
						</div>
							<div style="flex: 1" v-if="value.results[index].data.type === 'product'" data-cy="div-2">
								<v-btn class="span-button" :to="{ name: 'product', params: { slug: value.results[index].data.item.slug } }" outlined large :block="$vuetify.breakpoint.smAndDown" data-cy="v-btn-1">
									<span v-html="$options.filters.translatable(value.results[index].data.item.name, value.results[index].data.item.name_i18n, $i18n.locale)" data-cy="span-0"></span>
								</v-btn>
							</div>
							<div style="flex: 1" v-else-if="value.results[index].data.type === 'products_tags'" data-cy="div-3">
								<v-btn class="span-button" :to="{ name: 'tag', params: { key: value.results[index].data.item.slug } }" :href="value.results[index].data.item.href" outlined large :block="$vuetify.breakpoint.smAndDown" data-cy="v-btn-2">
									<span v-html="$options.filters.translatable(value.results[index].data.item.name, value.results[index].data.item.name_i18n, $i18n.locale)" data-cy="span-1"></span>
								</v-btn>
							</div>
							<div style="flex: 1" v-else-if="value.results[index].data.type === 'url'" data-cy="div-4">
								<v-btn class="span-button" :href="value.results[index].data.item.value" outlined large :block="$vuetify.breakpoint.smAndDown" data-cy="v-btn-3">
									<span v-html="value.results[index].data.item.name_i18n" data-cy="span-2"></span>
								</v-btn>
							</div>
						<div v-if="canShowArrows" class="ml-4" style="flex: 0" data-cy="div-5">
							<v-btn icon x-large @click="handleNextClick" data-cy="v-btn-4">
								<v-icon x-large data-cy="v-icon-1">mdi-chevron-right</v-icon>
							</v-btn>
						</div>
					</div>
				</v-container>
				<v-progress-linear v-if="cycle || pause" v-model="progress" data-cy="v-progress-linear-0"></v-progress-linear>
			</v-sheet>
			<v-carousel-item v-for="(item, itemIdx) in value.results" :key="itemIdx" v-bind="item.attrs" v-on="item.listeners" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" data-cy="v-carousel-item-0">
				<v-img :src="item.data.image_url" style="max-height: 500px" aspect-ratio="2.5"></v-img>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'Carousel',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		showArrows: {
			type: Boolean,
			default: true,
		},
		cycle: {
			type: Boolean,
			default: false,
		},
		interval: {
			type: Number,
			default: 6000,
		},
		height: {
			type: Number,
			default: null,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		pause: false,
		internalCycle: false,
		index: 0,
		progress: 0,
		pauseTime: 0,
		pauseDiff: 0,
		originalInterval: 0,
	}),

	computed: {
		canShowArrows() {
			return this.showArrows && this.value.results.length > 1;
		},
		_height() {
			return "auto";
		},
	},

	mounted() {
		this.resetProgress();
	},

	methods: {
		handleMouseEnter() {
			if (!this.cycle) {
				return;
			}
			this.pause = true;
			this.pauseTime = new Date().getTime();
			this.internalCycle = false;
		},
		handleMouseLeave() {
			if (!this.cycle) {
				return;
			}

			this.pause = false;
			this.pauseDiff = new Date().getTime() - this.pauseTime;
			this.internalCycle = true;
		},
		handlePrevClick() {
			this.$refs.carousel.prev();
		},
		handleNextClick() {
			this.$refs.carousel.next();
		},
		resetProgress() {
			this.progress = 0;
			if (this.cycle) {
				let lastTime = new Date().getTime();
				const interval = this.interval;
				const updateProgress = () => {
					requestAnimationFrame(() => {

						if (this.pause && this.progress < 100) {
							lastTime = new Date().getTime();
							return updateProgress();
						}

						this.pauseDiff = 0;

						const currentTime = new Date().getTime();
						this.progress = (currentTime - lastTime) * 100 / interval;
						if (this.progress < 100) {
							updateProgress();
						}
					});
				}
				updateProgress();
			}
		},
	},

	watch: {
		index() {
			this.resetProgress();
		},
		cycle: {
			immediate: true,
			handler(value) {
				this.internalCycle = value;
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.v-progress-linear::v-deep * {
	transition: none;
}
.v-skeleton-loader ::v-deep .v-skeleton-loader__image {
	height: 100%;
}
.container-carousel {
	max-height: 500px;
}

.container-text{
	width:100%;
	max-width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.justify-content-center{
	justify-content: center;
}

@media (max-width:530px) {
	.span-button{
		white-space: normal;
		display: grid;
		width: 100%;
		height: auto !important;
		min-height: 44px;
	}
}
</style>
