<template data-cy="template-0">
	<VueGooglePlaces
		:api-key="apiKey"
		:enable-geolocation="true"
		@placechanged="$emit('update', $event)"
		data-cy="VueGooglePlaces-0">
		<v-text-field
			v-model="_value"
			v-bind="$attrs"
			v-on="$listeners"
			data-cy="v-text-field-0"/>
	</VueGooglePlaces>
</template>

<script>
import { VueGooglePlaces } from 'vue-google-places'

export default {
	name: 'AddressAutocompleteField',

	components: { VueGooglePlaces },

	props: {
		value: {
			type: String,
			default: '',
		},
	},

	data: () => ({
		apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
}
</script>

<style lang="scss">
.pac-target-input::placeholder {
	color: transparent;
	opacity: 0;
}
.pac-container {
	box-shadow: none;
	border: 0;
}
</style>
