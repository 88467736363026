<template data-cy="template-0">
	<PrePurchasedAssignCard :value="value" class="pa-0" flat readonly hide-title  data-cy="PrePurchasedAssignCard-0"/>
</template>

<script>
import PrePurchasedAssignCard from '@/components/PrePurchasedAssignCard';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletPrePurchasedListing',

	components: { PrePurchasedAssignCard },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
	},
}
</script>
