<template data-cy="template-0">
	<v-menu transition="slide-y-transition" bottom data-cy="v-menu-0">
		<template v-slot:activator="{ on: menu }" data-cy="template-1">
			<v-tooltip bottom data-cy="v-tooltip-0">
				<template v-slot:activator="{ on: tooltip }" data-cy="template-2">
					<v-btn data-testid="languageSwitcher" v-bind="$attrs" :class="btnClass" v-on="{ ...tooltip, ...menu, ...$listeners }" :disabled="languages.length === 0" data-cy="v-btn-0">
						<span data-cy="span-0">
							<span v-if="!isLoading" class="text-uppercase" data-cy="span-1">{{ getSelectedLang() }}</span>
							<span v-else data-cy="span-2">
								<v-icon class="mdi-spin" data-cy="v-icon-0">mdi-loading</v-icon>
							</span>
						</span>
						<v-icon right data-cy="v-icon-1">mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<span v-text="$t('languageSwitcher.tooltip')"></span>
			</v-tooltip>
		</template>
		<v-list>
			<v-list-item :data-test-selector="'language_switcher_' + lang.abbreviation" v-for="(lang, i) in _languages" :key="i" @click="switchLang(lang.abbreviation)">
				<v-list-item-title :class="{ 'font-weight-bold': lang.abbreviation === $i18n.locale }">
					{{ lang.originTitle }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { availableLanguages } from '@/locales';
import { Cookies, Server, } from "@connectngo/sdk";

export default Vue.extend({
	name: 'LanguageSwitcher',

	props: {
		value: {
			type: String,
			default: 'en',
		},
		btnClass: {
			type: String,
			default: null,
		},
	},

	computed: {
		_languages() {
			return this.$root.websiteConfig.data.tenant.locales.map(locale => ({
				originTitle: this.$options.filters.translatable(locale.name, locale.name_i18n, this.$i18n.locale),
				abbreviation: locale.locale,
			}));
		},
	},

	methods: {

		getSelectedLang() {

			const lang = this.languages.find(lang => lang.abbreviation === this.value);
			if (lang) {
				return lang.abbreviation;
			}

			const defaultLang = this.languages.find(lang => lang.abbreviation === this.$i18n.locale);
			if (defaultLang) {
				return defaultLang.abbreviation;
			}

			return null;
		},

		switchLang(lang) {
			this.applyLang(lang);
			this.$emit('input', lang);
		},

		applyLang(lang) {

			const cookieDate = new Date();
			cookieDate.setMonth(cookieDate.getMonth() + 12);
			Cookies.set("lang", lang, cookieDate, '/', process.env.VUE_APP_COOKIE_DOMAIN);
			Server.setLocale(lang);

			this.$i18n.locale = lang;
			moment.locale(lang);
			const event = new Event('resize');
			window.dispatchEvent(event);

			this.$router.replace({...this.$route, params: { ...this.$route.params, lang }});
		}
	},

	data() {
		return {
			isLoading: false,
			lang: this.value || this.$i18n.locale,
			languages: availableLanguages.map(availableLanguage => ({
				originTitle: this.$i18n.t('language.' + availableLanguage),
				abbreviation: availableLanguage
			}))
		};
	},

	watch: {
		value: function (val) {
			this.lang = val || this.$i18n.locale;
			this.switchLang(this.lang);
		},
	}
});
</script>
