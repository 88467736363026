<template data-cy="template-0">
	<v-snackbar v-bind="$attrs" v-on="$listeners" :color="snack.color" v-model="snack.visible" data-cy="v-snackbar-0">
		<div class="d-flex align-center" data-cy="div-0">
			<v-icon class="white--text" v-text="snack.icon" left data-cy="v-icon-0"></v-icon>
			<span data-testid="snackMessage" v-text="snack.content" class="mr-4" data-cy="span-0"></span>
			<v-btn text @click="snack.visible = false" data-test-selector="snack_close" data-cy="v-btn-0">
				{{ $t('modal.close') }}
			</v-btn>
		</div>
	</v-snackbar>
</template>

<script>
export default {
	name: "Snack",

	props: {
		snack: {
			type: Object,
			default: () => ({
				visible: false,
				color: 'primary',
				content: '',
				icon: 'mdi-check',
			}),
		}
	},
}
</script>
