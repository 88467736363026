<template data-cy="template-0">
	<a :href="value" v-text="$t('goBackUp.label')" @click.prevent="$vuetify.goTo(value)" data-cy="a-0"></a>
</template>

<script>
export default {
	name: 'GoBackUp',

	props: {
		value: {
			type: String,
			default: '#app',
		},
	},
}
</script>
