import { render, staticRenderFns } from "./ReceivableStatement.vue?vue&type=template&id=72d461fe&scoped=true&data-cy=template-0&"
import script from "./ReceivableStatement.vue?vue&type=script&lang=js&"
export * from "./ReceivableStatement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d461fe",
  null
  
)

export default component.exports