<template data-cy="template-0">
	<v-dialog v-model="_visible" v-bind="$attrs" v-on="$listeners" :persistent="persistent" data-cy="v-dialog-0">
		<v-card data-cy="v-card-0" :id="id">
			<slot v-if="!hideHeader" name="header" data-cy="slot-0">
				<v-card-title :class="headerClass" class="d-flex flex-nowrap text-left transparent" style="justify-content: space-between !important" data-cy="v-card-title-0">
					<div class="d-flex flex-column">
						<span class="headline text-truncate mb-1" v-text="title" data-cy="span-0"></span>
						<span class="subtitle text-truncate" v-text="subtitle" v-if="subtitle" data-cy="span-1"></span>
					</div>
					<v-btn v-if="!noClose" @click="_visible = false" class="mr-n2" data-test-selector="modal_title_close" icon data-cy="v-btn-0">
						<v-icon data-cy="v-icon-0">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
			</slot>
			<slot v-if="!hideBody" name="body" data-cy="slot-1">
				<v-card-text data-cy="v-card-text-0">
					<span v-html="body" data-cy="span-1"></span>
				</v-card-text>
			</slot>
			<slot v-if="!hideFooter" name="footer" data-cy="slot-2">
				<v-card-actions :class="footerClass" data-cy="v-card-actions-0">
					<v-divider data-cy="v-divider-0" />
					<slot name="actions" data-cy="slot-3">
						<div class="d-flex flex-column flex-md-row w-100 justify-end" style="gap: 0.5rem" data-cy="div-0">
							<slot name="buttons" data-cy="slot-4">
								<v-btn :key="btnIdx" v-for="(btn, btnIdx) in buttons" v-bind="btn.attrs" v-on="btn.events" :block="$vuetify.breakpoint.smAndDown" :outlined="btn.type === 'cancel' || (btn.attrs && btn.attrs.outlined)" large @click="btn.type === 'cancel' ? _visible = false : null" data-cy="v-btn-1">
									<v-icon v-if="btn.icon" v-text="btn.icon" left data-cy="v-icon-1"></v-icon>
									<span v-if="btn.type === 'cancel'" v-text="$t('btn.cancel')" data-cy="span-2"></span>
									<span v-else v-text="btn.text" data-cy="span-3"></span>
								</v-btn>
							</slot>
						</div>
					</slot>
				</v-card-actions>
			</slot>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "Modal",

	props: {
		id: {
			type: String,
			default: null,
		},
		value: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
			required: false,
		},
		body: {
			type: String,
			default: null,
		},
		buttons: {
			type: Array,
			default: null,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideBody: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		noClose: {
			type: Boolean,
			default: false,
		},
		footerClass: {
			type: String,
			default: null
		},
		headerClass: {
			type: String,
			default: null
		},
		persistent: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		defaultButtons: [],
	}),

	computed: {
		_visible: {
			get() {
				return this.value;
			},
			set(value) {
				if (!value) {
					this.$emit('close');
				}
				this.$emit('input', value);
			},
		},
		_buttons() {
			return this.buttons === null ? this.defaultButtons : this.buttons;
		},
	},

	created() {
		if (this.buttons === null) {
			this.defaultButtons = [
				{ i18n: 'btn.close', events: {
					click: () => {
						this._visible = false;
					}
				}}
			];
		}
	}
}
</script>
