var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"color":"neutralLight","breadcrumbs":"","force-showing-sidebar":"","data-cy":"Page-0"},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('v-list',{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown,"rounded":"","data-cy":"v-list-0"}},[_c('v-list-item',{staticClass:"py-4",attrs:{"data-cy":"v-list-item-0"}},[(_vm.$route.params.is_b2b )?_c('v-list-item-title',{staticClass:"text-uppercase",attrs:{"data-cy":"v-list-item-title-0"}},[(_vm.$root.user.data.company_name == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('account.company_missing'))+" ")]):_c('span',{attrs:{"data-cy":"span-0"},domProps:{"textContent":_vm._s(_vm.$t('toolbar.welcomeUser', {
						name:  _vm.$root.user.data.company_name
					}))}})]):_c('v-list-item-title',{staticClass:"text-uppercase",attrs:{"data-cy":"v-list-item-title-0"}},[_c('span',{attrs:{"data-cy":"span-0"},domProps:{"textContent":_vm._s(_vm.$t('toolbar.welcomeUser', {
						name: _vm.$root.user.data.firstname
					}))}})])],1),_vm._l((_vm.menuLinks),function(link,linkIdx){return _c('v-list-item',_vm._g(_vm._b({key:linkIdx,class:link.classes,attrs:{"color":"secondaryDark","exact":link.exact,"data-cy":"v-list-item-2"}},'v-list-item',link,false),link.events),[_c('v-list-item-icon',{attrs:{"data-cy":"v-list-item-icon-0"}},[_c('v-icon',{attrs:{"data-cy":"v-icon-0"},domProps:{"textContent":_vm._s(link.icon)}})],1),_c('v-list-item-title',{attrs:{"data-cy":"v-list-item-title-1"},domProps:{"textContent":_vm._s(_vm.$t('accountMenu.' + link.key))}})],1)})],2)]},proxy:true}])},[_c('v-container',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","lg":"6"}},[(_vm.$route.params.is_b2b && _vm.$route.name == 'account')?_c('h1',{staticClass:"display-1"},[(_vm.$root.user.data.company_name == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('account.company_missing'))+" ")]):_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.pages[_vm.$route.params.section || 'index'].title, {
						name: this.$root.user.data.company_name,
					}) || _vm.$t('route.account_' + _vm.$route.params.section))}})]):_c('h1',{staticClass:"display-1",domProps:{"textContent":_vm._s(_vm.$t(_vm.pages[_vm.$route.params.section || 'index'].title, {
						name: this.$root.user.data.firstname,
					}) || _vm.$t('route.account_' + _vm.$route.params.section))}})]),_c('v-col',{staticClass:"d-flex flex-column justify-center align-end d-print-none",attrs:{"cols":"12","lg":"6"}},[_c('v-btn',{class:{
					'py-3 v-size--stretch': true,
				},attrs:{"color":"button","to":{ name: 'home' },"block":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-icon',{attrs:{"x-large":"","left":""}},[_vm._v("mdi-cart-outline")]),_c('span',{class:{
						'text-left ml-3 text-none': true,
						'text-wrap': _vm.$vuetify.breakpoint.mdAndDown,
					},staticStyle:{"width":"min-content","margin":"auto","flex":"1"}},[_c('strong',{domProps:{"textContent":_vm._s(_vm.$t('account.goToShopBtn.title'))}}),_c('br'),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('account.goToShopBtn.desc'))}})])],1)],1)],1)],1),_c('component',_vm._b({},'component',_vm.pages[_vm.$route.params.section || 'index'],false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }