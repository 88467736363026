var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"data-cy":"v-container-0"}},[_c('v-row',{staticClass:"mb-8",attrs:{"data-cy":"v-row-0"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","data-cy":"v-col-0"}},[_c('h2',{staticClass:"display-1 font-weight-light",attrs:{"data-cy":"h2-0"},domProps:{"textContent":_vm._s(_vm.$t('account.wallet.title', {
				name: _vm.account.data.firstname,
			}))}})])],1),(_vm.items.results.length === 0)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"info","data-cy":"v-alert-0"}},[_c('span',{attrs:{"data-cy":"span-0"},domProps:{"textContent":_vm._s(_vm.$t('account.wallet.empty'))}})]):_vm._e(),_c('v-row',{attrs:{"data-cy":"v-row-1"}},_vm._l((_vm.items.results),function(item){return _c('v-col',{key:item.guid,attrs:{"cols":"12","lg":"6","data-cy":"v-col-1"}},[_c('VirtualWalletCard',{attrs:{"value":item,"user-name":_vm.account.data.firstname,"skeleton":_vm.skeleton,"route":{
				name: 'account_section_sub_action_param1', params: {
					section: 'connected',
					sub: _vm.account.data.id,
					action: 'wallet',
					param1: item.data.id,
				}
			},"data-cy":"VirtualWalletCard-0"}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }