var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 container px-0",class:_vm.className},[(_vm.isDesktopBreakpoint && _vm.showTitle)?[(_vm.showDetails)?_c('div',[_c('h1',[_vm._v("Your title here")]),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Choose your time of visit")])]):_vm._e(),(_vm.isDesktopBreakpoint && !_vm.hideHeader)?_c('div',{directives:[{name:"else",rawName:"v-else"}],staticClass:"mb-2 select__title"},[_c('li',{staticClass:"ml-2 font-weight-bold",domProps:{"textContent":_vm._s(
					_vm.$t('route.tags.combos.select.choose_your_time_of_visit')
				)}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" ( "+_vm._s(_vm.product.data.name)+" )")])]):_vm._e(),_vm._l((_vm.otherSelectedTimeslotsText),function(text){return _c('p',{key:text,staticClass:"text-previous-selections highlighted mb-0"},[_vm._v(" *"+_vm._s(text)+" ")])}),(!_vm.showDetails)?_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"elevation-0"},[_c('v-expansion-panel-header',{staticClass:"text-black"},[_vm._v(" "+_vm._s(_vm.selectedTime ? _vm.selectedTime?.period : "00:00am - 00:00pm")+" ")]),_c('v-expansion-panel-content',{staticClass:"px-0"},[_c('v-list',{staticClass:"d-flex flex-column"},_vm._l((_vm.timeSlots),function(timeSlot,index){return _c('v-list-item',{key:index,staticClass:"px-6",on:{"click":function($event){return _vm.onSelectTimeslotAndCollapsePanel(timeSlot)}}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between w-100",class:{ highlighted: _vm.isHighlighted(timeSlot) }},[_c('div',{staticClass:"text-left flex-grow-1",domProps:{"textContent":_vm._s(timeSlot.period)}}),_c('div',{staticClass:"text-left flex-grow-1",domProps:{"textContent":_vm._s(
											`${timeSlot.amountLeft} left`
										)}})])])],1)}),1)],1)],1)],1):_c('v-list',{staticClass:"d-flex flex-column"},_vm._l((_vm.timeSlots),function(timeSlot,index){return _c('v-list-item',{key:index,staticClass:"px-6",on:{"click":function($event){return _vm.selectTime(timeSlot)}}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between w-100"},[_c('div',{staticClass:"text-left flex-grow-1",domProps:{"textContent":_vm._s(timeSlot.period)}}),_c('div',{staticClass:"text-left flex-grow-1",domProps:{"textContent":_vm._s(`${timeSlot.amountLeft} left`)}})])])],1)}),1)]:_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"elevation-0 pb-3"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.panel === 0)?_c('v-icon',{class:{
							'mdi-chevron-down': _vm.panel === 0,
							'mdi-chevron-right': _vm.panel !== 0,
						}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])},[_c('div',[(_vm.selectedTime?.period)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","data-cy":"v-icon-0"}},[_vm._v(" mdi-check ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.product.data.name)+" "+_vm._s(_vm.progress)+" ")],1)]),_vm._l((_vm.otherSelectedTimeslotsText),function(text,index){return _c('p',{key:text,staticClass:"text-previous-selections highlighted"},[_vm._v("*"+_vm._s(text))])}),_c('v-divider',{staticClass:"mx-6"}),_c('v-expansion-panel-content',_vm._l((_vm.timeSlots),function(timeSlot){return _c('div',{key:timeSlot.period,staticClass:"checkbox-container pl-6 py-1 pr-4 d-flex justify-space-between align-center",class:{
						highlighted: _vm.isHighlighted(timeSlot),
						highlightedRow: _vm.checked[timeSlot.id] && _vm.isDesktopBreakpoint,
					}},[_c('v-checkbox',{attrs:{"type":"checkbox","label":`${timeSlot.period}`,"hide-details":""},on:{"change":function($event){return _vm.onCheckboxSelectTime(timeSlot)}},model:{value:(_vm.checked[timeSlot.id]),callback:function ($$v) {_vm.$set(_vm.checked, timeSlot.id, $$v)},expression:"checked[timeSlot.id]"}}),_c('span',[_vm._v(_vm._s(_vm.getAmountLeftText(timeSlot)))])],1)}),0)],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }