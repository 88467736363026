<template>
	<Page color="neutralLight" breadcrumbs no-sidebar show-combos data-cy="Page-0">
		<v-container class="px-8 py-8">
			<v-row :class="{ 'flex-column d-flex': !isDesktopBreakpoint }">
				<v-col cols="12" sm="6">
					<v-card class="px-0 py-0">
						<div class="w-100 h-100 image-container">
							<v-img :src="placeholderImage" aspect-ratio="2">
								<v-overlay absolute top left v-if="combo.promotion">
									<div class="tag" v-text="$t('route.tags.combos.promotion')"></div>
								</v-overlay>
							</v-img>
						</div>
						<v-card-text class="pb-8">
							<combo-detail-intro
								:productsSummary="productsSummary"
								:initialState="false"
								:combo-description="comboDescription"
								:combo-products-desc="comboProductsDesc"
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<Combo
					:combo="combo"
					:skeleton="skeleton"
					:showCalendar="showCalendar"
				/>
			</v-row>
			<section class="mt-6">
				<div class="container carousel-header d-flex mb-6 justify-space-between align-center">
					<h2>Other option to discover</h2>
					<a href="">See all our options</a>
				</div>
				<carousel
					hide-delimiters
					height="400px"
					:show-arrows="true"
					:per-page="isDesktopBreakpoint ? 2.5 : 1"
					class="carousel"
					:cycle="true"
					:navigationEnabled="true"
					:navigationNextLabel="navigationNext"
					:navigationPrevLabel="navigationPrev"
					pagination-active-color="#009AA6"
					pagination-color="#D9D9D9"
					:pagination-size=20
					:spacePadding=20
					:spacePaddingMaxOffsetFactor=-2
				>
					<slide
						v-for="(product, index) in otherProductsMock"
						:key="index"
						class="slide"
					>
						<OtherProduct
							:title="product.title"
							:originalPrice="product.originalPrice"
							:actualPrice="product.actualPrice"
							:discount="product.discount"
							:image-src="product.image"
							:show-discount-label="true"
						/>
					</slide>
				</carousel>
			</section>
			<div class="button-container d-flex my-8">
				<v-btn
					color="primary"
					class="w-50"
					width="50%"
					large
					outlined
					:to="{ name: 'home' }"
					exact
				>
					<v-icon left>mdi-cart-outline</v-icon>
					<span v-text="$t('cart.welcome.continueShop')"></span>
				</v-btn>
				<v-btn
					data-testid="checkoutBtn"
					color="primary"
					large
					@click="handleNextClick"
					class="ml-5 w-50"
					width="50%"
				>
					<v-icon left>mdi-credit-card-outline</v-icon>
					<span v-text="$t('btn.checkout')"></span>
				</v-btn>
			</div>
			<div @click="scrollToTop" class="top-of-page text-center">
				Top of the page
			</div>
		</v-container>
	</Page>
</template>

<script>
import OtherProduct from "@/components/OtherProduct.vue";
import Page from "@/components/Page";
import { EventBus, EComService } from "@connectngo/sdk";
import ComboDetailIntro from "./ComboDetailIntro.vue";
import { Carousel, Slide } from "vue-carousel";
import BackgroundImage from "@/assets/images/background.svg";
import Combo from "@/components/Combo.vue";
export default {
	name: "ComboDetail",

	props: {
		currentCombo: {
			type: Object,
			default: () => {},
		}
	},
	components: {
		OtherProduct,
		Carousel,
		Slide,
		Page,
		ComboDetailIntro,
		Combo,
	},
	data() {
		return {
			price: 0,
			total: 0,
			showFullDescription: false,
			timeOptions: [],
			errorLoadingImage: {},
			crossSellProducts: [],
			combo: { data: {} },
			selectedEvent: null,
			showCalendar: true,
			navigationNext: '<span class="mdi mdi-chevron-right"></span>',
			navigationPrev: '<span class="mdi mdi-chevron-left"></span>',
			skeleton: true,
			otherProductsMock: [
				{
					title: "Hot dog - all you can drink combo",
					actualPrice: 15,
					originalPrice: 20,
					discount: 15,
					image: "https://bota.vn/wp-content/uploads/2018/10/img20180510214548269.jpg",
				},
				{
					title: "50$ in park currency to spend",
					actualPrice: 40,
					originalPrice: 50,
					discount: 20,
					image: "https://bota.vn/wp-content/uploads/2018/10/img20180510214548269.jpg",
				},
				{
					title: "Senior season pass 65+",
					actualPrice: 99.99,
					originalPrice: 129.99,
					discount: 23,
					image: "https://bota.vn/wp-content/uploads/2018/10/img20180510214548269.jpg",
				},
				{
					title: "50$ in park currency to spend",
					actualPrice: 40,
					originalPrice: 50.0,
					discount: 20,
					image: "https://bota.vn/wp-content/uploads/2018/10/img20180510214548269.jpg",
				},
			],
			// Other data properties...
		};
	},
	computed: {
		fullDescription() {
			return "enjoying the flavors of our culinary delights, this combo has you covered. Purchase your Ultimate Fun Combo today and embark on an unforgettable journey of fun and excitement! Read Less"
		},
		placeholderImage() {
			const comboImage =
				this.combo?.data?.image;
			if (!comboImage) {
				return BackgroundImage;
			}
			return comboImage;
		},
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		},
		comboDescription() {
			return this.combo.data.description_i18n?.[this.$i18n.locale] || '';
		},
		comboProductsDesc() {
			return this.combo?.data?.combo_products
				?.filter(product => product?.description_i18n?.[this.$i18n.locale])
				?.map(product => ({
					name: product?.name,
					description: product?.description_i18n?.[this.$i18n.locale] ?? '',
				}))
		},
		productsSummary() {
			if (!this.combo || !this.combo.data || !this.combo.data.combo_products) {
				return [];
			}
			return this.combo.data.combo_products.map(product => {
    			const comboQuantity = this.combo.data.combo_quantities.find(qty => qty.product_id === product.id);
    			const quantity = comboQuantity ? comboQuantity.quantity : 0;
    			return { name: product.name, quantity };
  			});
		},
		mounted() {
			this.timeSlotEventBus = EventBus.subscribe(
				"timeSlotPickedForGroup" + this.eventGroupId,
				this.eventSelected
			);
		},
	},
	created() {
		this.load();
	},
	methods: {
		toggleDescription() {
			this.showFullDescription = !this.showFullDescription;
		},
		triggerGAEvent() {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "view_item",
					ecommerce: {
						items: [
							{
								item_id: this.combo.data.id,
								item_name: this.$options.filters.translatable(
									this.combo.data.name,
									this.combo.data.name_i18n,
									this.$i18n.locale
								),
								currency: this.$root.websiteConfig.data.tenant
									.currency.code,
								index: 1,
								price: this.combo.data.price,
								quantity: 1,
							},
						],
					},
				});
			}
		},
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		handleNextClick() {
			console.log('Handle next click here!!!');
		},
		handleImageError(product) {
			this.$set(this.errorLoadingImage, product.id, true);
			this.productImage = BackgroundImage;
		},
		getProductImage(product) {
			const hasError = this.errorLoadingImage[product.id];
			return hasError || !product?.data?.image ? BackgroundImage : product?.data?.image;
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getProductDetails(this.$route.params.slug),
				new EComService().getCrossSell(this.$route.params.slug),
			])
				.then(([productDetails, crossSellProducts]) => {
					this.title = this.$options.filters.translatable(
						productDetails.data.name,
						productDetails.data.name_i18n,
						this.$i18n.locale
					);
					this.$route.meta.name = this.title;
					this.$breadcrumbs.refresh();
					this.crossSellProducts = crossSellProducts;
					this.$set(this.combo, "data", productDetails.data);
					this.skeleton = false;

					const eventGroupIdArray = [];
					if (this.combo.data.combo_products) {
						this.combo.data.combo_products.forEach((product) => {
							if (product.event_group?.id) {
								eventGroupIdArray.push(product.event_group?.id);
							}
						})
					}
					this.eventGroupIds = eventGroupIdArray;
					if (this.eventGroupIds.length > 0) {
						this.showCalendar = true;
					}

					this.triggerGAEvent();
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},
};
</script>
<style scoped lang="scss">
h2 {
	font-weight: 700;
	font-size: 28px;
	color: #009aa6;
	text-transform: capitalize;
}

.carousel-header {
	display: flex;
	padding: 10px 0px 10px 0px;
	align-items: center;
	h2 {
		font-size: 27px;
		font-weight: 600;
		line-height: 36px;
		text-align: left;
		margin-bottom: 0;
		color: #413F3F;
	}
	a {
		font-size: 22px;
		font-weight: 600;
		line-height: 28px;
		text-align: right;
		color: #686A71;
	}
}

.products-summary {
	display: flex;
	flex-direction: column;
	gap: 36px;
	.product-img {
		border-radius: 4px;
		height: 56px;
		width: 100px;
	}

	.row {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.subtitle {
		font-size: 13px;
		font-weight: 400;
	}

	.title a {
		font-size: 18px;
		font-weight: 700;
		color: #009aa6 !important;
	}
}

.v-card {
	border-radius: 10px;
}

.v-card__text {
	font-weight: 400;
	font-size: 22px;
	line-height: 21px;
}

.image-container {
	background-size: cover;
	.v-image {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
}

::v-deep .v-overlay__content {
	max-height: 44px;
	padding: 8px 16px 8px 16px !important;
	border-radius: 16px;
	gap: 8px;
	position: absolute;
	top: 20px;
	left: 20px;
	padding: 8px;
	background-color: #E0F5F7;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	.tag {
		text-align: left;
		line-height: 28px;
		font-weight: 600;
		font-size: 22px;
		color: #000;
		text-transform: none;
	}
}

::v-deep .carousel .VueCarousel-inner {
	gap: 24px;
}

.slide {
	max-width: 40%;
}

// Navigation buttons.
::v-deep .VueCarousel-navigation-button {
	width: 80px;
	height: 80px;
	font-size: 100px;
	position: absolute;
	background-color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #fff;
	top: 50%;
	transform: translateY(-50%);
	&.VueCarousel-navigation-prev {
		left: 10px;
	}
	&.VueCarousel-navigation-next {
		right: 10px;
	}
}

.top-of-page {
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	text-align: center;
	color: #009aa6;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.carousel-header {
		flex-direction: column;
		align-items: flex-start;
		h2 {
			font-size: 22px;
		}
		a {
			font-size: 18px;
		}
	}
	.carousel .VueCarousel-inner {
		gap: 12px;
	}
	.slide {
		max-width: 100%;
	}
}

</style>
