import { render, staticRenderFns } from "./ProductTag.vue?vue&type=template&id=ed67d6dc&scoped=true&data-cy=template-0&"
import script from "./ProductTag.vue?vue&type=script&lang=js&"
export * from "./ProductTag.vue?vue&type=script&lang=js&"
import style0 from "./ProductTag.vue?vue&type=style&index=0&id=ed67d6dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed67d6dc",
  null
  
)

export default component.exports