<template data-cy="template-0">
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners"
		:max-width="isDesktopBreakpoint ? 680 : 500" data-cy="Modal-0">

		<template #body data-cy="template-1">
			<div class="p-3" v-if="!loading" data-cy="div-0">
				<div style="margin:0 auto;"  id="combo-carousel" data-cy="div-1">
					<h3 v-if="combos.length > 1"  class="mb-3 text-center" data-cy="h3-0">{{$t('comboModal.modal_title_multiple')}}</h3>
					<h3  v-if="combos.length === 1" class="mb-3 text-center" data-cy="h3-1">{{$t('comboModal.modal_title_one')}}</h3>
					<carousel class="ml-4 mr-4" v-if="showComboModal && combos.length > 0" :perPage="1" :navigationEnabled="true" :navigationNextLabel="navigationNext" :navigationPrevLabel="navigationPrev" ref="carousel" data-cy="carousel-0">
						<slide v-for="(comboItem,index) in combos" :key="'combo-item-'+index" data-cy="slide-0">
							<ComboItem
								:key="'combo-item-'+index"
								:combo="comboItem.data"
								@closeModal="cancelModal"
							 	data-cy="ComboItem-0"
							/>
						</slide>
					</carousel>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ComboItem from '@/components/ComboItem';
import { Carousel, Slide } from 'vue-carousel';
import navigationNext from '@/assets/images/navigation-next.svg';
import navigationPrev from '@/assets/images/navigation-prev.svg';

export default {
	name: 'ComboModal',
	components: { Modal, ComboItem, Carousel, Slide },

	props: {
		showComboModal: {
			type: Boolean,
			default: false,
		},
		combos: {
			type: Array,
			required: false,
			default: [],
		},
	},

	data() {
		return {
			loading: false,
			allDone: false,
			navigationNext: '<img src="' + navigationNext + '">',
			navigationPrev: '<img src="' + navigationPrev + '">',
		}
	},

	methods: {
		cancelModal() {
			this.$emit('handleComboModalCloseClick');
		},

		refreshCarousel() {
			const event = new Event('resize');
			window.dispatchEvent(event);
		},
	},

	computed: {
		_visible: {
			get() {
				return this.showComboModal;
			},
			set() {
				this.$emit('handleComboModalCloseClick');
			},
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		}
	},

	updated() {
		this.refreshCarousel()
	},
}
</script>

<style>
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 95%;
}
</style>
