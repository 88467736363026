<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row class="mb-8" data-cy="v-row-0">
			<v-col cols="12" md="6" class="d-flex flex-column justify-center" data-cy="v-col-0">
				<h2 class="display-1 font-weight-light" v-text="$t('account.transactions.title', {
					name: $route.meta.sub,
				})" data-cy="h2-0"></h2>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-end align-center" data-cy="v-col-1">
				<v-select
					v-model="sortBy"
					:items="sortByItems"
					:label="$t('account.transactions.sortByLabel')"
					prepend-inner-icon="mdi-sort"
					solo
					hide-details
				 data-cy="v-select-0"/>
				<v-btn icon @click="goToPrevPage" :disabled="currentPage <= 1">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<span style="text-align: center" v-text="$t('btn.pagination.page', {currentPage:  currentPage, totalPages: totalPages})" data-cy="span-0">
				</span>
				<v-btn icon @click="goToNextPage" :disabled="currentPage >= totalPages">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-alert v-if="transactions.results.length === 0" text prominent type="info" data-cy="v-alert-0">
			<span v-text="$t('account.transactions.empty')" data-cy="span-0"></span>
		</v-alert>
		<v-row v-else data-cy="v-row-1">
			<v-col :key="transactionIdx" v-for="(transaction, transactionIdx) in _transactions" cols="12" data-cy="v-col-2">
				<TransactionCard
					:value="transaction"
					:skeleton="skeleton"
					:to="{ name: 'account_section_sub_action_param1', params: { section: 'connected', sub: $route.params.sub, action: 'transactions', param1: transaction.data.uuid } }"
					:route="{ name: 'account_section_sub_action_param1', params: { section: 'connected', sub: $route.params.sub, action: 'transactions', param1: transaction.data.uuid } }"
				 data-cy="TransactionCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TransactionCard from '@/components/TransactionCard';
import { PaginationModel, EComService } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { TransactionCard },

	metaInfo() {
		return {
			title: this.title,
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_connected_transactions') },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	data: () => ({
		loading: false,
		skeleton: true,
		sortBy: 'created_at,desc',
		transactions: new PaginationModel([{}, {}, {}]),
		title: '',
		currentPage: 1,
		totalPages: 1,
	}),

	watch: {
		sortBy(newSortOrder) {
			this.sortBy = newSortOrder;
			this.currentPage = 1; // Reset to first page on sort change
			this.load();
		},
	},

	computed: {
		_transactions() {
			return this.transactions.results;
		},
		sortByItems() {
			return [
				{ text: this.$i18n.t('account.transactions.sortDateDesc'), value: 'created_at,desc' },
				{ text: this.$i18n.t('account.transactions.sortDateAsc'), value: 'created_at,asc' },
				{ text: this.$i18n.t('account.transactions.sortTotalDesc'), value: 'amount,desc' },
				{ text: this.$i18n.t('account.transactions.sortTotalAsc'), value: 'amount,asc' },
			];
		},
	},

	methods: {
		load() {
			this.loading = true;
			this.skeleton = true;
			return Promise.all([
				new EComService().getAccount(this.$route.params.sub),
				new EComService().getTransactionHistory(this.$route.params.sub, {
					page: this.currentPage
				}),
			])
				.then(([account, transactions]) => {
					this.totalPages = transactions.pagination.last_page;
					this.currentPage = transactions.pagination.current_page;
					this.$route.meta.sub = this.$options.filters.shortUserName(account);
					this.title = this.$i18n.t('account.transactions.title', {	name: this.$route.meta.sub });
					this.$breadcrumbs.refresh();
					Object.assign(this, { transactions });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
		goToPrevPage() {
			this.currentPage -= 1;
			this.load();
		},
		goToNextPage() {
			this.currentPage += 1;
			this.load();
		},
		getSortColumn() {
			return this.sortBy.split(',')[0];
		},
		getSort() {
			return this.sortBy.split(',')[1];
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
