import Vue from 'vue';
import moment from 'moment';
import globalVariables from "@/global";

Vue.filter('completeUserName', (user) => {
	return ((user.data.firstname ? user.data.firstname : '')
		+ ' '
		+ (user.data.lastname ? user.data.lastname : '')).trim() || user.data.gamename.trim();
});

Vue.filter('shortUserName', (user) => {
	if (user.data.salesgroup_id !== null) {
		return user.data.company_name;
	}
	const name = ((user.data.firstname + ' ' + (user.data.lastname || '').substring(0, 1)).trim() || user.data.gamename).trim();
	if (user.data.lastname) {
		return name + '.';
	}
	return name;
});

Vue.filter('timestampToHumanDate', (ts) => {
	return moment(ts).format('LL HH:mm:ss');
});

Vue.filter('timestampToDateOnly', (ts) => {
	return moment(ts).format('DD/MM/YYYY');
});

Vue.filter('tsToFormat', (ts, format, useTenantsTimezone = false) => {
	let momentObj = moment(ts);

	if (useTenantsTimezone) {
		momentObj.tz(globalVariables.vm.$root.websiteConfig.data.tenant.timezone);
	}

	return momentObj.format(format);
});

Vue.filter('currency', (value, currency, locale = globalVariables.vm.$i18n.locale) => {
	let currencyValue
	currencyValue = Intl.NumberFormat(locale, { style: 'currency', currency: currency || globalVariables.websiteConfig.data.tenant.currency.code }).format(value);

	if (globalVariables.websiteConfig.data.tenant.currency.code === 'QAR') {
		currencyValue = currencyValue.replace('QAR', '') + ' QAR'
	}

	return currencyValue
});

Vue.filter('currencySign', (value, currency, locale = 'en-US') => {
	return Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency || globalVariables.websiteConfig.data.tenant.currency.code
	}).format(value).replaceAll(/[0-9,.]/gi, '');
});

Vue.filter('taxes', (value, locale = 'en-US') => {
	return Intl.NumberFormat(locale, {
		style: "percent",
		maximumFractionDigits: 5,
	}).format(value);
});

Vue.filter('translatable', (original, json, locale = globalVariables.vm.$i18n.locale) => {
	try {
		if (json !== null && typeof json === 'string') {
			const parsed: any = JSON.parse(json);
			return parsed[locale] || original;
		} else if (json !== null && json instanceof Object) {
			return json[locale] || original;
		}
		return original;
	} catch (error) {
		return original;
	}
});
