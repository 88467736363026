<template data-cy="template-0">
	<v-container data-cy="v-container-0">

		<AddSubAccountModal
			:visible.sync="addSubAccountModal"
			:parent-account="$root.user"
			scrollable
			max-width="500"
			@created="handleSubAccountCreated"
		 data-cy="AddSubAccountModal-0"/>

		<v-row data-cy="v-row-0">
			<v-col cols="12" md="6" class="d-flex flex-column justify-center" data-cy="v-col-0">
				<h2 v-if="$route.params.is_b2b" class="display-1 font-weight-light" v-text="this.$root.user.data.company_name ? $t('account.connected.title', {
					name: $root.user.data.company_name,
				}) : $t('account.company_missing')"" data-cy="h2-0"></h2>
				<h2 v-else class="display-1 font-weight-light" v-text="$t('account.connected.title', {
					name: $root.user.data.firstname,
				})" data-cy="h2-0"></h2>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column justify-center align-end" data-cy="v-col-1">
				<v-btn color="button" :class="{
					'py-3 v-size--stretch': true,
				}" outlined :block="$vuetify.breakpoint.smAndDown" @click="handleCreateSubAccountClick" data-cy="v-btn-0">
					<v-icon left data-cy="v-icon-0">mdi-account-plus</v-icon>
					<span :class="{
						'text-left ml-3 text-none': true,
						'text-wrap': $vuetify.breakpoint.smAndDown,
					}" style="width: min-content; margin: auto; flex: 1" data-cy="span-0">
						<span v-text="$t('account.connected.addMember')" data-cy="span-1"></span>
					</span>
				</v-btn>
			</v-col>
		</v-row>
		<v-row data-cy="v-row-1">
			<v-col v-if="items.results.length === 0" cols="12" data-cy="v-col-2">
				<v-alert text prominent type="info" class="ma-0" data-cy="v-alert-0">
					<span v-text="$t('connectedAccountListingCard.empty')" data-cy="span-2"></span>
				</v-alert>
			</v-col>
			<v-col :key="item.guid" v-for="item in items.results" cols="12" lg="6" data-cy="v-col-3">
				<ConnectedAccountCard :value="item" :skeleton="skeleton"  data-cy="ConnectedAccountCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ConnectedAccountCard from '@/components/ConnectedAccountCard';
import AddSubAccountModal from '@/components/AddSubAccountModal';
import { PaginationModel, EComService } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { ConnectedAccountCard, AddSubAccountModal },

	data: () => ({
		skeleton: true,
		loading: false,
		addSubAccountModal: false,
		items: new PaginationModel([{},{},{}]),
	}),

	methods: {
		handleSubAccountCreated(account) {
			this.load();
		},
		handleCreateSubAccountClick() {
			this.addSubAccountModal = true;
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getConnectedAccounts(),
			])
				.then(([connectedAccounts]) => {
					Object.assign(this, { items: connectedAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
