<template data-cy="template-0">
	<v-skeleton-loader
		v-if="skeleton"
		type="image, article, actions"
		data-cy="v-skeleton-loader-0"
	></v-skeleton-loader>
	<v-card
		data-testid="cartItemCard"
		v-else-if="inCart && isAvailable"
		v-bind="$attrs"
		v-on="$listeners"
		:class="['no-background', { 'event-unavailable': eventClosed }]"
		data-cy="v-card-0"
	>
		<v-expansion-panels>
			<v-expansion-panel>
				<v-expansion-panel-header class="pr-2 d-flex flex-column" disable-icon-rotate>
					<v-row class="w-100 mb-1">
						<v-col
							class="
								pa-0
								pl-2
								d-flex
								flex-column
								justify-center
								align-start
							"
							:class="{
								'col-7 col-sm-9':
									cartItem.data.eventPeriod || hasModifiersInCart,
							}"
							data-cy="v-col-0"
						>
							<div data-cy="div-0">
								<span
									data-testid="cartItemName"
									:class="{
										caption: isMobile,
										title: !isMobile,
									}"
									class="font-weight-regular mb-2"
									v-if="quantity > 0"
									data-cy="span-0"
									>{{ quantity }}&nbsp;x&nbsp;{{
										$options.filters.translatable(
											product.data.name,
											product.data.name_i18n,
											$i18n.locale
										)
									}}</span>
							</div>
							<p
								v-if="cartItem.data.eventPeriod"
								class="
									caption
									font-italic font-weight-thin
									text-black
								"
								:class="{ 'font-weight-regular': !isMobile }"
								v-text="cartItem.data.eventPeriod"
								data-cy="p-0"
							></p>
						</v-col>
						<v-col
							v-if="!cartItem.data.eventPeriod && !hasModifiersInCart"
							class="
								col-3
								pa-0
								d-flex
								justify-center
								align-center
							"
							data-cy="v-col-1"
						>
							<v-btn
								data-testid="plusBtn"
								icon
								@click="handleUpClick"
								class="
									pa-0
									ma-0
									title
									font-weight-bold
									success--text
									rounded-sm rounded-tr-0 rounded-br-0
								"
								:width="(isMobile && '30px') || '36px'"
								:height="(isMobile && '30px') || '36px'"
								:disabled="addQuantityState"
								style="border: 1px solid gray"
								data-cy="v-btn-0"
								>+</v-btn
							>
							<v-btn
								data-testid="minusBtn"
								icon
								@click="handleDownClick"
								class="
									pa-0
									ma-0
									title
									font-weight-bold
									primary--text
									rounded-sm rounded-tr-1 rounded-bl-0
								"
								:width="(isMobile && '30px') || '36px'"
								:height="(isMobile && '30px') || '36px'"
								:disabled="removeQuantityState"
								style="
									border: 1px solid gray;
									border-left: none;
								"
								data-cy="v-btn-1"
								>-</v-btn
							>
						</v-col>
						<v-col
							class="
								col-3 col-sm-2
								pa-0
								d-flex
								justify-center
								align-center
							"
							data-cy="v-col-2"
						>
							<div
								data-testid="itemTotal"
								:class="{ caption: isMobile, title: !isMobile }"
								class="font-weight-black"
								v-text="$options.filters.currency(subTotal())"
								data-cy="div-1"
							></div>
						</v-col>
						<v-col
							class="
								col-2 col-sm-1
								pa-0
								d-flex
								justify-end
								align-center
							"
							data-cy="v-col-3"
						>
							<v-btn
								data-testid="itemRemoveBtn"
								v-if="inCart"
								class="ma-4 pa-0"
								color="error"
								:width="(isMobile && '30px') || '36px'"
								:height="(isMobile && '30px') || '36px'"
								icon
								@click.stop.prevent="
									handleRemoveClick(actualQuantity)
								"
								data-cy="v-btn-2"
							>
								<v-icon class="pa-0" data-cy="v-icon-0"
									>mdi-trash-can</v-icon
								>
							</v-btn>
						</v-col>
					</v-row>
					<template v-if="comboItemsSummary.length > 0">
						<v-divider />
						<div class="w-100 mt-1 pa-2 font-weight-bold combo-items-container d-flex flex-column">
							<p class="mb-1">This combo includes:</p>
							<ul>
								<li v-for="(item, index) in comboItemsSummary">
									{{ item.quantity }}x {{ item.name }}
								</li>
							</ul>
						</div>
					</template>
					<template v-slot:actions>
						<v-icon v-show="hasModifiersInCart"> $expand </v-icon>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content v-if="hasModifiersInCart && modifiersByEventGroups.length > 0">
					<v-divider class="my-4" />
					<!-- MODIFIERS -->
					<v-row v-if="loadingModifiers">
						<v-col
							cols="12"
							sm="6"
							:key="index"
							v-for="index in [1, 2]"
						>
							<v-skeleton-loader
								type="text"
								height="20"
							></v-skeleton-loader>
						</v-col>
					</v-row>
					<template
						v-else
						v-for="eventGroup in modifiersByEventGroups"
					>
						<div
							v-for="modifier in eventGroup.data.modifiers"
							:key="modifier.data.id"
						>
							<v-row class="flex">
								<span
									class="
										body-1
										text-capitalize-fl
										col-7 col-sm-9
									"
									data-cy="span-0"
								>
									<span data-cy="span-2"
										>{{
											actualQuantityModifiers[
												`${modifier.data.id}`
											]
										}}
										x
										{{
											$options.filters.translatable(
												modifier.data.name,
												modifier.data.name_i18n
											)
										}}</span
									>
								</span>
								<span
									class="
										body-1
										text-capitalize-fl
										col-5 col-sm-3
									"
									v-text="
										$options.filters.currency(
											total(
												getModifierPrice(modifier.data),
												actualQuantityModifiers[
													`${modifier.data.id}`
												]
											)
										)
									"
									data-cy="span-4"
								>
								</span>
							</v-row>
						</div>
					</template>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
	<v-card
		data-testid="productCard"
		v-else
		v-bind="$attrs"
		v-on="$listeners"
		:class="{ 'event-unavailable': eventClosed }"
		data-cy="v-card-1"
	>
		<template v-if="minimal" data-cy="template-1">
			<v-row no-gutters data-cy="v-row-1">
				<v-col
					style="flex: 0"
					class="d-flex align-center"
					data-cy="v-col-4"
				>
					<v-img
						v-if="product.data.image"
						id="img_1"
						:aspect-ratio="16 / 9"
						width="12rem"
						height="100%"
						:src="product.data.image"
						class="d-flex"
						data-cy="v-img-0"
					>
					</v-img>
					<v-checkbox
						v-if="selectable"
						v-model="_selected"
						:disabled="!canSelect"
						background-color="white"
						class="ma-2 mt-1"
						style="width: 1.5rem"
						data-cy="v-checkbox-0"
					></v-checkbox>
				</v-col>
				<v-col
					style="flex: 1"
					class="d-flex flex-column justify-center px-4 text-left"
					:class="{ 'mt-4': windowWidth < 380 }"
					data-cy="v-col-5"
				>
					<v-card
						v-if="promotion"
						color="primary"
						:class="{
							minimal,
							dense,
							'promotion px-4 py-0 overline': true,
						}"
						dark
						data-cy="v-card-2"
					>
						<span
							v-text="$t('product.promotion')"
							data-cy="span-1"
						></span>
					</v-card>

					<span
						class="overline text--secondary mt-n1"
						v-text="product.data.category"
						data-cy="span-2"
					></span>
					<span
						class="title font-weight-regular mt-n2"
						v-text="
							$options.filters.translatable(
								product.data.name,
								product.data.name_i18n,
								$i18n.locale
							)
						"
						data-cy="span-3"
					></span>

					<div
						class="d-flex align-center justify-space-between"
						data-cy="div-2"
					>
						<span data-cy="span-4">
							<span
								v-if="
									product.data.msrp &&
									product.data.msrp >= product.data.price
								"
								class="text-decoration-line-through mr-1"
								v-text="
									$options.filters.currency(product.data.msrp)
								"
								data-cy="span-5"
							></span>
							<span v-if="quantity > 1" data-cy="span-6"
								>{{ quantity }}x</span
							>
							<span
								class="font-italic font-weight-bold"
								v-text="
									$t('product.priceEach', {
										price: $options.filters.currency(
											product.data.price
										),
									})
								"
								data-cy="span-7"
							></span>
						</span>
					</div>
				</v-col>
			</v-row>
		</template>
		<template v-else>
			<v-row v-if="dense" no-gutters>
				<v-col cols="4" class="d-flex align-center">
					<v-img
						v-if="product.data.image"
						id="img_2"
						:aspect-ratio="16 / 9"
						:src="product.data.image"
						class="d-flex"
					>
					</v-img>
					<v-checkbox
						v-if="selectable"
						v-model="_selected"
						:disabled="!canSelect"
						background-color="white"
						class="ma-2 mt-1 selectable"
						style="width: 1.5rem"
					></v-checkbox>
					<v-card
						v-if="promotion"
						color="primary"
						:class="{
							minimal,
							dense,
							'promotion px-4 py-0 overline': true,
						}"
						dark
					>
						<span v-text="$t('product.promotion')"></span>
					</v-card>
				</v-col>
				<v-col cols="8" class="d-flex flex-column justify-center px-4">
					<span
						class="overline text--secondary"
						v-text="product.data.category"
					></span>
					<span
						class="title font-weight-regular"
						v-text="
							$options.filters.translatable(
								product.data.name,
								product.data.name_i18n,
								$i18n.locale
							)
						"
					></span>
				</v-col>
			</v-row>
			<template v-else-if="!horizontal">
				<v-img
					v-if="product.data.image"
					:aspect-ratio="16 / 9"
					:src="product.data.image"
					class="d-flex"
				>
					<v-checkbox
						v-if="selectable"
						v-model="_selected"
						:disabled="!canSelect"
						background-color="white"
						class="ma-2 mt-1"
						style="width: 1.5rem"
					></v-checkbox>

					<v-card
						v-if="promotion"
						color="primary"
						:class="{
							minimal,
							dense,
							'promotion px-4 py-0 overline': true,
						}"
						dark
					>
						<span v-text="$t('product.promotion')"></span>
					</v-card>
				</v-img>
				<v-card-text
					v-text="product.data.category"
					class="pb-2 overline text-left"
				></v-card-text>
				<v-row
					class="justify-space-between ma-0 pa-0 pr-4"
					:class="{
						'flex-column':
							windowWidth <= 450 && productType === 'tag',
					}"
				>
					<v-card-title
						data-testid="cardTitle"
						v-text="
							$options.filters.translatable(
								product.data.name,
								product.data.name_i18n,
								$i18n.locale
							)
						"
						class="pt-0 text-left"
						:class="{ 'pb-3': windowWidth <= 450 }"
					></v-card-title>
					<v-btn
						data-testid="prodDetailBtn"
						v-if="productType === 'tag'"
						class="primary"
						:class="{
							'w-fit-content align-self-end':
								windowWidth <= 450 && productType === 'tag',
						}"
						@click="
							$router.push({
								name: product.data.is_combo && isTimeBasedCombos ? 'combo' : 'product',
								params: {
									lang: $i18n.locale,
									slug: product.data.slug,
								},
							})
						"
						v-text="$t('product.see_details')"
					></v-btn>
				</v-row>
			</template>

			<v-alert
				v-if="formErrors.error"
				class="mt-n4 mx-3 mb-0"
				type="error"
				text
			>
				<span v-text="formErrors.error"></span>
			</v-alert>

			<template
				v-if="isCrossSell && product.data.event_group.id !== null"
			>
				<Calendar
					v-if="showCalendar"
					v-model="_eventId"
					:in-product="isCrossSell"
					:event-group-ids="[eventGroupId]"
					class="mb-4"
				>
					<template v-slot:append-day>
						<span v-text="$t('calendar.chooseYourItems')"></span>
					</template>
				</Calendar>

				<Calendar
					v-else
					v-model="_eventId"
					:in-product="isCrossSell"
					:event-group-ids="[eventGroupId]"
					:hide-day-selection="true"
					:day-selected.sync="daySelected"
					class="mb-4"
				>
					<template v-slot:append-day>
						<span v-text="$t('calendar.chooseYourItems')"></span>
					</template>
				</Calendar>
			</template>

			<v-card-text class="text-left" v-if="basic">
				<p>
					{{
						$options.filters.translatable(
							null,
							product.data.description_i18n,
							$i18n.locale
						)
					}}
				</p>
			</v-card-text>
			<v-card-text v-else>
				<v-row>
					<v-col
						v-if="horizontal"
						cols="12"
						md="6"
						class="pa-0 ma-n1 pr-4"
					>
						<v-img
							:height="product.data.image ? 250 : 20"
							:src="product.data.image"
							class="d-flex"
						>
						</v-img>
						<v-checkbox
							v-if="selectable"
							v-model="_selected"
							:disabled="!canSelect"
							background-color="white"
							class="ma-2 mt-1 selectable"
							style="width: 1.5rem"
						></v-checkbox>

						<v-card
							v-if="promotion"
							color="primary"
							:class="{
								minimal,
								dense,
								'promotion px-4 py-0 overline': true,
							}"
							dark
						>
							<span v-text="$t('product.promotion')"></span>
						</v-card>
					</v-col>
					<v-col
						cols="12"
						:md="horizontal && product.data.image ? 6 : 12"
						:class="{
							'd-flex flex-column justify-center': horizontal,
						}"
					>
						<v-row v-if="cartItem.data.eventPeriod">
							<v-col
								cols="6"
								md="7"
								class="
									d-flex
									flex-column
									justify-center
									text-left
								"
							>
								<span
									class="font-weight-bold"
									v-text="cartItem.data.eventPeriod"
								></span>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								md="7"
								class="
									d-flex
									flex-column
									justify-center
									text-left
								"
							>
								<span
									v-if="
										product.data.min_quantity === null &&
										product.data.max_quantity === null
									"
									v-text="$t('product.quantity')"
								></span>
								<span
									v-else-if="
										product.data.min_quantity !== null &&
										product.data.max_quantity !== null
									"
									v-text="
										$t('product.quantityBoth', {
											min: product.data.min_quantity,
											max: product.data.max_quantity,
										})
									"
								></span>
								<span
									v-else-if="
										product.data.min_quantity !== null &&
										product.data.max_quantity === null
									"
									v-text="
										$t('product.quantityMin', {
											min: product.data.min_quantity,
										})
									"
								></span>
								<span
									v-else
									v-text="
										$t('product.quantityMax', {
											max: product.data.max_quantity,
										})
									"
								></span>

								<span data-testid="price" class="font-italic">
									<span
										v-if="
											product.data.msrp &&
											product.data.msrp >=
												product.data.price
										"
										class="
											text-decoration-line-through
											mr-1
										"
										v-text="
											$options.filters.currency(
												product.data.msrp
											)
										"
									></span>
									<span
										v-if="product.data.priceVerifiedViaApi || !_eventId"
										class="font-weight-bold"
										v-text="
											$t('product.priceEach', {
												price: $options.filters.currency(
													product.data.price
												),
											})
										"
									></span>
									<v-skeleton-loader v-else type="heading" />
								</span>
							</v-col>
							<v-col
								cols="6"
								md="5"
								class="d-flex align-center justify-end"
							>
								<InputNumeric
									v-if="!readonly"
									v-model="quantity"
									:min="
										product.data.min_quantity > 0
											? product.data.min_quantity
											: 0
									"
									:max="
										product.data.max_quantity > 0
											? product.data.max_quantity
											: null
									"
									:increment="
										product.data.increment > 0
											? product.data.increment
											: 1
									"
									:readonly="readonly || loading"
								/>
								<v-chip
									v-else
									class="px-6 title font-weight-black"
									large
									v-text="quantity"
								></v-chip>
							</v-col>
						</v-row>

						<!-- MODIFIERS -->
						<template
							v-if="
								hasModifiers &&
								modifiersByEventGroups.length > 0
							"
						>
							<v-row>
								<v-col cols="6" class="d-flex align-center">
									<span
										class="font-weight-bold"
										v-text="$t('product.subTotal')"
									></span>
								</v-col>
								<v-col
									cols="6"
									class="d-flex align-center justify-end"
								>
									<span
										class="font-weight-bold"
										v-text="
											$options.filters.currency(
												subTotal()
											)
										"
									></span>
								</v-col>
							</v-row>

							<v-divider class="my-4" />

							<h4
								class="mb-4 title font-weight-light"
								v-text="
									enhanceText || $t('product.enhanceVisit')
								"
							></h4>

							<v-row v-if="loadingModifiers">
								<v-col
									cols="12"
									sm="6"
									:key="index"
									v-for="index in [1, 2]"
								>
									<v-skeleton-loader
										type="image"
										height="70"
									></v-skeleton-loader>
								</v-col>
							</v-row>
							<template
								v-else
								v-for="eventGroup in modifiersByEventGroups"
							>
								<v-row
									v-if="
										eventGroup.data.events !== null &&
										eventGroup.data.events.length <= 4 &&
										!inCart
									"
								>
									<v-col
										cols="12"
										md="6"
										v-for="slot in sortEvents(
											eventGroup.data.events,
											daySelected
										)"
										:key="slot.id"
										class="ma-0 px-1 py-0 m-bottom"
									>
										<CalendarSlot
											:key="slot.date"
											:period="slot.period"
											:remaining="slot.available"
											:total="slot.available"
											:color="
												slot.available === 0
													? '#ccc'
													: selectedModifierEvent[
															eventGroup.guid
													  ] !== slot.id
													? 'primary'
													: 'white'
											"
											:outlined="
												selectedModifierEvent[
													eventGroup.guid
												] !== slot.id
											"
											class="mb-3"
											:disableSlot="isSlotIdDisabled(slot.id, disabledSlot)"
											@slotMounded="
												handleMountedSlot(
													slot,
													eventGroup.data.modifiers
												)
											"
											@click="
												handleSlotClick(
													slot,
													eventGroup
												)
											"
										/>
									</v-col>
								</v-row>
								<template
									v-else-if="
										eventGroup.data.events !== null &&
										eventGroup.data.events.length > 4 &&
										!inCart
									"
								>
									<v-select
										:label="$t('select.timeOfVisit')"
										:item-disabled="(option)=>isSlotIdDisabled(option.value.slot.id, disabledSlot)"
										:items="
											slotsForSelectDisplay(eventGroup)
										"
										@change="handleSelectTimeSlotSelect"
										@click="handleItemMounted(eventGroup)"
									/>
								</template>
								<template
									v-for="(modifier, modifierIdx) in eventGroup
										.data.modifiers"
								>
									<Modifier
										:key="modifier.data.id"
										:value="modifier"
										:loading="loading"
										:readonly.sync="readonly"
										:selectable="modifier.data.checkbox"
										:required="
											infoModifiers[modifier.data.id]
												.required
										"
										:quantity.sync="
											quantityModifiers[modifier.data.id]
										"
										:disableModifier="disabledModifier"
										:modifierCutoff="getCutOff(modifier)"
										:selected.sync="
											selectedModifiers[modifier.data.id]
										"
										:class="{
											'mt-2': modifierIdx > 0,
										}"
										@click="
											handleModifierClick(
												modifier.data.id
											)
										"
									/>
									<v-divider
										:key="modifier.data.id + '_div'"
										class="my-4"
									/>
								</template>
							</template>
						</template>

						<!-- TOTAL -->
						<v-row>
							<v-col cols="6" md="7" class="d-flex align-center">
								<div
									v-if="product.data.priceVerifiedViaApi|| !_eventId"
									data-testid="totalPrice"
									class="title font-weight-black"
									v-text="
										$options.filters.currency(totalPrice())
									"
								></div>
								<v-skeleton-loader v-else type="heading" />
							</v-col>

							<v-col
								cols="12"
								md="5"
								class="d-flex align-center justify-end"
							>
								<v-btn
									data-testid="addToCart"
									v-if="!readonly && !inCart"
									color="primary"
									:disabled="!canAddToCart() || !isAvailable"
									:loading="loading"
									block
									@click="handleAddClick"
								>
									<v-icon left>mdi-cart-plus</v-icon>
									<span v-text="$t('btn.add')"></span>
								</v-btn>
							</v-col>
						</v-row>
						<div class="disable-product" v-if="!isAvailable">
							<span
								v-text="
									$t('slot.noProducts') + getCutOff(product)
								"
							></span>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>
import moment from "moment";
import InputNumeric from "@/components/InputNumeric";
import ProductMixin, { WindowWidthMixin, DebounceMixin } from "@/mixins/ProductMixin";
import Modifier from "@/components/Modifier";
import CalendarSlot from "@/components/CalendarSlot";
import Calendar from "@/components/Calendar";
import globalVariables from "@/global";

export default {
	name: "Product",

	mixins: [ProductMixin, WindowWidthMixin, DebounceMixin],

	components: { InputNumeric, Modifier, CalendarSlot, Calendar },

	computed: {
		removeQuantityState() {
			return (
				this.quantity < 2 ||
				this.loading ||
				this.quantity === this.product.data.min_quantity
			);
		},
		addQuantityState() {
			return (
				this.loading || this.quantity === this.product.data.max_quantity
			);
		},
		isTimeBasedCombos() {
			return this.$root.websiteConfig.data?.feature_flags?.includes('co-5754-time-based-combos');
		},
		comboItemsSummary() {
    		// Check if this.products.comboItems exists and is an array
    		if (Array.isArray(this.cartItem.data.comboItems)) {
				const quantitiesByName = {};

      			this.cartItem.data.comboItems.forEach(comboItem => {
					if (!quantitiesByName[comboItem.product.name]) {
						quantitiesByName[comboItem.product.name] = comboItem.quantity;
					} else {
						quantitiesByName[comboItem.product.name] += comboItem.quantity;
					}
      			});

				return Object.entries(quantitiesByName).map(([name, quantity]) => ({ name, quantity }));
    		} else {
      		// Return an empty array if this.products.comboItems doesn't exist or is not an array
      			return [];
    		}
  		},
	},

	props: {
		addDisableModifierData: {
			type: Function,
			default: () => ({}),
		},
		disabledModifier: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		disabledSlot: [],
		selectFirstAvailSlot: null,
		exitingDisableSlot: 0,
	}),

	methods: {
		handleItemMounted(eventGroup) {
			if (eventGroup.data.events?.length > 0) {
					if (globalVariables.websiteConfig.featureFlagEnabled('co-4126-offset-event-product-availablity')) {
					this.debounce(()=> {
						const events = this.sortEvents(
							eventGroup.data.events,
							this.daySelected
						);
						events.map(item => {
							if(!this.disabledSlot.includes(item.id)) {
								this.handleMountedSlot(item, eventGroup.data.modifiers);
							}
						});
					},500);
				}
			}
		},
		handleDownClick() {
			if (this.product.data.increment > 0) {
				this.quantity = this.quantity - this.product.data.increment;
			} else {
				this.quantity--;
			}

			this.debounce(() => this.handleApplyChanges(), 500);
		},
		handleUpClick() {
			if (this.product.data.increment > 0) {
				this.quantity = this.quantity + this.product.data.increment;
			} else {
				this.quantity++;
			}
			this.debounce(() => this.handleApplyChanges(), 500);
		},
		showAvailability() {
			return (
				parseInt(
					globalVariables.websiteConfig.data.fields
						.show_tickets_availabilities
				) ? true : false
			);
		},
		slotsForSelectDisplay(eventGroup) {
			const events = this.sortEvents(
				eventGroup.data.events,
				this.daySelected
			);
			return events.map((item) => {
				let label = item.period;
				if (this.showAvailability) {
					label += ` (${this.$tc("calendar.places", item.available, {
						amount: item.available,
					})})`;
				}

				return {
					value: { slot: item, guid: eventGroup.guid, eventGroup },
					disabled: item.available <= 0,
					text: label,
				};
			});
		},
		handleSelectTimeSlotSelect(data) {
			this.handleSlotClick(data.slot, data.eventGroup);
		},
		handleMountedSlot(slot, modifiers) {
			if (
				!globalVariables.websiteConfig.featureFlagEnabled(
					"co-4126-offset-event-product-availablity"
				)
			) {
				return;
			}
			const slotMoment = this.dateToTenantTimezone(
				new Date(slot.start_ts * 1000)
			);
			const { disabledModifierData, currentDisabledSlot } =
				this.isSlotDisabled(slot, slotMoment, modifiers);

			if (currentDisabledSlot) {
				this.disabledSlot.push(slot.id);
			}

			this.updateDisableModifierData(slotMoment, disabledModifierData);

			this.disabledSlot = [...new Set(this.disabledSlot)];
			this.exitingDisableSlot++;
		},
		isSlotDisabled(slot, slotMoment, modifiers) {
			if (modifiers.length <= 0) {
				return;
			}
			const unavailableData = modifiers
				.filter((modifier) => modifier.data.unavailable_before !== null)
				.map((data) => data.data.unavailable_before)
				.sort();
			let currentDisabledSlot;
			let disabledModifierData;
			if (unavailableData.length > 0) {
				const unavailableBeforeMoment = this.dateToTenantTimezone(
					new Date(unavailableData[0] * 1000)
				);
				const unavailableBeforeIsAfterProduct =
					unavailableBeforeMoment?.isAfter(
						moment.unix(this.product.data.unavailable_before)
					);

				if (unavailableData.length > 1) {
					disabledModifierData = modifiers.filter(
						(modifier) =>
							modifier.data.unavailable_before !==
							unavailableData[0]
					);
				}
				if (!unavailableBeforeIsAfterProduct) {
					currentDisabledSlot = slotMoment?.isAfter(
						moment.unix(this.product.data.unavailable_before)
					);
				} else {
					currentDisabledSlot = slotMoment.isAfter(
						moment.unix(unavailableData[0])
					);
				}
			} else {
				if (!slot.from) {
					currentDisabledSlot = slotMoment.isAfter(
						moment.unix(this.product.data.unavailable_before)
					);
				} else {
					currentDisabledSlot = slot.from.isAfter(
						moment.unix(this.product.data.unavailable_before)
					);
				}
			}
			return { disabledModifierData, currentDisabledSlot };
		},
		updateDisableModifierData(slotMoment, disabledModifierData) {
			if (disabledModifierData?.length > 0) {
				disabledModifierData.forEach((modifier) => {
					const lastDisabledMoment =
						modifier?.data.unavailable_before;

					const unavailableBeforeMoment = slotMoment.isAfter(
						moment.unix(lastDisabledMoment)
					);
					if (unavailableBeforeMoment || !lastDisabledMoment) {
						this.$emit("addDisableModifierData", {
							slotId: modifier.data.id,
							available: true,
						});
					} else {
						this.$emit("addDisableModifierData", {
							slotId: modifier.data.id,
							available: false,
						});
					}
				});
			}
		},
		handleSlotClick(slot, eventGroup) {
			if (
				globalVariables.websiteConfig.featureFlagEnabled(
					"co-4126-offset-event-product-availablity"
				)
			) {
				const slotMoment = this.dateToTenantTimezone(
					new Date(slot.start_ts * 1000)
				);
				this.$emit("handleSlotClick", slotMoment, eventGroup);
			}
			this.handleModifierSelectTimeSlotClick(slot, eventGroup.guid);
		},
		getCutOff(value) {
			return (
				(value.data.unavailable_before &&
					moment
						.unix(value.data.unavailable_before)
						.format("HH:mm:ss DD-MM-YYYY")) ||
				null
			);
		},
		getModifierPrice(modifier) {
			let price = 0;

			if (this.hasModifiersInCart && modifier.is_calendar_pricing) {
				this.cartItem.data.modifiers.forEach((item) => {
					if (item.data.product.data.id == modifier.id) {
						price = item.data.unitPrice;
					}
				});
			}

			return price !== 0 ? price : modifier.price;
		},
	},
};
</script>

<style lang="scss" scoped>
.promotion {
	position: absolute;
	top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.promotion:not(.minimal):not(.dense) {
	left: 3rem;
}

.combo-items-container {
	p, ul {
		width: 100%;
	}
	p {
		font-weight: bold;
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	ul li {
		color: #413F3F;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}
}

.promotion.dense,
.promotion.minimal {
	right: 1rem;
	line-height: 1.25rem;
}

.top_right_abs {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}

.event-unavailable:not(.cross-sell-container .event-unavailable) {
	opacity: 50%;
}

.selectable {
	position: absolute;
	top: 0;
}

.disable-product {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: absolute;
	background-color: #4d4c4cb8;
	border-radius: 4px;
	z-index: 2;
	top: 0;
	left: 0;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	cursor: not-allowed;
}

.m-bottom {
	margin-bottom: -2px !important ;
}

.no-background {
	background-color: transparent;
	box-shadow: none !important;
}

.v-application .title {
	font-size: 1rem !important;
}
</style>
