<template data-cy="template-0">
	<Page color="neutralLight" no-sidebar no-top-page data-cy="Page-0">
		<v-container style="max-width: 35rem" data-cy="v-container-0">
			<h3 class="font-weight-regular text-center ma-4 mb-6 pt-3 pb-2" v-text="$t('loginForm.loginAccount')" data-cy="h3-1"></h3>
			<v-card data-cy="v-card-0">
				<KYC :route="$route.name" />
			</v-card>
		</v-container>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import KYC from '@/components/KYC';

export default {
	name: 'Register',

	components: { Page, KYC },
}
</script>

<style scoped lang="scss">
h3 {
	font-size: 36px;
}

.btn--underline {
	text-decoration: underline;
	font-size: 24px;
	text-transform: none;
	span {
		font-family: Roboto, sans-serif !important;
		font-weight: 400;
		letter-spacing: normal;
	}
}
</style>

