<template>
	<div>
    	<h3 class="mb-4">This combo includes the following product</h3>
    	<ul class="mb-4">
      		<li v-for="product in productsSummary" :key="product.name">
				{{ product.quantity }}x {{ product.name }}
			</li>
    	</ul>
		<div v-if="descriptionParts.firstPart">
			{{ descriptionParts.firstPart }}
			<span v-if="!showFullDescription && descriptionParts.secondPart?.length > 0">...</span>
			<a @click.prevent="toggleDescription" class="text-black" v-if="descriptionParts.secondPart?.length > 0 && !showFullDescription">
				Read More
  			</a>
			<span v-if="showFullDescription">{{ descriptionParts.secondPart }}</span>
		</div>
    	<div v-if="comboProductsDesc?.length && showFullDescription">Combo Includes:</div>
    	<ul v-if="comboProductsDesc?.length && showFullDescription">
			<li v-for="product in comboProductsDesc" :key="product.name">{{ capitalize(product.name) }}: {{ convertHtmlToPlainText(product.description) }}</li>
    	</ul>
		<a
			@click.prevent="toggleDescription"
			v-if="showFullDescription && descriptionParts.secondPart.length > 0"
			class="text-black"
		>
			Read Less
		</a>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	name: 'ComboDetailIntro',
	props: {
		initialState: {
			type: Boolean,
			default: false,
		},
		productsSummary: {
			type: Array,
			default: () => []
		},
		comboDescription: {
			type: String,
			default: () => ''
		},
		comboProductsDesc: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			showFullDescription: false,
		}
	},
	computed: {
		descriptionParts() {
			let firstPart = '';
			let secondPart = '';
			if (!this.comboDescription) {
				this.showFullDescription = true;
			} else {
				const formattedText = this.convertHtmlToPlainText(this.comboDescription);
				const words = formattedText.split(' ');

				firstPart = words.slice(0, 30).join(' ');
				secondPart = words.slice(30).join(' ');

				if (!secondPart) {
					this.showFullDescription = true;
				}
			}

			return {
				firstPart,
				secondPart
			}
		},
		showComboProductDescription() {
			return this.showFullDescription &&
				this.comboProductsDesc?.length &&
				this.comboProductsDesc.some(product => product?.description?.length > 0);
		}
	},
	methods: {
		toggleDescription() {
			this.showFullDescription = !this.showFullDescription;
		},
		convertHtmlToPlainText(htmlString) {
    		const parser = new DOMParser();
    		const dom = parser.parseFromString(htmlString, 'text/html');
    		return dom.body.textContent || '';
  		},
		capitalize(str) {
			return _.capitalize(str);
		}
	}
}
</script>
<style scoped lang="scss">
h3 {
	color: #413F3F;
}
h3, a {
	font-size: 18px;
	font-weight: 600;
	line-height: 23px;
	text-align: left;
}
li, p, div {
	font-weight: 400;
	font-size: 14px;
	color: #686A71;
}

a {
	font-weight: 600;
	text-decoration: underline;
}

</style>
