<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item"  data-cy="v-skeleton-loader-0"/>
	<v-form v-else :disabled="paying" data-test-selector="payment_form_payment_form" v-model="formPaymentValid" @submit.prevent="handlePayClick" data-cy="v-form-0">

		<PaymentProcessOverlay
			:paying.sync="paying"
			:success.sync="success"
			:error.sync="error"
		 data-cy="PaymentProcessOverlay-0"/>

		<v-text-field
			v-model="data.creditCard.name"
			name="ccname"
			prepend-inner-icon="mdi-account"
			:rules="[rules.required]"
			:label="$t('input.ccName')"
			:error-messages="formErrors.name"
			:autofocus="$vuetify.breakpoint.mdAndUp"
			:disabled="paying"
			autocomplete="cc-name"
			outlined
			@input="formErrors = {}"
		 data-cy="v-text-field-0"></v-text-field>

		<v-text-field
			v-model="data.creditCard.number"
			v-mask="'#### #### #### ####'"
			name="ccnumber"
			prepend-inner-icon="mdi-credit-card-outline"
			:rules="[rules.required, rules.creditCardNumber]"
			:label="$t('input.ccNumber')"
			:error-messages="formErrors.card_number"
			:disabled="paying"
			autocomplete="cc-number"
			outlined
			@input="formErrors = {}"
		 data-cy="v-text-field-1"></v-text-field>

		<v-row data-cy="v-row-0">
			<v-col data-cy="v-col-0">
				<v-text-field
					v-model="data.creditCard.exp"
					v-mask="'##/##'"
					name="exp"
					prepend-inner-icon="mdi-calendar-blank-outline"
					:rules="[rules.required, rules.creditCardExp]"
					:label="$t('input.ccExp')"
					:error-messages="formErrors.expiration"
					:disabled="paying"
					autocomplete="cc-exp"
					outlined
					@input="formErrors = {}"
				 data-cy="v-text-field-2"></v-text-field>
			</v-col>
			<v-col data-cy="v-col-1">
				<v-text-field
					v-model="data.creditCard.cvc"
					v-mask="'####'"
					name="cvc"
					prepend-inner-icon="mdi-shield-key-outline"
					:rules="[rules.required, rules.creditCardCvc]"
					:label="$t('input.ccCvc')"
					:error-messages="formErrors.security"
					:disabled="paying"
					autocomplete="cc-csc"
					outlined
					@input="formErrors = {}"
				 data-cy="v-text-field-3"></v-text-field>
			</v-col>
		</v-row>

		<v-text-field
			v-if="engine.data.fields.AVS"
			v-model="data.creditCard.zip"
			name="cczip"
			prepend-inner-icon="mdi-home-city-outline"
			:rules="this.postalRules"
			:label="$t('input.ccZip')"
			:error-messages="formErrors.zip"
			:disabled="paying"
			autocomplete="cc-zip"
			outlined
			@input="formErrors = {}"
		 data-cy="v-text-field-4"></v-text-field>

		<v-checkbox v-model="data.readTerms" :disabled="paying" name="read_terms" class="mt-0" :rules="[rules.required]" data-cy="v-checkbox-0">
			<template v-slot:label data-cy="template-1">
				<span data-cy="span-0">
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')" data-cy="span-1"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')" data-cy="a-0"></a>
				</span>
			</template>
		</v-checkbox>

		<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canPay" :loading="paying" block x-large>
			<span v-text="$t('btn.pay')"></span>
		</v-btn>
	</v-form>
</template>

<script>
import { Rules, PaymentService, EventBus, CartModel, BaseModel, EComService } from '@connectngo/sdk';
import PaymentProcessOverlay from '@/components/PaymentProcessOverlay';

export default {
	name: 'PayfactoForm',

	components: { PaymentProcessOverlay },

	props: {
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		engine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		balance: {
			type: Number,
			default: null
		},
	},

	data: () => ({
		paying: false,
		success: false,
		error: false,
		formPaymentValid: false,
		data: {
			readTerms: false,
			creditCard: {
				name: '',
				number: '',
				exp: '',
				cvc: '',
				zip: '',
			},
		},
		formErrors: {},
		rules: {},
		paymentLockSeconds: 0,
	}),

	computed: {
		canPay() {
			return this.formPaymentValid && !this.paying;
		},
		postalRules() {
			return this.$root.websiteConfig.data.fields.postal_code_required === '1' ? [this.rules.required] : [];
		}
	},

	methods: {
		handlePayClick() {
			this.pay();
		},
		handleTermsClick() {
			EventBus.publish('OPEN_PAGE', 'terms');
		},
		pay() {
			this.paying = true;
			
			new PaymentService().processPayfacto(
				this.engine.data.id,
				this.cart.data.uuid,
				this.cart.data.amountToPay,
				this.data.creditCard.name,
				this.data.creditCard.number,
				this.data.creditCard.exp,
				this.data.creditCard.cvc,
				this.cart.deposit
			)
				.then(response => {
					this.handlePaymentSuccess(response);
				})
				.catch(error => {
					this.handlePaymentError(error);
				})
		},

		handlePaymentSuccess(response) {
			this.success = true;
			setTimeout(() => {
				this.success = false;
				this.$emit('completed', response);
			}, 3000);
			this.paying = false;
		},

		handlePaymentError(error) {
			if (this.$isAlreadyPaid(error)) {
				this.paying = false;
				this.$emit('completed');
			}

			if (this.$isAlreadyLocked(error) && this.paymentLockSeconds < error.timeout) {
				this.checkPaymentLock();
			} else {
				this.paying = false;
				this.error = true;
				this.$handleError(this, error, this.formErrors, false);
			}
		},

		checkPaymentLock() {
			return new EComService().getPaymentLockStatus(this.cart.data.uuid)
				.then(response => {
					this.paymentLockSeconds = 0;
					this.handlePaymentSuccess(response);
				})
				.catch(error => {
					this.paymentLockSeconds += 2;
					setTimeout(() => {
						this.paying = true;
						this.handlePaymentError(error);
					}, 2000)
				})
		},

		handleCreditCardInformation(event) {
			this.data.creditCard = event;
		}
	},

	created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			creditCardNumber: value => Rules.creditCardNumber(value) || this.$t('rules.creditCardNumber'),
			creditCardCvc: value => Rules.creditCardCvc(value) || this.$t('rules.creditCardCvc'),
			creditCardExp: value => Rules.creditCardExp(value) || this.$t('rules.creditCardExp'),
			zipPostal: value => Rules.zipCode(value) || Rules.postalCode(value) || this.$t('rules.creditCardZipPostal'),
		};
	},
}
</script>
