import { render, staticRenderFns } from "./SwitchAccountModal.vue?vue&type=template&id=4c5cf80b&scoped=true&data-cy=template-0&"
import script from "./SwitchAccountModal.vue?vue&type=script&lang=js&"
export * from "./SwitchAccountModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5cf80b",
  null
  
)

export default component.exports