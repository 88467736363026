<template>
	<Page color="neutralLight" no-sidebar :no-top-page="cart.data.items.length === 0" data-cy="Page-0">
		<v-container class="py-md-4 pa-print-0" data-cy="v-container-0">
			<v-stepper data-testid="breadcrumb" v-model="step" class="d-print-none">
				<v-stepper-header>
					<template v-for="(item, itemIdx) in _steps">
						<v-divider :key="itemIdx + '_div'" v-if="itemIdx > 0"></v-divider>
						<v-stepper-step :editable="step > itemIdx + 1"
										edit-icon="mdi-check"
										:key="itemIdx + '_item'"
										:complete="step > itemIdx + 1"
										:step="itemIdx + 1"
										:rules="[() => !item.warning]">
							<span v-text="$t('cart.' + item.key + '.title')"></span>
						</v-stepper-step>
					</template>
				</v-stepper-header>
			</v-stepper>

			<v-skeleton-loader v-if="skeleton" type="image" class="mt-8"></v-skeleton-loader>
			<component v-else v-bind="_steps[step -1]" :cart.sync="cart" :payments.sync="payments" @next="handleNext"></component>
		</v-container>
	</Page>
</template>

<script>
	import Page from "@/components/Page";
	import Welcome from "@/routes/App/Cart/Welcome";
	import Identification from "@/routes/App/Cart/Identification";
	import Assignment from "@/routes/App/Cart/Assignment";
	import Payment from "@/routes/App/Cart/Payment";
	import {
		EComService,
		CartModel,
		EventBus,
		BaseModel,
		AccountModel,
	} from "@connectngo/sdk";
	import CompleteAccountForm from "@/components/CompleteAccountForm";
	import ConfigFieldsMixin from "@/mixins/ConfigFieldsMixin";

	export default {
		name: "Cart",

		mixins: [ConfigFieldsMixin],

		components: { Page },
		metaInfo() {
			return {
				title: this.$i18n.t("cart.welcome.title"),
				meta: [
					{
						property: "og:title",
						content: this.$i18n.t("cart.welcome.title"),
					},
					{ property: "og:url", content: window.location.href },
				],
			};
		},
		data: () => ({
			loading: false,
			skeleton: true,
			step: 1,
			steps: [
				{ key: "welcome", is: Welcome, class: "mt-8 pa-0", enabled: true },
				{
					key: "identification",
					is: Identification,
					style: "max-width: 30rem",
					class: "mt-8 pa-0",
					enabled: true
				},
				{
					key: "account_fields",
					is: CompleteAccountForm,
					style: "max-width: 25rem",
					class: "mt-8 pa-0",
					enabled: false,
					warning: true,
				},
				{
					key: "assignment",
					is: Assignment,
					class: "mt-8 pa-0",
					enabled: false,
				},
				{ key: "payment", is: Payment, class: "mt-8 pa-0" },
				{ key: "reload", disabled: true },
				{ key: "confirmation", disabled: true },
			],
			cart: new CartModel(),
			payments: new BaseModel(),
		}),

		computed: {
			_steps() {
				return this.steps.filter((step) => step.enabled !== false);
			},
		},

		watch: {
			step(value) {
				const step = this._steps[value - 1] || {};
				if (step.key !== this.$route.params.step && step.enabled) {
					this.goToStep(step.key);
				}
			},
		},

		methods: {
			goToStep(key) {
				this.step = this.getEnabledStepIndexByKey(key) + 1;
				this.$router.push({ name: "cart_step", params: { step: key }});
			},
			handleNext() {
				const step = this._steps[this.step];
				if (step) {
					this.goToStep(step.key);
				}
			},
			load() {
				this.loading = true;
				return new EComService()
					.getCart()
					.then((cart) => {
						return new EComService()
							.getCartPayments(cart.data.id)
							.then((payments) => {
								Object.assign(this, { cart, payments });
								if (this.cartHasAccountRequiredItems(cart)) {
									this.enableStep("assignment");
								}
								this.skeleton = false;
							});
					})
					.catch((reason) => {
						new EComService().logout().then(() => {
							if (reason.response.status === 422) {
								this.$snack(this.$i18n.t("error.session_expired"), "warning");
							} else {
								this.$handleError(this, reason);
							}
							this.$root.user.data = new AccountModel().data;
							this.storage.remove("cartId");
							this.load();
						});
					})
					.finally(() => (this.loading = false));
			},
			getStepByKey(key) {
				return this.steps.find((step) => step.key === key);
			},
			getEnabledStepIndexByKey(key) {
				return this._steps.findIndex((step) => step.key === key);
			},
			enableStep(key) {
				this.getStepByKey(key).enabled = true;
			},
			cartHasAccountRequiredItems(cart) {
				for (const item of cart.data.items) {
					if (
						item.data.product.data.account_required === 1 ||
						item.data.comboItems.some(combo => combo.product.account_required === 1)
					) {
						return true;
					}
				}

				return false;
			}
		},

		created() {
			if (this.$route.params.step === 'account_fields') {
				this.enableStep(this.$route.params.step);
			}
			if (this.getBooleanField('active_reload')) {
				this.enableStep("reload");
			}

			this.load().then(() => {
				const stepIdx = this.getEnabledStepIndexByKey(this.$route.params.step);
				if (stepIdx !== -1) {
					this.step = stepIdx + 1;
				}
			});

			EventBus.subscribe('MISSING_ACCOUNT_FIELDS', () => {
				this.enableStep("account_fields");
				this.goToStep("account_fields");
			});

			EventBus.subscribe("CART_UPDATED", (model) => {
				this.cart = model;
			});

			EventBus.subscribe("LOGOUT", () => {
				const identificationStep = this.getEnabledStepIndexByKey("identification") + 1;
				if (this.step > identificationStep) this.step = identificationStep;
			});
		},
	};
</script>

