<template>
	<a @click.prevent="$emit('clicked')" href="#">
		<img :src="image" :height="iconHeight" :alt="$t('digitalWallet.google')" data-cy="img-0"/>
	</a>
</template>
<script>
import en from '@/assets/images/google-wallet/en.svg'
import fr from '@/assets/images/google-wallet/fr.svg'
import es from '@/assets/images/google-wallet/es.svg'
import ar from '@/assets/images/google-wallet/ar.svg'

export default {
	name: "GoogleWallet",
	props : {
		iconHeight: {
			type: Number,
			default: 40
		}
	},
	computed: {
		image() {
			switch (this.$i18n.locale) {
				case 'fr':
					return fr
				case 'es':
					return es
				case 'ar':
					return ar
			}

			return en
		}
	}
}
</script>
