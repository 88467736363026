<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else :to="{ name: 'account_section_sub', params: { section: 'connected', sub: value.data.id } }" data-cy="v-card-0">
		<v-list-item data-cy="v-list-item-0">
			<v-list-item-icon data-cy="v-list-item-icon-0">
				<v-avatar size="76" color="secondaryLight" data-cy="v-avatar-0">
					<v-img :src="value.data.image" size="76" contain  data-cy="v-img-0"/>
				</v-avatar>
			</v-list-item-icon>
			<v-list-item-content data-cy="v-list-item-content-0">
				<h5 class="caption text-uppercase text--disabled" style="line-height: 1rem" v-text="$t('account.connected.profile')" data-cy="h5-0"></h5>
				<h3 class="title mb-1 secondaryDark--text" style="line-height: 1.5rem" v-text="value.data.firstname" data-cy="h3-0"></h3>
				<div class="overline font-weight-light text-none" style="line-height: 1rem" v-text="$options.filters.completeUserName(value)" data-cy="div-0"></div>
			</v-list-item-content>
		</v-list-item>

		<div class="px-4 pb-4 d-flex justify-end" data-cy="div-1">
			<v-list-item-action-text data-cy="v-list-item-action-text-0">
				<router-link class="text-decoration-none d-flex align-center" :to="{ name: 'account_section_sub', params: { section: 'connected', sub: value.data.id } }" @clic.stop data-cy="router-link-0">
					<span v-text="$t('account.connected.viewDetails')" data-cy="span-0"></span>
					<v-icon right x-small data-cy="v-icon-0">mdi-arrow-right</v-icon>
				</router-link>
			</v-list-item-action-text>
		</div>
	</v-card>
</template>

<script>
import { BaseModel } from '@connectngo/sdk';

export default {
	name: 'ConnectedAccountCard',

	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
