<template data-cy="template-0">
	<Page color="neutralLight" no-sidebar data-cy="Page-0">
		<v-container style="max-width: 35rem" class="py-12" data-cy="v-container-0">

			<h1 class="text-center display-1" v-text="registerTitle" data-cy="h1-0"></h1>

			<p class="mt-12" data-cy="p-0"><strong v-text="$t('register.advantages.title')" data-cy="strong-0">Advantages</strong></p>
			<ul class="mb-12" data-cy="ul-0">
				<li v-text="$t('register.advantages.line1')" data-cy="li-0"></li>
				<li v-text="$t('register.advantages.line2')" data-cy="li-1"></li>
			</ul>

			<v-card data-cy="v-card-0">
				<v-card-title v-text="$t('register.main')" data-cy="v-card-title-0"></v-card-title>
				<v-card-text data-cy="v-card-text-0">
					<CreateAccountForm @registerAsGuest="handleRegisterAsGuest" @completed="() => handleRegister()"  data-cy="CreateAccountForm-0"/>
				</v-card-text>
			</v-card>

			<v-card class="mt-8" data-cy="v-card-3">
				<v-card-title v-text="$t('register.alreadyHaveAccount')" data-cy="v-card-title-1"></v-card-title>
				<v-card-text data-cy="v-card-text-1">
					<v-btn color="button" block :to="handleConnect" data-cy="v-btn-0">
						<span v-text="$t('btn.connect')" data-cy="span-0"></span>
					</v-btn>
				</v-card-text>
			</v-card>
		</v-container>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import CreateAccountForm from '@/components/CreateAccountForm';

export default {
	name: 'Register',

	components: { Page, CreateAccountForm },
	metaInfo() {
		return {
			title: this.$i18n.t('register.title'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('register.title') },
				{ property: 'og:type', content: 'article' },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data() {
		return {
			registerAsGuest: false,
		}
	},

	computed: {
		handleConnect() {
			if(this.$route.params?.resId) {
				return {name : 'reservation', params : {lang: this.$route.params?.lang,	resId : this.$route.params?.resId}};
			}

			return { name : 'login'};
		},
		registerTitle() {
			if (this.registerAsGuest) {
				return this.$i18n.t('register.guest.title');
			}
			return this.$i18n.t('register.title');
		}
	},


	methods: {
		handleRegister() {
			if(this.$route.params?.resId) {
				this.$router.push(this.handleConnect)
				return;
			}
			this.$router.push({ name: 'home' });
		},
		handleRegisterAsGuest() {
			this.registerAsGuest = true;
		}
	},

}
</script>
