<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row class="mb-8" data-cy="v-row-0">
			<v-col cols="12" class="d-flex flex-column justify-center" data-cy="v-col-0">
				<h2 v-if="$route.params.is_b2b" class="display-1 font-weight-light" v-text="this.$root.user.data.company_name ? $t('account.wallet.title', {
					name: $root.user.data.company_name,
				}) : $t('account.company_missing')" data-cy="h2-0"></h2>
				<h2 v-else class="display-1 font-weight-light" v-text="$t('account.wallet.title', {
					name: $root.user.data.firstname,
				})" data-cy="h2-0"></h2>
			</v-col>
		</v-row>
		<v-alert v-if="items.results.length === 0" text prominent type="info" data-cy="v-alert-0">
			<span v-text="$t('account.wallet.empty')" data-cy="span-0"></span>
		</v-alert>
		<v-row data-cy="v-row-1">
			<v-col :key="item.guid" v-for="item in items.results" cols="12" lg="6" data-cy="v-col-1">
				<VirtualWalletCard :value="item" :user-name="$root.user.data.firstname" :skeleton="skeleton"  data-cy="VirtualWalletCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VirtualWalletCard from '@/components/VirtualWalletCard';
import { PaginationModel, EComService, WalletModel } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { VirtualWalletCard },

	data: () => ({
		skeleton: true,
		loading: false,
		items: new PaginationModel([{}, {}, {}], WalletModel),
	}),

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getVirtualWallets(this.$root.user.data.id),
			])
				.then(([walletAccounts]) => {
					Object.assign(this, { items: walletAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
