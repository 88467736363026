<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="article,list-item,list-item,list-item" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
		<div class="d-flex flex-no-wrap justify-space-between align-center" data-cy="div-0">
			<v-avatar class="ma-3" size="125" color="secondaryLight" style="flex: 0" data-cy="v-avatar-0">
				<v-img :src="value.data.image" data-cy="v-img-0"></v-img>
			</v-avatar>

			<div style="flex: 1" data-cy="div-1">
				<v-card-subtitle class="overline text--disabled py-0 mb-n1" v-text="fullView ? $t('accountCard.myProfile') : $t('accountCard.account')" data-cy="v-card-subtitle-0"></v-card-subtitle>
				<v-card-title
					class="text-h5 align-start pt-0 pb-4"
					style="justify-content: start !important"
					v-text="value.data.firstname"
					data-cy="v-card-title-0">
				</v-card-title>
				<v-card-subtitle class="font-italic" v-text="value.data.firstname + ' ' + value.data.lastname" data-cy="v-card-subtitle-1"></v-card-subtitle>
			</div>

			<v-btn v-if="fullView" color="secondaryDark" class="mr-4" :to="routeEdit" large icon data-cy="v-btn-0">
				<v-icon data-cy="v-icon-0">mdi-pencil</v-icon>
			</v-btn>
		</div>

		<v-card-text data-cy="v-card-text-0">
			<v-divider class="mb-4"  data-cy="v-divider-0"/>

			<v-list-item :key="itemIdx" v-for="(item, itemIdx) in items" dense data-cy="v-list-item-0">
				<v-list-item-icon data-cy="v-list-item-icon-0">
					<v-icon color="secondaryDark" v-text="item.icon" data-cy="v-icon-1"></v-icon>
				</v-list-item-icon>
				<v-list-item-content data-cy="v-list-item-content-0">
					<a v-if="item.href" :href="item.href" data-cy="a-0">
						<span v-text="item.text" data-cy="span-0"></span>
					</a>
					<span v-else v-text="item.text" data-cy="span-1"></span>
				</v-list-item-content>
			</v-list-item>

			<v-list-item v-if="fullView" class="justify-end" data-cy="v-list-item-3">
				<v-list-item-action-text data-cy="v-list-item-action-text-0">
					<router-link class="text-decoration-none d-flex align-center" :to="routeEdit" data-cy="router-link-0">
						<span v-text="$t('accountCard.edit')" data-cy="span-2"></span>
						<v-icon right x-small data-cy="v-icon-2">mdi-arrow-right</v-icon>
					</router-link>
				</v-list-item-action-text>
			</v-list-item>
		</v-card-text>
	</v-card>
</template>

<script>
import { AccountModel } from '@connectngo/sdk';

export default {
	name: 'AccountCard',

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		fullView: {
			type: Boolean,
			default: true,
		},
		routeEdit: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'edit' } }),
		},
	},

	methods: {
		getItems() {
			return [
				// { icon: 'mdi-calendar', text: this.$t('accountCard.born', {
				// 	date: this.$options.filters.tsToFormat(this.value.data.birthdate, this.$i18n.t('date.format.ymd'))
				// }) },
				{ icon: 'mdi-email-outline', text: this.value.data.email, href: 'mailto:' + this.value.data.email },
				// { icon: 'mdi-hospital-box-outline', text: this.$t('accountCard.allergies', {
				// 	value: this.value.data.allergies || this.$i18n.t('accountCard.none'),
				// }) },
				{ icon: 'mdi-home-outline', text: this.$t('accountCard.address', {
					address: [
						this.value.data.address.address1,
						this.value.data.address.address2,
						this.value.data.address.city,
						this.value.data.address.country,
						this.value.data.address.state_province_region,
						this.value.data.address.zipcode_postalcode,
					].filter(item => item !== null).join(', ') || this.$i18n.t('accountCard.none'),
				}) },
				// { icon: 'mdi-phone-outline', text: this.$t('accountCard.phone', {
				// 	phone: this.value.data.phone || this.$i18n.t('accountCard.none'),
				// }) },
			];
		},
	},

	watch: {
		value: {
			deep: true,
			immediate: true,
			handler() {
				this.items = this.getItems();
			},
		},
	},
}
</script>
