<template data-cy="template-0">
	<v-sheet color="transparent" data-cy="v-sheet-0">

		<PaymentProcessOverlay
			:paying.sync="paying"
			:success.sync="success"
			:error.sync="error"
		 data-cy="PaymentProcessOverlay-0"/>

		<v-card v-if="!paymentMethod" data-cy="v-card-0">
			<v-card-title v-text="$t('cart.payment.choseMethod')" data-cy="v-card-title-0"></v-card-title>
			<v-card-text data-cy="v-card-text-0">
				<v-btn data-testid="ccPayment" color="button" block :disabled="!component || fullDiscount" @click="handlePaymentMethod('credit')" x-large data-cy="v-btn-0">
					<v-icon left data-cy="v-icon-0">mdi-credit-card-outline</v-icon>
					<span v-text="$t('cart.payment.creditCard')" data-cy="span-0"></span>
				</v-btn>
				<v-btn data-testid="gcPayment" color="button" class="mt-4" block :disabled="!cashless || fullDiscount" @click="handlePaymentMethod('gift')" x-large data-cy="v-btn-1">
					<v-icon left data-cy="v-icon-1">mdi-gift-outline</v-icon>
					<span v-text="$t('cart.payment.giftCard')" data-cy="span-1"></span>
				</v-btn>
				<v-btn data-testid="arPayment" v-if="accountReceivable" color="button" class="mt-4" block :disabled="!accountReceivable" @click="handlePaymentMethod('accountReceivable')" x-large data-cy="v-btn-2">
					<v-icon left data-cy="v-icon-2">mdi-cash-usd-outline</v-icon>
					<span v-text="$t('cart.payment.accountReceivable')" data-cy="span-2"></span>
				</v-btn>
				<v-btn data-testid="cashlessPayment" v-if="active_cashless" color="button" class="mt-4" block :disabled="!cashless || fullDiscount" @click="handlePaymentMethod('cashless')" x-large data-cy="v-btn-2">
					<v-icon left data-cy="v-icon-2">mdi-cash-usd-outline</v-icon>
					<span v-text="$t('cart.payment.cashless')" data-cy="span-2"></span>
				</v-btn>
			</v-card-text>
		</v-card>
		<v-card v-else-if="paymentMethod === 'credit'" data-cy="v-card-3">
			<v-card-title v-text="$t('cart.payment.enterDetails')" data-cy="v-card-title-1"></v-card-title>
			<v-card-text data-cy="v-card-text-1">
				<component
					:is="component"
					:cart="cart"
					:engine="engine"
					:skeleton="skeleton"
					@completed="handlePaymentCompleted"
					@paymentOverlay="handlePaymentOverlay"
				 data-cy="component-0"/>
				<v-btn data-testid="cancelPayment" class="mt-4" x-large text block @click="handlePaymentMethod(null)" data-cy="v-btn-3">
					<span v-text="$t('btn.cancel')" data-cy="span-3"></span>
				</v-btn>
			</v-card-text>
		</v-card>
		<GiftCardCard v-else-if="paymentMethod === 'gift'" :component="component" :cart="cart" :engine="engine" :cashless-engine="cashlessEngine" :payments.sync="payments" :skeleton="skeleton" @cancel="handlePaymentMethod(null)" @completed="handlePaymentCompleted"  data-cy="GiftCardCard-0"/>
		<CashlessCard v-else-if="paymentMethod === 'cashless'" :component="component" :cart="cart" :engine="engine" :cashless-engine="cashlessEngine" :payments.sync="payments" :skeleton="skeleton" @cancel="handlePaymentMethod(null)" @completed="handlePaymentCompleted"  data-cy="CashlessCard-0"/>
		<AccountReceivableCard v-else-if="paymentMethod === 'accountReceivable'" :component="component" :cart="cart" :engine="engine" :account-receivable-engine="accountReceivableEngine" :payments.sync="payments" :skeleton="skeleton" @cancel="handlePaymentMethod(null)" @completed="handlePaymentCompleted"  data-cy="AccountReceivableCard-0"/>

		<div v-if="verifiedBy !== null" class="mt-8 text-center" data-cy="div-0">
			<img :src="verifiedBy"  data-cy="img-0"/>
		</div>
	</v-sheet>
</template>

<script>
	import verifiedByAdyen from "@/assets/images/verified-adyen.png";
	import GiftCardCard from "./GiftCardCard";
	import AccountReceivableCard from "./AccountReceivableCard";
	import CashlessCard from "./CashlessCard";
	import NmiForm from "./NmiForm";
	import PayfactoForm from "./PayfactoForm";
	import AmazonPaymentForm from "./AmazonPaymentForm";
	import AdyenForm from "./AdyenForm";
	import BamboraForm from "./BamboraForm";
	import {
		EComService,
		CartModel,
		BaseModel,
		PaymentModel,
	} from "@connectngo/sdk";
	import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";

	export default {
		name: "PaymentForm",

		components: { GiftCardCard, CashlessCard, PaymentProcessOverlay,AccountReceivableCard },

		props: {
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
			skeleton: {
				type: Boolean,
				default: false,
			},
		},

		watch: {
			cart(newValue, oldValue) {
				if (
					Object.keys(newValue?.getAppliedDiscounts())?.length > 0 &&
					newValue.data?.total <= 0
				) {
					this.paymentMethod = null;
					this.fullDiscount = true;
				} else {
					this.fullDiscount = false;
				}
			},
		},

		data: () => ({
			verifiedBy: null,
			engine: new BaseModel(),
			cashlessEngine: new BaseModel(),
			cashless: false,
			accountReceivableEngine: new BaseModel(),
			accountReceivable: false,
			component: null,
			paymentMethod: null,
			fullDiscount: false,
			paying: false,
			success: false,
			error: false,
			active_cashless: false,
			active_account_receivable:false
		}),

		methods: {
			/* Google Tag Manager */
			triggerGAEvent() {
				const items = [];
				this.cart.data.items.forEach((item, index) => {
					items.push({
						item_id: item.data.product.data.id,
						item_name: this.$options.filters.translatable(
							item.data.product.data.name,
							item.data.product.data.name_i18n,
							this.$i18n.locale
						),
						coupon: item.data.hasDiscount
							? this.$options.filters.translatable(
									item.data.discount.name,
									item.data.discount.name_i18n,
									this.$i18n.locale
							  )
							: null,
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						discount: item.data.discountTotal,
						index: index++,
						item_category: item.data.mainTag
							? this.$options.filters.translatable(
									item.data.mainTag.name,
									item.data.mainTag.name_i18n,
									this.$i18n.locale
							  )
							: null,
						price: item.data.total,
						quantity: item.data.quantity,
					});
				});
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "add_payment_info",
						ecommerce: {
							currency:
								this.$root.websiteConfig.data.tenant.currency.code,
							value: this.cart.data.total,
							payment_type: this.paymentMethod,
							items: items,
						},
					});
				}
			},

			handlePaymentMethod(method) {
				this.paymentMethod = method;
			},

			handlePaymentOverlay(args) {
				[this.paying, this.success, this.error] = args;
			},

			handlePaymentCompleted(args) {
				this.triggerGAEvent();
				this.$emit("completed", args);
			},

			init() {
				new EComService().getPaymentEngines().then((response) => {
					response.results.forEach((engine) => {

						let amount = this.balance ? this.balance : this.cart.data.balance || this.cart.data.total;

						if(this.cart.deposit){
							amount = this.cart.depositAmount;
						}

						this.cart.data.amountToPay = amount;

						switch (engine.data.payment_engine.name) {
							case "nmi":
								this.engine = engine;
								this.component = NmiForm;
								break;
							case "payfacto_api":
								this.engine = engine;
								this.component = PayfactoForm;
								break;
							case "adyen_ecom":
								this.component = AdyenForm;
								this.verifiedBy = verifiedByAdyen;
								this.engine = engine;
								break;
							case "bambora_ecom_iframe":
								this.component = BamboraForm;
								this.engine = engine;
								break;
							case "amazon_payment":
								this.component = AmazonPaymentForm;
								this.engine = engine;
								break;
							case "cashless":
								this.cashlessEngine = engine;
								this.cashless = true;
								break;
							case "account_receivable":
								if (this.cart.data.account?.can_receivable) {
									this.accountReceivableEngine = engine;
									this.accountReceivable = true;
								}
								break;
						}
					});
				});
			},

			isIframe() {
				try {
					return window.self !== window.top;
				} catch (e) {
					return true;
				}
			},
		},

		created() {
			this.init();
			if (
				this.$root.websiteConfig.data.fields.active_cashless?.toString() ===
				"1"
			) {
				this.active_cashless = true;
			}
			if (this.$route.name === "cart_payment_3ds") {
				this.paymentMethod = "credit";
				this.paying = true;
			}
			if (
				this.$route.name === "cart_payment_3ds_prepare" &&
				this.$route.params.engine == "bambora_ecom_iframe"
			) {
				if (this.isIframe()) {
					top.location.href = window.location.href;
				}
			}
		},
	};
</script>
