var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"color":"neutralLight","breadcrumbs":"","no-sidebar":"","show-combos":"","data-cy":"Page-0"}},[_c('v-container',{staticClass:"py-md-0 pt-md-6",attrs:{"data-cy":"v-container-0"}},[(_vm.isHardCrossSell)?_c('CrossSellModal',{attrs:{"visible":_vm.crossSellModal.visible,"products":_vm._crossSellProducts,"events":_vm.eventsForCrossSells,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"scrollable":"","max-width":"1000","data-cy":"CrossSellModal-0"},on:{"update:visible":function($event){return _vm.$set(_vm.crossSellModal, "visible", $event)},"continue":_vm.onCrossSellContinue}}):_vm._e(),_c('v-row',{attrs:{"data-cy":"v-row-0"}},[_c('v-col',{staticClass:"mt-n6 pl-md-6",attrs:{"cols":"12","md":"5","data-cy":"v-col-0"}},[_c('Sticky',{staticClass:"pt-6 py-md-6",attrs:{"data-cy":"Sticky-0"}},[_c('v-card',{attrs:{"flat":"","data-cy":"v-card-0"}},[_c('v-img',{attrs:{"src":_vm.details.data.image,"aspect-ratio":16 / 9,"data-cy":"v-img-0"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"image","data-cy":"v-skeleton-loader-0"}})]},proxy:true}])},[(_vm.details.data.promotion)?_c('v-overlay',{staticClass:"align-start justify-start pa-4",attrs:{"absolute":"","opacity":"0","data-cy":"v-overlay-0"}},[_c('v-card',{staticClass:"promotion px-4 py-0 overline",attrs:{"color":"secondaryDark","dark":"","data-cy":"v-card-1"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('product.promotion'))}})])],1):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"pr-md-6",attrs:{"cols":"12","md":"7"}},[_c('v-card',{attrs:{"outlined":""}},[(_vm.skeleton)?[_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mt-4",attrs:{"type":"paragraph"}})],1)]:[_c('v-card-title',{staticClass:"text-left secondaryDark--text pb-3"},[_c('span',{staticClass:"text-capitalize-fl",domProps:{"textContent":_vm._s(
									_vm.$options.filters.translatable(
										_vm.details.data.name,
										_vm.details.data.name_i18n
									)
								)}})]),_c('v-card-text',[(_vm.hasQuantity)?_c('div',{staticClass:"caption"},[(
										_vm.details.data.min_quantity !== 0 &&
										_vm.details.data.min_quantity !== null &&
										_vm.details.data.max_quantity !== 0 &&
										_vm.details.data.max_quantity !== null
									)?_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t('product.quantityBoth', {
											min: _vm.details.data.min_quantity,
											max: _vm.details.data.max_quantity,
										})
									)}}):(
										_vm.details.data.min_quantity !== 0 &&
										_vm.details.data.min_quantity !== null
									)?_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t('product.quantityMin', {
											min: _vm.details.data.min_quantity,
										})
									)}}):(
										_vm.details.data.max_quantity !== 0 &&
										_vm.details.data.max_quantity !== null
									)?_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t('product.quantityMax', {
											max: _vm.details.data.max_quantity,
										})
									)}}):_vm._e()]):_vm._e(),(_vm.details.data.priceVerifiedViaApi)?_c('div',[(
										_vm.details.data.msrp &&
										_vm.details.data.msrp >=
											_vm.details.data.price
									)?_c('span',{staticClass:"mr-4",staticStyle:{"text-decoration":"line-through"},domProps:{"textContent":_vm._s(
										_vm.$options.filters.currency(
											_vm.details.data.msrp
										)
									)}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t('product.priceEach', {
											price: _vm.$options.filters.currency(
												_vm.details.data.price
											),
										})
									)}})]):_vm._e(),(_vm.details.data.priceVerifiedViaApi)?_c('div',{staticClass:"d-flex align-center justify-space-between mt-3"},[_c('h5',{staticClass:"text-h5 font-weight-bold",domProps:{"textContent":_vm._s(
										_vm.$options.filters.currency(
											_vm.details.data.price
										)
									)}})]):_c('v-skeleton-loader',{attrs:{"type":"heading"}})],1)]],2),_c('div',{staticClass:"mt-4"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"paragraph"}}):_c('p',{staticClass:"body-1 font-weight-light"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formattedDescription)}}),(_vm.canShowAllDesc)?_c('a',{staticClass:"secondaryDark--text",attrs:{"href":""},domProps:{"textContent":_vm._s(_vm.showAllDesc ? _vm.$t('product.readLess') : _vm.$t('product.readMore'))},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showAllDesc = !_vm.showAllDesc}}}):_vm._e()])],1),(!_vm.skeleton)?_c('ProductCard',{attrs:{"product":_vm.details,"skeleton":_vm.skeleton,"event-group-ids":_vm.eventGroupIds},on:{"update:product":function($event){_vm.details=$event}}}):_vm._e()],1)],1),_c('div',{staticClass:"px-md-3 mb-8"},[(
					!_vm.isHardCrossSell &&
					_vm._crossSellProducts.results.length > 0
				)?_c('div',{staticClass:"mt-8"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"heading"}}):_c('h2',{staticClass:"display-1 font-weight-light text-center",domProps:{"textContent":_vm._s(_vm.$t('tag.otherOptions'))}}),_c('v-row',{staticClass:"mt-6"},_vm._l((_vm._crossSellProducts.results),function(product,index){return _c('v-col',{key:index,staticStyle:{"min-width":"20rem"}},[_c('Product',{attrs:{"product":product,"skeleton":_vm.skeleton,"is-cross-sell":true,"show-calendar":!_vm.showCalendar,"day-selected":_vm.selectedDay},on:{"update:daySelected":function($event){_vm.selectedDay=$event},"update:day-selected":function($event){_vm.selectedDay=$event}}})],1)}),1)],1):_vm._e()]),_c('div',{class:['sticky-container rounded py-5 px-5 mx-md-3 mx-0',_vm.$root.cart.data.itemCount ? 'active' : '']},[_c('div',{staticClass:"button-container"},[_c('v-btn',{attrs:{"color":"button","outlined":"","x-large":"","to":{ name: 'home' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.seeAllOptions'))}})],1),_c('v-btn',{staticClass:"ml-5",attrs:{"data-testid":"checkoutBtn","color":"button","disabled":_vm.$root.cart.data.itemCount === 0,"x-large":""},on:{"click":_vm.onProceedCheckout}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.proceedCheckout'))}})],1)],1)])],1),(_vm.hasInteractiveMap)?_c('InteractiveMapCrossSellModal',{attrs:{"visible":_vm.showInteractiveMapModal,"next-action":() => _vm.$router.push({ name: 'cart' })},on:{"modalClosed":function($event){_vm.showInteractiveMapModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }