<template data-cy="template-0">
	<v-dialog dark v-model="visible" scrollable max-width="600" data-cy="v-dialog-0">
		<v-card :color="color" data-cy="v-card-0">
			<v-card-title class="headline text-left" data-cy="v-card-title-0">
				<v-icon slot="icon" size="36" left data-cy="v-icon-0">mdi-alert-box</v-icon>
				<span v-text="title.length > 64 ? $t('error.defaultTitle') : title" data-cy="span-0"></span>
			</v-card-title>

			<v-card-text v-if="showMessage" class="my-5" data-cy="v-card-text-0">
				<h3 v-if="title.length > 64" class="mb-4" v-text="title" data-cy="h3-0"></h3>
				<div v-html="message" data-cy="div-0"></div>
			</v-card-text>

			<v-card-actions data-cy="v-card-actions-0">
				<v-spacer data-cy="v-spacer-0"></v-spacer>

				<v-btn :data-test-selector="'error_handler_btn_' + index" v-for="(button, index) in buttons" :key="index" text @click="button.callback ? buttonCallback(button) : visible = false" data-cy="v-btn-0">
					<span v-text="button.text" data-cy="span-1"></span>
				</v-btn>

				<v-btn v-if="buttons.length === 0" data-test-selector="error_handler_close" text @click="visible = false" data-cy="v-btn-1">
					<span v-text="$t('modal.close')" data-cy="span-2"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Vue from 'vue';
import RestError from "../utils/RestError";
import ServerError from "../utils/ServerError";
import * as Sentry from '@sentry/vue'

export default Vue.extend({
	name: 'ErrorHandler',
	props: ['response'],

	methods: {

		close() {
			this.visible = false;
		},

		buttonCallback(button) {
			button.callback(this);
		},

		handleResponse(response) {

			this.showMessage = this.env !== 'production';
			this.buttons = [];
			this.color = 'default';
			Sentry.captureException(response);
			if (response instanceof RestError) {
				this.visible = response.response.status < 200 || response.response.status >= 300;
				this.title = response.response.status + ' ' + response.response.statusText;
				this.message = response.data;
			} else if (response instanceof ServerError) {
				this.visible = true;
				this.title = response.title;
				this.message = response.desc;
				this.color = 'error';

				if (response.options) {
					this.buttons = response.options.buttons || [];
				}

				this.showMessage = true;
			} else {
				this.visible = response.desc !== undefined;
				this.title = (response.statusCode ? response.statusCode + ': ' : '') + response.desc;
				this.message = response.stack;
			}
		},
	},

	watch: {
		response(newVal, oldVal) {
			this.handleResponse(newVal);
		},
	},

	data() {
		return {
			showMessage: false,
			visible: false,
			buttons: [],
			color: null,
			env: process.env.NODE_ENV,
			title: '',
			message: '',
		};
	},
});
</script>

<style lang="scss" scoped>
	.v-card::v-deep pre {
		padding: 2rem;
		border-radius: 0.25rem;
		background-color: rgba(0, 0, 0, 0.05);
		white-space: pre-wrap;
	}
</style>
