var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"data-cy":"v-container-0"}},[_c('AddSubAccountModal',{attrs:{"visible":_vm.addSubAccountModal,"parent-account":_vm.$root.user,"scrollable":"","max-width":"500","data-cy":"AddSubAccountModal-0"},on:{"update:visible":function($event){_vm.addSubAccountModal=$event},"created":_vm.handleSubAccountCreated}}),_c('v-row',{attrs:{"data-cy":"v-row-0"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","md":"6","data-cy":"v-col-0"}},[(_vm.$route.params.is_b2b)?_c('h2',{staticClass:"display-1 font-weight-light",attrs:{"data-cy":"h2-0"},domProps:{"textContent":_vm._s(this.$root.user.data.company_name ? _vm.$t('account.connected.title', {
				name: _vm.$root.user.data.company_name,
			}) : _vm.$t('account.company_missing'))}}):_c('h2',{staticClass:"display-1 font-weight-light",attrs:{"data-cy":"h2-0"},domProps:{"textContent":_vm._s(_vm.$t('account.connected.title', {
				name: _vm.$root.user.data.firstname,
			}))}})]),_c('v-col',{staticClass:"d-flex flex-column justify-center align-end",attrs:{"cols":"12","md":"6","data-cy":"v-col-1"}},[_c('v-btn',{class:{
				'py-3 v-size--stretch': true,
			},attrs:{"color":"button","outlined":"","block":_vm.$vuetify.breakpoint.smAndDown,"data-cy":"v-btn-0"},on:{"click":_vm.handleCreateSubAccountClick}},[_c('v-icon',{attrs:{"left":"","data-cy":"v-icon-0"}},[_vm._v("mdi-account-plus")]),_c('span',{class:{
					'text-left ml-3 text-none': true,
					'text-wrap': _vm.$vuetify.breakpoint.smAndDown,
				},staticStyle:{"width":"min-content","margin":"auto","flex":"1"},attrs:{"data-cy":"span-0"}},[_c('span',{attrs:{"data-cy":"span-1"},domProps:{"textContent":_vm._s(_vm.$t('account.connected.addMember'))}})])],1)],1)],1),_c('v-row',{attrs:{"data-cy":"v-row-1"}},[(_vm.items.results.length === 0)?_c('v-col',{attrs:{"cols":"12","data-cy":"v-col-2"}},[_c('v-alert',{staticClass:"ma-0",attrs:{"text":"","prominent":"","type":"info","data-cy":"v-alert-0"}},[_c('span',{attrs:{"data-cy":"span-2"},domProps:{"textContent":_vm._s(_vm.$t('connectedAccountListingCard.empty'))}})])],1):_vm._e(),_vm._l((_vm.items.results),function(item){return _c('v-col',{key:item.guid,attrs:{"cols":"12","lg":"6","data-cy":"v-col-3"}},[_c('ConnectedAccountCard',{attrs:{"value":item,"skeleton":_vm.skeleton,"data-cy":"ConnectedAccountCard-0"}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }