<template data-cy="template-0">
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners" no-close data-cy="Modal-0">
		<template #body data-cy="template-1">
			<div class="text-center" data-cy="div-0" v-if="interactiveMap">
				<v-card-text class="px-0 px-md-4" data-cy="v-card-text-0">
					<h1 class="display-1" v-text="$t('interactiveMap.modalTitle')" data-cy="h1-0"></h1>
					<v-container class="cross-sell-container" data-cy="v-container-0">
						<InteractiveMapButton :interactive-map="interactiveMap" :on-click="() => {_visible = false}" />
					</v-container>
				</v-card-text>

				<v-divider v-if="products.results.length > 0" class="mb-4 mx-7"></v-divider>
			</div>
			<div  v-if="products.results.length > 0" class="text-center" data-cy="div-0">
				<h1 class="display-1" v-text="$t('crossSellModal.title')" data-cy="h1-0"></h1>
				<p v-text="$t('crossSellModal.body')" data-cy="p-0"></p>
			</div>

			<v-card-text data-testid="xSellModal" v-if="products.results.length > 0" class="px-0 px-md-4" data-cy="v-card-text-0">
				<v-container class="cross-sell-container" data-cy="v-container-0">
					<v-row class="mt-6" data-cy="v-row-0">
						<v-col
							cols="12"
							md="6"
							:key="product.guid"
							v-for="product in products.results"
						 data-cy="v-col-0">
							<Product
								:product="product"
								:skeleton="skeleton"
								:is-cross-sell="true"
								:event-id.sync="events[product.guid]"
							 data-cy="Product-0"/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</template>
		<template #actions>
			<div class="d-flex w-100 justify-end" style="gap: 0.5rem">
				<v-btn
					data-testid="xSellCancel"
					color="button"
					data-test-selector="crossSellModal_cancel"
					:disabled="loading"
					:loading="loading"
					outlined
					@click="_visible = false"
				>
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
				<v-btn
					data-testid="xSellContinue"
					type="submit"
					color="button"
					data-test-selector="crossSellModal_add"
					:disabled="loading"
					:loading="loading"
					@click="handleContinueClick"
				>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from "./Modal";
import Product from "./Product";
import { PaginationModel } from "@connectngo/sdk";
import InteractiveMapButton from "@/components/InteractiveMap/InteractiveMapButton";

export default {
	name: "CrossSellModal",

	components: {InteractiveMapButton, Modal, Product },

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		products: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		events: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			loading: false,
			skeleton: false,
		};
	},

	computed: {
		_visible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit("update:visible", value);
			},
		},
		interactiveMap() {
			return this.$root.websiteConfig.data.interactive_map;
		}
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},
		handleContinueClick() {
			this._visible = false;
			this.$emit("continue");
		},
		load() {
			this.skeleton = false;
			this.loading = false;
		},
	},

	created() {
		this.load();
	},
};
</script>
