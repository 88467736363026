<template data-cy="template-0">
	<Page color="neutralLight" no-sidebar data-cy="Page-0">
		<template #prepend data-cy="template-1">
			<v-container class="pa-0" fluid data-cy="v-container-0">
				<Carousel v-if="carouselItems" v-model="carouselItems" :skeleton="skeleton" :cycle="true"  data-cy="Carousel-0"/>
			</v-container>
		</template>
		<v-container class="py-12 text-center">

			<!-- HOME BLOCKS -->
			<template v-if="_homeHtmlBlockActive">
				<div class="my-12" v-html="homeHtmlBlock"></div>
			</template>

			<template v-if="interactiveMap">
				<InteractiveMapButton class="mb-12" :interactive-map="interactiveMap" />
			</template>

			<!-- TAGS -->
			<template v-if="_tags.results.length > 0">
				<template v-if="headerHomePage.length === 0">
					<h2 class="display-1 font-weight-light" v-text="$t('home.allOptions')"></h2>
				</template>
				<template v-else>
					<div class="header_homepage" v-html="headerHomePage"></div>
				</template>
				<v-row class="mt-8">
					<v-col cols="12" md="6" :key="index" v-for="(tag, index) in _tags.results">
						<ProductTag v-bind="tag.data" :to="{ name: 'tag', params: { key: tag.data.slug } }" :skeleton="skeleton" dark />
					</v-col>
				</v-row>
			</template>

			<!-- PRODUCTS -->
			<template v-if="_products.results.length > 0">
				<h2 :class="{
					'display-1 font-weight-light': true,
					'mt-12': _tags.results.length > 0,
				}" v-text="$t('home.popularProducts')"></h2>
				<v-row class="mt-8">
					<v-col cols="12" md="6" xl="4" :key="index" v-for="(product, index) in _products.results">
						<Product v-if="product.data.type === 'product'" :product="product.data.item" :skeleton="skeleton" basic :to="{ name: 'product', params: { slug: product.data.item.data.slug } }" />
					</v-col>
				</v-row>
			</template>
		</v-container>
	</Page>
</template>

<script>
import ProductTag from '@/components/ProductTag'
import Product from '@/components/Product'
import Page from '@/components/Page'
import Carousel from '@/components/Carousel'

import {PaginationModel, FeaturedItemModel, EComService, EventBus} from '@connectngo/sdk'
import InteractiveMapButton from "@/components/InteractiveMap/InteractiveMapButton";

export default {
	name: "Home",
	components: {InteractiveMapButton, Page, ProductTag, Product, Carousel },
	metaInfo() {
		return {
			title: this.$i18n.t('route.home'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.home') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},
	data: () => ({
		skeleton: true,
		loading: true,
		tags: new PaginationModel(),
		products: new PaginationModel(),
		carouselItems: null,
		homeHtmlBlockActive: false,
		homeHtmlBlock: "",
		interactiveMap : null,
		b2bLoginEvent: null
	}),

	computed: {
		_tags() {
			return this.skeleton ? new PaginationModel([{},{},{}]) : this.tags;
		},
		_products() {
			return this.skeleton ? new PaginationModel([{},{},{},{}], FeaturedItemModel) : this.products;
		},
		_homeHtmlBlockActive() {
			return this.homeHtmlBlockActive;
		},
		headerHomePage() {
			try {
				return this.$options.filters.translatable
				(
					'',
					this.$root.websiteConfig.data.fields.header_homepage,
					this.$i18n.locale
				);
			}
			catch (e) {}
			return null;
		}
	},

	methods: {
		load() {
			this.loading = true;
			const hideMapConfig = this.$root.websiteConfig.data.fields.hasOwnProperty('hide_interactive_map') ?
				Number(this.$root.websiteConfig.data.fields.hide_interactive_map) : 0;

			this.interactiveMap = !hideMapConfig ? this.$root.websiteConfig.data.interactive_map : null;

			const blockActive = this.$root.websiteConfig.data.fields.home_html_block_active;

			if (blockActive !== '0' && blockActive) {
				try {
					this.homeHtmlBlockActive = true;
					this.homeHtmlBlock = this.$options.filters.translatable
					(
						'',
						this.$root.websiteConfig.data.fields.home_html_block,
						this.$i18n.locale
					);
				}
				catch (e) {
					this.homeHtmlBlockActive = false;
					this.homeHtmlBlock = "";
				}
			}

			new EComService().getCarouselItems()
				.then(carouselItemsResponse => {
					if (carouselItemsResponse.results.length > 5) {
						carouselItemsResponse.results = carouselItemsResponse.results.slice(0, 5);
						this.carouselItems = carouselItemsResponse;
					} else if (carouselItemsResponse.results.length > 0)  {
						this.carouselItems = carouselItemsResponse;
					}
				})
				let tags = this.$route.params.is_b2b ? new EComService().getB2bTags() : new EComService().getTags();
				return Promise.all([
					tags,
					new EComService().getPromotedProducts(),
				])
				.then(([tagsResponse, promotedProductsResponse]) => {
					this.tags = tagsResponse;
					this.tags.results = this.tags.results.filter(tag => tag.data.homepage);
					this.products = promotedProductsResponse;
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		}
	},

	created() {
		this.load();
		this.b2bLoginEvent = EventBus.subscribe("B2B_LOGGIN_CHANGE", () => this.load());
	},
	destroyed() {
		this.b2bLoginEvent.unsubscribe();
	}
}
</script>

<style lang="scss" scoped>
	div.header_homepage {
		font-weight: 300 !important;
		font-size: 2.125rem !important;
		line-height: 2.5rem;
		letter-spacing: 0.0073529412em !important;
		font-family: "Roboto", sans-serif !important;
		margin: 1px;
	}
	@media (max-width:300px) {
		div.header_homepage {
			font-size: 1.5rem !important;
		}
	}
	@media (max-width:600px) {
		div.header_homepage {
			font-size: 1.5rem !important;
		}
	}
	@media (max-width:1024px) {
		div.header_homepage {
			font-size: 1.7rem !important;
		}
	}

</style>

