<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
		<!-- WAIVER MODAL -->
		<Modal v-model="showPdf" :title="$t('productWaiverCard.pdfModal.title')" max-width="800" data-cy="Modal-0">
			<template #body data-cy="template-1">
				<iframe :src="waiverPdf.data.base_64" class="waiver_pdf" data-cy="iframe-0"></iframe>
			</template>
		</Modal>
		<v-card-title v-if="!hideTitle" class="text-left secondaryDark--text font-weight-bold" v-text="$t('admissionAssignCard.title')"></v-card-title>
		<div :key="itemIdx" v-for="(item, itemIdx) in _items">
			<v-card-text>
				<v-alert v-if="_items.length === 0" text prominent type="info">
					<span v-text="$t('admissionAssignCard.empty')"></span>
				</v-alert>

				<v-divider v-if="itemIdx > 0" class="my-4" />
				<div class="d-flex align-center justify-space-between">
					<v-icon class="mr-4" x-large>mdi-ticket-outline</v-icon>
					<div style="flex: 1">
						<div class="caption text--disabled text-uppercase" v-text="$t('admissionAssignCard.admission')"></div>
						<h4 class="my-1" v-text="$options.filters.translatable(item.data.ticket_type.name, item.data.ticket_type.name_i18n, $i18n.locale)"></h4>
						<h5 class="text-uppercase" v-text="$options.filters.translatable(item.data.ticket_type.validity.name, item.data.ticket_type.validity.name_i18n, $i18n.locale)"></h5>
						<h5 v-text="$t('admissionAssignCard.uid', {
							uid: item.data.id,
						})"></h5>
					</div>
					<strong v-if="item.data.product.price">
						<span v-text="item.data.product.price.is_variable ? $options.filters.currency(item.data.product.price.min_price) + ' - ' + $options.filters.currency(item.data.product.price.max_price) : $options.filters.currency(item.data.product.price)"></span>
					</strong>
				</div>
			</v-card-text>
			<v-card-actions v-if="item.data.waiver" class="d-print-none">
				<v-btn color="button" v-model="showPdf" :href="item.data.waiver.signed ? null : item.data.waiver.url" target="_blank" @click="item.data.waiver.signed ? handleShowWaiverClick(item.data.waiver) : null" block>
					<span v-text="item.data.waiver.signed ? $t('cart.reload.assign.signWaiver') : $t('btn.seeWaiverForm')"></span>
				</v-btn>
			</v-card-actions>
		</div>
	</v-card>
</template>

<script>
import {BaseModel, EComService, PaginationModel} from '@connectngo/sdk';
import Modal from '@/components/Modal';

export default {
	name: 'AdmissionAssignCard',
	components: { Modal },
	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		hideTitle: {
			type: Boolean,
			default: false,
		},
		expired: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		loadingWaiver: false,
		showPdf: false,
		waiverPdf: new BaseModel(),
	}),
	computed: {
		_items() {
			return this.value.results.filter(item => item.data.expired === this.expired);
		},
	},
	methods: {
		handleShowWaiverClick(waiver) {
			this.loadingWaiver = waiver.hash;
			return Promise.all([
				new EComService().getWaiverPdf(waiver.hash),
			])
				.then(([waiverPdf]) => {
					Object.assign(this, { waiverPdf, showPdf: true });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loadingWaiver = false));

		},
	},
}
</script>

<style scoped>
.waiver_pdf {
	float: left;
	width: 100%;
	border: 0;
	height: calc(100vh - 122px - 48px - 40px)
}
</style>
