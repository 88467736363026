<template data-cy="template-0">
	<v-autocomplete
		v-model="value"
		prepend-inner-icon="mdi-map-marker"
		autocomplete="cc-country"
		:items="items"
		:rules="[rules.required]"
		:disabled="disabled"
		outlined
	/>
</template>

<script>
import {Rules} from "@connectngo/sdk";
import countries from '@/assets/countries.json';
export default {
	name: "CountrySelector",
	props: {
		guess: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		value: null,
		items: [],
		rules: {},
	}),
	created () {
		this.rules = {
			required: (value) =>
				Rules.required(value) || this.$t("rules.required"),
		}
		const locale = ['en', 'fr'].includes(this.$i18n.locale) ? this.$i18n.locale : 'en'
		this.items = countries[locale]
		if (this.guess !== null) {
			this.items.some((item) => {
				if (item.text.toLowerCase().includes(this.guess.toLowerCase()) ||
					item.value.toLowerCase().includes(this.guess.toLowerCase())) {
					this.value = item.value
					return true;
				}
			})
		}
	},

	watch: {
		value() {
			this.$emit('input', this.value)
		}
	}
}
</script>
