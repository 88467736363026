<template data-cy="template-0">
	<v-app data-cy="v-app-0">
		<v-main class="fill-height" data-cy="v-main-0">
			<v-sheet color="background" class="fill-height d-flex flex-column align-center justify-center text-center" dark data-cy="v-sheet-0">

				<!-- TITLE -->
				<h1 v-text="$t('error.api.title')" data-cy="h1-0"></h1>

				<!-- DESCRIPTION -->
				<p class="mt-4" v-text="$t('error.api.desc')" data-cy="p-0"></p>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'Error',
});
</script>
