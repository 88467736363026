<template data-cy="template-0">
	<div v-bind="$attrs" v-on="$listeners" data-cy="div-0">
		<div v-if="title" class="font-weight-bold" v-text="title" data-cy="div-1"></div>
		<div :key="conditionIdx" v-for="(condition, conditionIdx) in conditions" :class="{
			'mt-1': true,
			'success--text': condition.valid,
			'danger--text': !condition.valid,
		}" data-cy="div-2">
			<v-icon v-if="condition.valid" color="success" left data-cy="v-icon-0">mdi-check</v-icon>
			<v-icon v-else color="error" left data-cy="v-icon-1">mdi-close</v-icon>
			<span v-text="condition.text" data-cy="span-0"></span>
		</div>
	</div>
</template>

<script>
export default {
	name: "StrengthIndicator",

	props: {

		title: {
			type: String,
		},

		conditions: {
			type: Array,
			default: () => ([]),
		},
	},
}
</script>
