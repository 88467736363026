<template>
	<div>
		<AppleWallet
			v-if="showApple"
			:iconHeight="iconHeight"
			@clicked="handleGenerateClicked('APPLE')"
		/>
		<GoogleWallet
			v-if="showGoogle"
			class="ml-lg-2"
			:iconHeight="iconHeight"
			@clicked="handleGenerateClicked('GOOGLE')"
		/>
		<LoadingModal
			:text="modalText"
			:loading="loading"
			@handleCancelClick="loading = false"
		/>
	</div>
</template>

<script>
import GoogleWallet from '@/components/DigitalWallet/GoogleWallet'
import AppleWallet from '@/components/DigitalWallet/AppleWallet'
import LoadingModal from '@/components/Modals/LoadingModal'
import { EComService } from '@connectngo/sdk'
import FileMixin from '@/mixins/FileMixin'

export default {
	name: "DigitalWallet",
	components: { LoadingModal, AppleWallet, GoogleWallet },
	mixins: [FileMixin],
	props : {
		source: {
			type: String,
			required: true
		},
		itemId: {
			required: true,
			type: Number
		},
		iconHeight: {
			type: Number,
			default: 40
		}
	},
	data: () => ({
		loading: false,
		modalText: ''
	}),
	methods : {
		typeUuid(type) {
			if (!this.$root.websiteConfig.data.hasOwnProperty('digital_wallets')) {
				return null
			}
			const dw = this.$root.websiteConfig.data.digital_wallets
			if (!dw.hasOwnProperty(type)) {
				return null
			}

			return dw[type] !== null && dw[type].length > 0 ? dw[type] : null
		},
		handleGenerateClicked(type) {
			this.modalText = this.$t('digitalWallet.generating.' + type.toLowerCase())
			this.generateWallet(this.typeUuid(type))
			this.pushTagManagerEvent(type)
		},
		async generateWallet(configUuid) {
			this.loading = true
			new EComService().generateDigitalWallet(
				this.source,
				configUuid,
				this.itemId,
				this.$i18n.locale
			).then(response => {
				const res = response.data
				if (res.response_type === 'url') {
					window.open(res.data, '_blank');
				} else {
					this.downloadBase64(res.filename, res.response_type, res.data)
				}
			}).catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false))
		},
		async pushTagManagerEvent(type) {
			if (this.$gtm) {
				dataLayer.push({
					event: 'generate_digital_wallet',
					type: type
				});
			}
		}
	},

	computed: {
		showApple() {
			return this.typeUuid('APPLE') !== null
		},
		showGoogle() {
			return this.typeUuid('GOOGLE') !== null
		}
	}
}
</script>
