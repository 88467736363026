<template data-cy="template-0">
	<v-app data-cy="v-app-0">
		<div class="background white--text text-center w-100 fill-height d-flex align-center justify-center flex-nowrap" data-cy="div-0">
			<div style="width: 10rem" data-cy="div-1">
				<v-progress-linear indeterminate color="white" data-cy="v-progress-linear-0"></v-progress-linear>
			</div>
		</div>
	</v-app>
</template>

<script>
export default {
	name: "Loading",
}
</script>
