<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row data-cy="v-row-0">
			<v-col cols="12" lg="6" data-cy="v-col-0">
				<AccountCard v-model="account" :skeleton="skeleton" :route-edit="{ name: 'account_section_sub_action', params: { section: 'connected', sub: $route.params.sub, action: 'edit' } }"  data-cy="AccountCard-0"/>
				<TransactionHistoryListingCard
					class="mt-8"
					v-model="transactionHistory"
					:skeleton="skeleton"
					:route-all="{
						name: 'account_section_sub_action',
						params: {
							section: 'connected',
							sub: $route.params.sub,
							action: 'transactions'
						}
					}"
					:route="item => ({
						name: 'account_section_sub_action_param1',
						params: {
							section: 'connected',
							sub: this.$route.params.sub,
							action: 'transactions',
							param1: item.data.uuid
						}
					})"  data-cy="TransactionHistoryListingCard-0"/>
			</v-col>
			<v-col cols="12" lg="6" data-cy="v-col-1">
				<VirtualWalletListingCard :value="virtualWallets" :skeleton="skeleton" :user="account" :route="item => ({
					name: 'account_section_sub_action_param1',
					params: {
						section: 'connected',
						sub: this.$route.params.sub,
						action: 'wallet',
						param1: item.data.id
					}
				})" :route-all="{ name: 'account_section_sub_action', params: { section: 'connected', sub: $route.params.sub, action: 'wallet' } }" @created="handleVirtualWalletCreated"  data-cy="VirtualWalletListingCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AccountCard from '@/components/AccountCard';
import TransactionHistoryListingCard from '@/components/TransactionHistoryListingCard';
import VirtualWalletListingCard from '@/components/VirtualWalletListingCard';
import { AccountModel, EComService, PaginationModel } from '@connectngo/sdk';

export default {
	name: 'Index',

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_connected') + ' - ' + this.account.data.firstname + ' ' + this.account.data.lastname,
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_connected') + ' - ' + this.account.data.firstname + ' ' + this.account.data.lastname },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:image', content: this.account.data.image },
				{ property: 'twitter:image:src', content: this.account.data.image },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	components: { AccountCard, TransactionHistoryListingCard, VirtualWalletListingCard },

	data: () => ({
		skeleton: true,
		loading: false,
		account: new AccountModel({ firstname: '', lastname: '' }), // Pass blank strings to avoid displaying null in title tag while account loads
		transactionHistory: new PaginationModel(),
		virtualWallets: new PaginationModel(),
	}),

	methods: {
		handleVirtualWalletCreated(wallet) {
			this.$snack(this.$i18n.t('virtualWallet.created'));
			this.virtualWallets.results.unshift(wallet);
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getAccount(this.$route.params.sub),
				new EComService().getTransactionHistory(this.$route.params.sub),
				new EComService().getVirtualWallets(this.$route.params.sub),
			])
				.then(([account, transactionHistory, virtualWallets]) => {
					this.$route.meta.sub = this.$options.filters.shortUserName(account);
					this.$breadcrumbs.refresh();
					Object.assign(this, { account, transactionHistory, virtualWallets });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
