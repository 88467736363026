<template>
	<v-col cols="12" sm="6">
		<v-card class="px-4 py-4">
			<h2 class="mb-6">
				{{ combo?.data?.name }}
			</h2>
			<Calendar
				v-if="showCalendar"
				:productIds="products.map((product) => product.id)"
				:event-group-ids="eventGroupIds"
				:hide-time-slots="false"
				class="mb-4"
				:combo="combo"
				v-model="selectedEvent"
				:unavailableSlots="unavailableSlots"
				@update:current-slots="handleUpdateCurrentSlots"
				@daySelect="daySelected"
				@loading="isLoading => calendarLoading = isLoading"
			>
				<template v-slot:prepend-month>
					<div
						class="section__title mb-6 mt-6"
						v-text="$t('calendar.dateOfVisit')"
					></div>
				</template>
			</Calendar>
			<template>
				<div class="section__title mb-6 mt-6">
					{{
						$t("route.tags.combos.title.choose_your_time_of_visit")
					}}
				</div>
				<div class="d-flex flex-columns expansion-panel">
					<product-timeslot-picker
						v-for="(product, index) in productTimeslots"
						:key="product.id"
						:product="product ? { data: product } : new ProductModel()"
						:time-slots="
							timeSlots.filter(
								(timeSlot) =>
									timeSlot.productId ===
									product?.id
							)
						"
						product-type="tag"
						:selected-day.sync="selectedDate"
						:skeleton="skeleton"
						:event-id.sync="selectedEvent"
						:other-selected-timeslots="otherSelectedTimeslots[product.id]"
						:progress="`${index + 1}/${productTimeslots.length}`"
						@option-selected="
							(timeSlot) => onOptionSelected(timeSlot, product.id, index)
						"
					/>
				</div>
			</template>
			<v-divider class="my-6"></v-divider>
			<v-row>
				<v-col cols="8" class="d-flex align-center">
					<del class="text-red msrp" v-if="msrp">{{ $options.filters.currency(msrp) }}</del>
					<span class="price">{{
						$options.filters.currency(combo?.data?.price)
					}}</span>
				</v-col>
				<v-col cols="4" class="d-flex align-center">
					<InputNumeric
						v-model="quantity"
						:min="0"
						:max="10"
						:increment="1"
						:readonly="false"
						:large="false"
						class="input-numeric"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="8" class="d-flex align-center">
					<h2 class="mb-0">
						{{ $options.filters.currency(combo.data?.price * quantity) }}
					</h2>
				</v-col>
				<v-col cols="4" class="text-end">
					<v-btn
						color="#E37222"
						@click="handleAddToCart"
						:loading="loading"
						:disabled="loading || calendarLoading || !allTimeslotsSelected"
					>
						<v-icon left>mdi-cart-plus</v-icon>
						Add
					</v-btn>
					<small v-if="quantityBoundText">
						{{ quantityBoundText }}
					</small>
				</v-col>
			</v-row>
		</v-card>
	</v-col>
</template>

<script>
import ProductTimeslotPicker from "@/components/ProductTimeslotPicker";
import ComboMixin from "@/mixins/ComboMixin";
import { ProductModel } from "@connectngo/sdk";
import InputNumeric from "@/components/InputNumeric.vue";
import Calendar from "@/components/CalendarCombo.vue";

export default {
	name: "Combo",
	mixins: [ComboMixin],
	props: {
		combo: {
			type: Object,
			default: null,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		showCalendar: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		ProductTimeslotPicker,
		InputNumeric,
		Calendar
	},
	data() {
		return {
			currentSlots: [],
			slots: [],
			eventGroupIds: [],
			unavailableSlots: [],
			availableProducts: [],
			selectedDate: null,
			calendarLoading: false,
			selectedEvent: null,
			otherSelectedTimeslots: {},
		};
	},
	computed: {
		timeSlots() {
			return this.currentSlots.map((slot) => ({
				period: `${slot.from.format("h:mmA")} - ${slot.to.format(
					"h:mmA"
				)}`,
				amountLeft: slot.amountLeft,
				eventGroupId: slot.eventGroupId,
				id: slot.id,
				productId: slot.productId,
			}));
		},
		msrp() {
			return this.combo?.data?.msrp;
		},
		products() {
			if (!this.combo?.data?.combo_products) {
				return [];
			}
			return this.combo.data.combo_products;
		},
		increasePrice() {
			this.price++;
			this.total += this.price;
		},
		productTimeslots() {
			return this.products.filter(product => this.showTimeSlotPicker(product));
		},
		decreasePrice() {
			if (this.price > 0) {
				this.price--;
				this.total -= this.price;
			}
		},
	},
	methods: {
		handleUpdateCurrentSlots(slots) {
			this.currentSlots = slots;
		},
		eventSelected(val) {
			this.selectedEvent = val.slot;
		},
		showTimeSlotPicker(product) {
			return product.id && product.event_group?.id
		},
		daySelected(date) {
			this.selectedDate = date;
			this.availableProducts = Object.assign({}, this.products);
		},
		getOtherSelectedTimeslots(productId) {
			const timeslots = Object.keys(this.selectedTimeslots)
      			.filter(id => Number(id) !== Number(productId) && this.selectedTimeslots[id])
      			.map(id => this.selectedTimeslots[id]);
			return timeslots;
		},
		onOptionSelected(selectedTime, productId, index) {
			if (!selectedTime) {
				this.$delete(this.selectedTimeslots, productId);
			} else {
				this.$set(this.selectedTimeslots, productId, { ...selectedTime, index });
			}
		},
	},
	watch: {
		selectedDate: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					for (let product of this.products) {
						if (product?.id) {
							this.$set(this.selectedTimeslots, product.id, null);
							this.$set(this.otherSelectedTimeslots, product.id, []);
						}
					}
				}
			},
		},
		selectedTimeslots: {
			deep: true,
			handler(newValue) {
				for (let product of this.products) {
					if (product?.id) {
						this.$set(this.otherSelectedTimeslots, product.id, this.getOtherSelectedTimeslots(product.id));
					}
				}
			}
		}
	}
};
</script>

<style scoped lang="scss">
h2 {
	font-weight: 700;
	font-size: 28px;
	color: #009aa6;
	text-transform: capitalize;
}
.section__title {
	font-size: 24px;
}

.expansion-panel {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.msrp, .price {
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
}

.msrp {
	color: #DA291C;
	text-decoration: line-through;
	margin-right: 8px;
}

::v-deep .input-numeric {
	fieldset {
		width: 100%;
		border: 2px solid #009AA6 !important;
		border-radius: 8px;
	}
}

.button-container {
	.v-btn {
		height: 66px;
		padding: 12px 24px 12px 24px;
		border-radius: 8px;
		border: 1px;
		gap: 10px;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		text-align: center;
		&:first-child {
			color: #e37222 !important;
			border: 1px solid #e37222 !important;
		}
		&:last-child {
			color: #fff !important;
			background-color: #e37222 !important;
		}
	}
}

.v-btn {
	min-height: 48px;
	width: 100%;
	padding: 12px 24px 12px 24px;
	border-radius: 8px;
	gap: 10px;
	text-transform: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	.v-icon {
		font-size: 20px;
		margin-right: 12px;
	}
}
</style>
