<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="article,article,article,article"  data-cy="v-skeleton-loader-0"/>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
		<v-card-title :class="{'font-weight-bold': cart.data.type === 'RESERVATION'}" data-cy="v-card-title-0">
			<span v-text="cart.data.type === 'RESERVATION' ? $t('summaryOrderCard.reservation.title') : $t('summaryOrderCard.title')" data-cy="span-0"></span>
		</v-card-title>
		<v-card-text class="text-left" :class="{'pa-0': cart.data.type === 'RESERVATION'}" data-cy="v-card-text-0">

			<!-- ITEMS -->
			<v-alert v-if="cart.data.items.length === 0" type="info" outlined data-cy="v-alert-0">
				<span v-text="$t('summaryOrderCard.noItems')" data-cy="span-1"></span>
			</v-alert>
			<template v-else v-for="item in cart.data.items" data-cy="template-1">
				<v-row :key="item.guid" dense data-cy="v-row-0">
					<v-col cols="12" data-cy="v-col-0">
<!--						<h5 class="overline" v-text="item.data.product.data.name" data-cy="h5-0"></h5> &lt;!&ndash; should be tag name&ndash;&gt;-->
						<h4 :class="{'font-weight-bold black--text text-capitalize d-inline-block': showDepositSection}" v-text="$options.filters.translatable(item.data.product.data.name, item.data.product.data.name_i18n)" data-cy="h4-0"></h4>
						<p v-if="showDepositSection && cart.data.reservation_date" class="d-inline-block font-weight-medium black--text" v-html="reservationDate" data-cy="p-0"></p>
					</v-col>
					<v-col cols="6" data-cy="v-col-1">
						<span v-text="item.data.quantity" data-cy="span-2"></span>
						x <span v-text="$options.filters.currency(item.data.unitPrice)" data-cy="span-3"></span>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right" data-cy="v-col-2">
						<span v-text="$options.filters.currency(item.data.quantity * item.data.unitPrice)" data-cy="span-4"></span>
					</v-col>
				</v-row>
				<v-row :key="modifier.guid" v-for="modifier in item.data.modifiers" dense data-cy="v-row-1">
					<v-col cols="12" data-cy="v-col-3">
<!--						<h5 class="overline" v-text="modifier.data.product.data.name" data-cy="h5-1"></h5> &lt;!&ndash; should be tag name&ndash;&gt;-->
						<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}"  v-text="modifier.data.product.data.name" data-cy="h4-1"></h4>
					</v-col>
					<v-col cols="6" data-cy="v-col-4">
						<span v-text="modifier.data.quantity" data-cy="span-5"></span>
						x <span v-text="$options.filters.currency(modifier.data.unitPrice)" data-cy="span-6"></span>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right" data-cy="v-col-5">
						<span v-text="$options.filters.currency(modifier.data.quantity * modifier.data.unitPrice)" data-cy="span-7"></span>
					</v-col>
				</v-row>
			</template>

			<!-- SUB-TOTAL -->
			<div v-if="!$root.websiteConfig.data.tenant.tax_included">
				<v-divider class="my-4" />
				<v-row dense>
					<v-col cols="6">
						<h4 :class="{'font-weight-bold black--text': showDepositSection}" v-text="$t('summaryOrderCard.subTotal')"></h4>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currency(cart.data.subTotal - cart.data.discountTotal)"></span>
					</v-col>
				</v-row>
			</div>
			<!-- REBATES -->
			<v-divider v-if="Object.keys(cart.getAppliedDiscounts()).length !== 0" class="my-4" />
			<v-row :key="cart.getAppliedDiscounts()[discountKey].guid" v-for="discountKey in Object.keys(cart.getAppliedDiscounts())" dense>
				<v-col cols="8">
					<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}" v-text="$options.filters.translatable(cart.getAppliedDiscounts()[discountKey].name, cart.getAppliedDiscounts()[discountKey].name_i18n, $i18n.locale)"></h4>
					<p class="ma-0">
						<span v-text="$t('rebateCard.applied.code', {
							code: cart.getAppliedDiscounts()[discountKey].code
						})"></span>
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" class="ml-2" color="secondaryDark" icon small @click="handleRebateInput(cart.getAppliedDiscounts()[discountKey])">
									<v-icon small>mdi-trash-can-outline</v-icon>
								</v-btn>
							</template>
							<span v-text="$t('summaryOrderCard.removeRebate')"></span>
						</v-tooltip>
					</p>
				</v-col>
				<v-col cols="4" class="font-weight-bold text-right">
					<span v-text="$options.filters.currency(cart.getAppliedDiscounts()[discountKey].totalApplied)"></span>
				</v-col>
			</v-row>

			<!-- TOTAL BEFORE TAXES -->
			<template v-if="cart.data.taxes.length > 0">
<!--				<v-divider class="my-4" />-->
<!--				<v-row dense>-->
<!--					<v-col cols="6">-->
<!--						<h4 v-text="$t('summaryOrderCard.totalBeforeTaxes')"></h4>-->
<!--					</v-col>-->
<!--					<v-col cols="6" class="font-weight-bold text-right">-->
<!--						<span v-text="$options.filters.currency(cart.data.subTotal)"></span>-->
<!--					</v-col>-->
<!--				</v-row>-->

				<!-- TAXES -->
				<v-divider class="my-4" />
				<v-row :key="tax.guid" v-for="tax in cart.data.taxes" dense>
					<v-col cols="6">
						<h4 class="font-weight-regular">
							<span v-text="tax.data.taxDetail.name"></span>
							(<span v-text="$options.filters.taxes(tax.data.taxDetail.rate)"></span>)
						</h4>
						<p class="ma-0" v-text="tax.data.taxDetail.number"></p>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currency(tax.data.total)"></span>
					</v-col>
				</v-row>
				<!-- TAX TOTAL -->
<!--				<div v-if="$root.websiteConfig.data.tenant.tax_included">-->
<!--					<v-divider class="my-4" />-->
<!--					<v-row dense>-->
<!--						<v-col cols="6">-->
<!--							<h4 v-text="$t('summaryOrderCard.totalTax')"></h4>-->
<!--						</v-col>-->
<!--						<v-col cols="6" class="font-weight-bold text-right">-->
<!--							<span v-text="$options.filters.currency(totalTax)"></span>-->
<!--						</v-col>-->
<!--					</v-row>-->
<!--				</div>-->
			</template>

			<!-- TOTAL -->
			<v-divider class="my-4" />
			<v-row dense>
				<v-col cols="6">
					<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}" v-text="$t('summaryOrderCard.cartTotal')"></h4>
				</v-col>
				<v-col cols="6" class="font-weight-bold text-right">
					<span v-text="$options.filters.currency(cart.data.total)"></span>
				</v-col>
				<v-btn v-if="(showContinueButton)"
					class="mt-6" color="button" block :loading="skeleton" :disabled="skeleton" @click="load">
						<span v-text="$t('btn.continue')"></span>
				</v-btn>
			</v-row>
		</v-card-text>
		<!-- PAYMENTS -->
		<div v-if="showDepositSection && payments.data.payments.length > 0" class="pt-16 mx-0 mb-11 top-border">
			<v-card-title class="font-weight-bold" v-text="$t('reservation.payment_title')"></v-card-title>
			<v-card-text class="pa-0">
				<v-row :key="payment.data.id" v-for="payment in payments.data.payments" class="black--text subtitle-1 open-sans-font">
					<v-col cols="6" class="text-left">{{ $options.filters.timestampToDateOnly(payment.data.created_at) }} <span v-if="payment.data.payment_method">({{$options.filters.translatable(payment.data.payment_method.name, payment.data.paymentMethod.name_i18n, $i18n.locale)}})</span></v-col>
					<v-col cols="6" class="text-right" v-text="$options.filters.currency(payment.data.amount)"></v-col>
				</v-row>
				<v-row class="black--text font-weight-bold subtitle-1 open-sans-font bottom-border top-border">
					<v-col cols="6" class="text-left"  v-text="cart.data.status ==='CANCELLED' && $t('reservation.amount_refunded') || $t('summaryOrderCard.balance')"></v-col>
					<v-col cols="6" class="text-right" v-text="cart.data.status ==='CANCELLED' && $options.filters.currency(refundedAmount) || $options.filters.currency(cart.data.balance)"></v-col>
				</v-row>
			</v-card-text>
		</div>

	</v-card>
</template>

<script>
import { CartModel, TransactionModel, EComService, EventBus, PaymentModel } from '@connectngo/sdk';

export default {
	name: 'SummaryOrderCard',

	props: {
		cart: {
			type: [CartModel,TransactionModel],
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		load: {
			type: Function,
			default: () => {}
		},
		showContinueButton: {
			type: Boolean,
			default: false
		},
		showDepositSection: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		loading: false,
	}),

	methods: {
		handleRebateInput( rebate) {
			this.loading = true;
			new EComService().removeRebate(rebate.id)
				.then(cart => {
					EventBus.publish('CART_UPDATED', cart);
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));

		},
	},
	computed: {
		totalTax() {
			return this.cart.data.taxes.reduce( function(totalTax, tax){
					return totalTax + tax.data.total;
				}, 0);
		},

		reservationDate() {
			return `&nbsp&nbsp(${this.$options.filters.tsToFormat(this.cart.data.reservation_date, this.$t('date.format.ymd'))})`;
		},

		refundedAmount() {
			const refundedPayments =  this.payments.data.payments.filter(paymentsData => paymentsData.data.type == 'DEPOSIT_REFUND')?.map(payments => payments.data.amount);
			if(refundedPayments.length > 0) {
				const result = refundedPayments.reduce((a, b) => {
					return a + b;
				});
				return -result;
			}

			return 0;
		}

	},
}
</script>

<style lang="scss" scoped>
.top-border {
	border-top: 1px solid #E0E0E0;
}

.bottom-border {
	border-bottom: 1px solid #E0E0E0;
}
</style>
