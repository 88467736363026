<template data-cy="template-0">
  <v-container data-cy="v-container-0">
		<v-row data-cy="v-row-0">
			<v-col cols="12" data-cy="v-col-0">
				<template data-cy="template-1">
					<div class="text-center" data-cy="div-0">
						<v-dialog
							v-model="cartConfirm"
							width="360"
						 	data-cy="v-dialog-0">
							<v-card data-cy="v-card-0">
								<v-card-title class="text-h2 text-center" data-cy="v-card-title-0">
									<v-icon color="green" data-cy="v-icon-0">mdi-check-circle</v-icon>
								</v-card-title>

								<v-card-text class="text-center" data-cy="v-card-text-0">
									<v-container data-cy="v-container-1">
										<v-row data-cy="v-row-1">
											<v-col cols="12" data-cy="v-col-1">
												<h2 v-text="$t('account.reschedule.finalizeText')" data-cy="h2-0"></h2>
											</v-col>
										</v-row>
										<v-row data-cy="v-row-2">
											<v-col cols="12" data-cy="v-col-2">
												<p v-text="$t('account.reschedule.rescheduledTo', {
													event: selectedGroup ? selectedGroup.event.event_template.name : '',
													date: selectedDate,
												})" data-cy="p-0"></p>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>

								<v-divider data-cy="v-divider-0"></v-divider>

								<v-card-actions class="text-center d-flex flex-column" style="gap:0.5rem" data-cy="v-card-actions-0">
									<v-btn
										v-if="changesPending"
										color="primary"
										outlined
										large
										text
										@click="switchModal"
										block
									 	data-cy="v-btn-0"
									>
										<span v-text="$t('account.reschedule.continueChanges')" data-cy="span-0"></span>
									</v-btn>
									<v-spacer data-cy="v-spacer-0"></v-spacer>
									<v-btn
										color="primary"
										outlined
										large
										@click="finalizeTransaction"
										:loading="savingInvoice"
										block
									 	data-cy="v-btn-1"
									>
										<span v-text="$t('account.reschedule.finalizeButton')" data-cy="span-1"></span>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</template>
				<Modal
					v-model="changeDateModal.visible"
					footerClass="pa-8"
					headerClass="px-8 pt-6 pb-4 modal-header"
					:title="$t('account.reschedule.changeDate')"
					:subtitle="$t('account.reschedule.updateDateOfVisit')"
					max-width="1090"
					width="1090"
					scrollable
					class="dialog"
					id="dialog"
					@close="onCloseModal"
				>
					<template #body>
						<v-card-text class="modal-content px-8">
							<v-container>
								<v-row>
										<v-col cols="6" class="left-column">
											<div class="card">
												<v-row class="d-flex justify-space-between ma-0">
													<div class="green--text">
    													<v-icon :color="$vuetify.theme.themes.light.success">mdi-circle-medium</v-icon>
    													<span class="black--text text--small" v-text="$t('account.reschedule.howTo.green')"></span>
  													</div>
  													<div class="orange--text">
    													<v-icon :color="$vuetify.theme.themes.light.warning">mdi-circle-medium</v-icon>
    													<span class="black--text text--small" v-text="$t('account.reschedule.howTo.orange')"></span>
													</div>
													<div>
														<v-icon :color="$vuetify.theme.themes.light.systemDark1">mdi-circle-medium</v-icon>
														<span class="black--text text--small" v-text="$t('account.reschedule.howTo.black', { hours: reschedule_restrict_hour })"></span>
													</div>
												</v-row>
												<v-row class="mt-0">
													<v-col cols="12" class="pa-0">
														<v-date-picker
															:allowed-dates="allowedDates"
															color="primary"
															header-color="secondaryDark"
															v-model="selectedDate"
															@input="onDayChange"
															@update:picker-date="onMonthChange"
															:min="new Date().toISOString().substr(0, 10)"
															:events="functionEvents"
															:disabled="loading || loadingColors.length !== 0"
															full-width
															no-title
														>
															<div class="w-100">
																<v-expand-transition>
																	<v-progress-linear v-if="loadingColors.length !== 0" indeterminate class="mb-2"></v-progress-linear>
																</v-expand-transition>
															</div>
														</v-date-picker>
													</v-col>
												</v-row>
											</div>
											<span class="calendar__footer pl-6">
  												*Cannot be exchanged within 48h of original visiting date
											</span>
										</v-col>
									<v-col cols="5" class="right-column">
										<template v-for="(item, index) in selectedGroup?.items" v-if="showTimeSlotPicker(item.product)">
											<product-timeslot-picker
												:product="{ data: item.product }"
												:time-slots="timeSlots.filter(slot => slot?.item_id === item?.id) || []"
												product-type="tag"
												:otherSelectedTimeslots="otherSelectedTimeslots[item.product.id]"
												:hide-header="true"
												:show-title="false"
												:skeleton="skeleton"
												:selected-day.sync="selectedDate"
												:selected-options.sync="selectedTimeslots"
												@option-selected="(ts) => onSelectTimeSlotClick(ts, item.product, index)"
												:selected-index="index"
												:progress="`${index + 1}/${productTimeslotsCount}`"
											/>
											<v-divider v-if="index < productTimeslotsCount -1" class="mx-6"></v-divider>
										</template>
									</v-col>
									<v-col cols="12" md="7" v-if="selectedSlot.id && (selectedSlot.price_delta > 0 || hasModifiers)">
										<v-card outlined class="full-height" :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 24px' : null">
											<v-card-title class="pb-4">
												<i18n path="account.reschedule.priceDiffAvail" tag="span">
													<span class="error--text">*</span>
												</i18n>
											</v-card-title>
											<v-card-text>
												<v-card :key="productIdx" v-for="(item, productIdx) in selectedGroup.items" outlined :class="{
													'mt-3': productIdx > 0,
												}">
													<div :class="{
														'd-flex align-center': true,
													}">
														<v-img :src="item.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
														<v-card-text class="py-2">
															<h4 class="mb-0" v-text="$options.filters.translatable(item.product.name, item.product.name_i18n)"></h4>
															<p class="caption opacity-50 mb-2">{{ selectedSlot.period }}</p>

															<i18n path="account.reschedule.priceDiff" tag="h5">
																<strong :class="{
																	'primary--text': getProductPrice(item) > 0,
																	'error--text': getProductPrice(item) < 0,
																}">
																	<span>
																		{{ getProductPrice(item) > 0 ? '+' : '' }}
																		<!-- <span v-text="$options.filters.currency(product.data.min_price)"></span> -->
																		<span>{{ $options.filters.currency(priceDelta[item.product.id]) }}</span>
																	</span>
																</strong>
															</i18n>

															<i18n path="account.reschedule.newDate" tag="h5">
																<span class="font-weight-regular">{{ selectedSlot.day }} {{ selectedSlot.period }}</span>
															</i18n>

															<h5>
																<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
																&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
																	price: $options.filters.currency(calcUpdatedPrice(item))
																})"></span>
															</h5>
														</v-card-text>
													</div>

											<v-card :key="modifierIdx" v-for="(modifier, modifierIdx) in item.timeBasedModifiers" outlined :class="{
													'mt-3': modifierIdx > 0,
												}">
													<div :class="{
														'd-flex align-center': true,
													}">
														<v-img :src="modifier.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
														<v-card-text class="py-2">
															<h4 class="mb-0" v-text="$options.filters.translatable(modifier.product.name, modifier.product.name_i18n)"></h4>
															<p class="caption opacity-50 mb-2"></p>

															<i18n path="account.reschedule.priceDiff" tag="h5">
																<strong :class="{
																	'primary--text': getProductPrice(modifier) > 0,
																	'error--text': getProductPrice(modifier) < 0,
																}">
																	<span>
																		{{ getProductPrice(modifier) > 0 ? '+' : '' }}
																		<span>{{ $options.filters.currency(modifierDelta[productIdx][modifierIdx]) }}</span>
																	</span>
																</strong>
															</i18n>
															<h5>
																<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
																&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
																	price: $options.filters.currency(getProductPrice(modifier) + (modifierDelta[productIdx][modifierIdx] || 0))
																})"></span>
															</h5>
													<v-divider />
													<v-card-text class="pt-2" style="background-color: #f6f6f6">
														<i18n path="account.reschedule.chooseNewTime" tag="h4" class="mb-2">
															<span class="error--text">*</span>
														</i18n>
														<v-select
															v-model="modifier.period"
															:items="getModifierSlots(modifier)"
															@change="handleModifierChange($event, modifier, productIdx, modifierIdx)"
															:label="$t('account.reschedule.makeSelection')"
															background-color="white"
															dense
															outlined
															hide-details
														/>
														</v-card-text>
													</v-card-text>
													</div>
												</v-card>
												</v-card>
											</v-card-text>
											<v-card-actions>
												<div class="w-100">
													<i18n path="account.reschedule.totalPriceDiff" tag="span" class="title">
														<strong v-text="$options.filters.currency(totalDiff)"></strong>
													</i18n>
													<br>
													<i18n path="account.reschedule.lowerPriceNotRefund" tag="span" class="opacity-50">
														<span class="error--text">*</span>
													</i18n>
												</div>
											</v-card-actions>
										</v-card>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</template>

					<template #buttons>
						<div class="btn-container d-flex flex-row justify-end">
							<v-btn class="btn__cancel" outlined large @click="onCancelClick">
								<span v-text="$t('btn.cancel')"></span>
							</v-btn>
							<v-btn class="btn__confirm" large :disabled="canConfirm" :loading="applyingChanges" @click="updateSchedule">
								<span v-text="$t('btn.confirmChanges')"></span>
							</v-btn>
						</div>
					</template>
				</Modal>

				<h1 class="title" v-text="$t('account.reschedule.howTo.title')"></h1>
				<ul class="mt-4">
					<li v-text="$t('account.reschedule.howTo.point1')"></li>
					<li v-text="$t('account.reschedule.howTo.point2')"></li>
				</ul>
				<h4 class="red--text text-center mt-10" v-if="_sortedTransactions.length <= 0" v-text="$t('account.reschedule.noTransactionsAvailable')"></h4>
				<v-expansion-panels v-model="transactionPanel" class="mt-8" multiple>
					<v-expansion-panel class="px-0" :key="transactionIdx" v-for="(transaction, transactionIdx) in _sortedTransactions">
						<v-expansion-panel-header>
							<TransactionCard
								:value="transaction"
								:skeleton="skeleton"
								flat
								hide-details
								:to="null"
								:qr-code-attrs="{
									size: 96
								}"
							/>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-expansion-panels v-model="panel[transactionIdx]" multiple>
								<v-expansion-panel class="px-0" :key="groupIdx" v-for="(group, groupIdx) in transaction.data.groups">
									<v-expansion-panel-header>
										<div class="d-flex align-center mr-4 column-mobile">
											<v-icon x-large>mdi-calendar</v-icon>
											<div class="ml-3" style="flex: 1">
												<h3 class="title" v-text="group.event.event_template.name"></h3>
												<i18n path="account.reschedule.visitDate" tag="p" class="mb-0">
													<strong v-text="eventDate(group.event.start_datetime)"></strong>
													<strong v-text="eventTime(group.event.start_datetime, group.event.end_datetime)"></strong>
												</i18n>
												<div v-if="pending" class="d-flex align-center mt-2">
													<v-icon color="success">mdi-check-circle-outline</v-icon>
													<div class="mx-2 mb-0" v-text="$t('account.reschedule.changePending')"></div>
													(<TimestampCountdown v-model="pendingTime"></TimestampCountdown>)
												</div>
											</div>
											<v-btn color="button" @click.stop="onChangeDateClick(transaction, group, groupIdx)">
												<span v-text="$t('btn.changeDate')"></span>
											</v-btn>
										</div>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-row>
											<v-col :key="item.product.id + '-' + itemIdx" v-for="(item, itemIdx) in group.items" cols="12" lg="6" xl="4">
												<ProductWaiverCard :value="formatProduct(item)" :skeleton="skeleton" flat tile />
											</v-col>
										</v-row>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment';
import Calendar from '@/components/CalendarCombo.vue';
import TransactionCard from '@/components/TransactionCard';
import ProductWaiverCard from '@/components/ProductWaiverCard';
import TimestampCountdown from '@/components/TimestampCountdown';
import ProductTimeslotPicker from '@/components/ProductTimeslotPicker';
import Modal from '@/components/Modal';
import CalendarSlot from '@/components/CalendarSlot';
import {BaseModel, PaginationModel, ProductModel, EComService, EventBus} from '@connectngo/sdk';
import globalVariables from "@/global";

export default {
name: 'RescheduleExperimental',

	components: { TransactionCard, ProductWaiverCard, TimestampCountdown, Modal, CalendarSlot, ProductTimeslotPicker, Calendar },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_reschedule'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_reschedule') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

    mounted() {
		this.arrayEvents = [...Array(6)].map(() => {
			const day = Math.floor(Math.random() * 30)
			const d = new Date()
			d.setDate(day)
			return d.toISOString().substr(0, 10)
		})
    },

	data: () => ({
		now: new Date().getTime(),
		loading: false,
		loadingColors: [],
		otherSelectedTimeslots: {},
		applyingChanges: false,
		transactions: new PaginationModel([{}, {}, {}]),
		transactionDetailIds: [],
		selectedTransaction: new BaseModel(),
		selectedGroup: null,
		productSlots: [],
		productIds: [],
		eventSlots: [],
		mappedData: [],
		priceDelta: {},
		dayClosed: false,
		calendarDataForCurrentMonth: {},
		selectedTimeslots: {},
		isSlotSelected: false,
		selectedDate: null,
		selectedSlot: {},
		comboSlots: [],
		selectedModifiers: [],
		allModifiersSelected: false,
		modifierDelta: [],
		products: new PaginationModel([], ProductModel),
		skeleton: true,
		transactionPanel: [],
		panel: {},
		changeDateModal: {
			visible: false,
		},
		eventGroupIds: [],
		transactionProducts: [],
		cartConfirm: false,
		totalDiff: 0,
		savingInvoice: false,
		dateChangeCompleted: {},
		pending: false,
		pendingTime: 0,
		hasModifiers: false,
		changesPending: true,
		groupIndex: 0,
		reschedule_restrict_hour: 0,
		reschedule_allow_hour: 0
	}),

	computed: {
		_sortedTransactions() {
			return this.transactions.results ? this.transactions.results?.reverse() : [];
		},
		canApplyChanges() {
			return !(this.loading || this.applyingChanges || !this.selectedDate || !this.isSlotSelected) ? true : false;
		},
		hasPriceChangeDifference() {
			return Object.values(this.selectedTimeslots).some(slot => slot?.price_delta > 0);
		},
		productTimeslotsCount() {
			return this.transactionProducts.filter(product => this.showTimeSlotPicker(product)).length;
		},
		canConfirm() {
			return !this.canApplyChanges || !this.allModifiersSelected || this.allTimeslotsSelected;
		},
		allTimeslotsSelected() {
    		const productsWithEventGroupId = this.products.filter(product => product.data.event_group?.id);
    		return this.selectedTimeslots && Object.values(this.selectedTimeslots).filter(item => item && item.id).length === productsWithEventGroupId.length;
  		},
		// This function should only be used to display mock timeslots for testing layout.
		calanderCardWidth() {
			return !(Object.values(this.selectedTimeslots).some(slot => slot?.id && (slot?.price_delta > 0 || this?.hasModifiers))) && '500' || 'auto';
		},
		showAvailability() {
			return !!parseInt(globalVariables.websiteConfig.data.fields.show_tickets_availabilities) ?? false;
		},
		slotsForSelectDisplay() {
			if (!this.currentSlots || !this.currentSlots.items) return [];
			return this.currentSlots.items.map(item => {
				let label = item.period;

				if(this.showAvailability) {
					label += ` (${this.$tc('calendar.places', item.amountLeft, {
						amount: item.amountLeft
					})})`;
				}

				return {
					value : item,
					disabled : item.amountLeft <= 0,
					text : label,
				}
			})
		},
		currentSlots() {
			return this.mappedData?.find(d => d.day === this.selectedDate);
		},
		timeSlots() {
			if (!this.currentSlots || !this.currentSlots.items) return [];
			return this.currentSlots.items.filter(item => item.amountLeft > 0).map(item => ({
				period: item.period,
				amountLeft: item.amountLeft,
				id: item.id,
				item_id: item.item_id
			}));
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.mdAndUp;
		},
	},
	methods: {
		calculateTotal() {
			if (!this.isSlotSelected) return 0;

			let diff = 0;

			this.selectedGroup.items.forEach((item, itemIdx) => {
				diff += this.priceDelta[item.product.id];

				item.timeBasedModifiers.forEach((modifier, modifierIdx) => {
					if (this.priceDelta[modifier.product.id] && this.selectedModifiers[itemIdx][modifierIdx]) {
						diff += this.priceDelta[modifier.product.id];
					}
				})

			});
			this.totalDiff = diff;
		},
		getProductPrice(item) {
			if (this.selectedGroup !== null) {
				for (let i in this.selectedGroup.items) {
					let currentItem = this.selectedGroup.items[i];

					if (currentItem.id === item.id && currentItem.amount) {
						return currentItem.amount;
					}
				}
			}

			let product = item.product

			if (product.price) {
				return product.price.min_price;
			}

			return 0;
		},
		functionEvents(date) {
			const todayDate = moment().format('YYYY-MM-DD');
			const currentTimeStamp = moment().unix();
			// Display the event color based on the comboSlots.
			if (moment(date).isBefore(todayDate) || !this.productSlots.length) {
				return;
			}
			const currSlots = this.comboSlots.find(d => d.data.day === date);
			if (!currSlots) {
				return;
			}

			if (todayDate === date) {
				const lastEventStartTime = currSlots.data?.events?.[currSlots.data.events?.length - 1]?.start_ts;
				if (!moment(currentTimeStamp).isBefore(lastEventStartTime)) {
					return;
				}
			}

			if (currSlots.data?.events?.find(e => e.price_delta > 0)) return 'orange';

			return currSlots.data.color;
		},
		showTimeSlotPicker(product) {
			return this.selectedGroup?.items && product?.id && product?.event_group?.id;
		},
		onDayChange(date) {
			this.isSlotSelected = false;
			this.selectedDate = date;

			if (!this.currentSlots.items.length) {
				this.dayClosed = true;
			} else {
				this.dayClosed = false;
			}
		},
		onMonthChange(date) {
			this.resetData();
			this.calendarDataForCurrentMonth = {};
			this.allModifiersSelected = false;

			this.getReschedulableEvents(date);
			this.getComboAvailabilities(date);
		},
		handleSelectDate(date) {
			let selectDate = moment.unix(date).format('YYYY-MM-DD');
			if (this.reschedule_allow_hour && this.reschedule_allow_hour != 0) {
				let currentAvailableDate = moment().add(this.reschedule_allow_hour + 24, 'hours').format('YYYY-MM-DD');
				if (moment(selectDate).isBefore(currentAvailableDate)) {
					return currentAvailableDate;
				}
			}
			return selectDate;
		},
		onChangeDateClick(transaction, group, groupIdx) {
			this.loadingColors.push(true);
			this.changeDateModal.visible = true;
			this.selectedTransaction = transaction;
			this.transactionProducts = [];
			this.selectedGroup = group;
			this.groupIndex = groupIdx;
			this.calendarDataForCurrentMonth = {};
			this.selectedDate = this.handleSelectDate(group.event.start_datetime);
			this.transactionDetailIds = [];
			this.productSlots = [];
			this.productIds = [];
			this.mappedData = [];
			this.priceDelta = {};
			this.isSlotSelected = false;
			this.selectedModifiers = [];
			this.eventGroupIds = [];
			this.allModifiersSelected = false;
			this.modifierDelta = [];
			this.totalDiff = 0;
			this.hasModifiers = false;
			this.dateChangeCompleted[this.selectedTransaction.data.id] ??= [];

			this.selectedGroup.items.forEach((item, index) => {
				if (this.productIds.indexOf(item.product.id) === -1) {
					this.productIds.push(item.product.id)
					this.priceDelta[item.product.id] = 0;
				}

				this.transactionProducts.push(item.product);

				if (this.transactionDetailIds.indexOf(item.id) === -1) {
					this.transactionDetailIds.push(item.id);
				}

				if (this.eventGroupIds.indexOf(item.product.event_group.id) === -1) {
					this.eventGroupIds.push(item.product.event_group.id);
				}

				this.modifierDelta[index] = [];
				this.selectedModifiers[index] = [];

				item.timeBasedModifiers.forEach((modifier, modIdx) => {
					if (this.transactionDetailIds.indexOf(modifier.id) === -1) {
						this.transactionDetailIds.push(modifier.id);
					}

					if (this.productIds.indexOf(modifier.product.id) === -1) {
						this.productIds.push(modifier.product.id);
						this.priceDelta[modifier.product.id] = 0;
					}

					if (this.eventGroupIds.indexOf(modifier.product.event_group.id) === -1) {
						this.eventGroupIds.push(modifier.product.event_group.id);
					}

					this.selectedModifiers[index][modIdx] = false;
					this.modifierDelta[index][modIdx] = 0;
				});
			});

			this.getReschedulableEvents(this.selectedDate);
			this.getComboAvailabilities(this.selectedDate);
			this.loadingColors.pop();
		},
		getOtherSelectedTimeslots(productId) {
			const timeslots = Object.keys(this.selectedTimeslots)
      			.filter(id => Number(id) !== Number(productId) && this.selectedTimeslots[id])
      			.map(id => this.selectedTimeslots[id]);
			return timeslots;
		},
		onCancelClick() {
			this.changeDateModal.visible = false;
			this.resetData();
		},
		onCloseModal() {
			this.resetData();
		},
		resetData() {
			this.selectedDate = null;
			this.selectedTimeslots = {};
			this.otherSelectedTimeslots = {};
			this.mappedData = [];
		},
		onSelectTimeSlotClick(slot, product, index) {
			this.isSlotSelected = true;
			if (!slot) {
				this.$delete(this.selectedTimeslots, product.id);
			} else {
				this.$set(this.selectedTimeslots, product.id, { ...slot, index });
			}
			let modsOn = true;
			let hasMods = false;

			this.selectedGroup.items.forEach(item => {
				if (item.timeBasedModifiers.length > 0) {
					modsOn = false;
					hasMods = true;
				}
			});

			this.allModifiersSelected = modsOn;
			this.hasModifiers = hasMods;

			if (!slot?.id) return;

			return new EComService().getProductPrices(slot?.id, this.productIds)
				.then(response => {
					const deltas = {};
					this.selectedGroup.items.forEach(item => {
						if (response.data[item.product.id]) {
							deltas[item.product.id] = response.data[item.product.id].min_price - this.getProductPrice(item);
						}

						item.timeBasedModifiers.forEach(modifier => {
							deltas[modifier.product.id] = response.data[modifier.product.id].min_price - this.getProductPrice(modifier);
						})
					});

					this.priceDelta = deltas;
					this.calculateTotal();
				})
				.finally(() => this.loading = false);
		},
		allowedDates(val) {
			return val >= moment().format('YYYY-MM-DD') && this.functionEvents(val) && this.functionEvents(val) !== 'red';
		},
		eventDate(eventDate) {
			return moment.unix(eventDate).format('DD-MM-YYYY');
		},
		eventTime(startTime, endTime) {
			let eventDate = '';
			const format = 'HH:mm';
			const multiplyBy = 1000;

			eventDate += this.$options.filters.tsToFormat(startTime * multiplyBy, format, true);

			if (endTime) {
				eventDate += ' - ' + this.$options.filters.tsToFormat(endTime * multiplyBy, format, true);
			}

			return eventDate;
		},
		formatProduct(item) {
			return new BaseModel(item);
		},
		handleModifierChange(event, modifier, itemIdx, modifierIdx) {
			this.selectedModifierSlot = this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id])
				.find(d => d.date === this.selectedDate).items
				.find(item => item.period === event)

			return new EComService().getProductPrices(this.selectedModifierSlot.id, this.productIds)
				.then(response => {
					const modDelta = this.modifierDelta.slice(0);
					const selectedMods = this.selectedModifiers.slice(0);
					let allSelected = true;

					modDelta[itemIdx][modifierIdx] = response.data[modifier.product.id].min_price - this.getProductPrice(modifier);
					selectedMods[itemIdx][modifierIdx] = this.selectedModifierSlot.id;

					this.selectedModifiers.forEach((i, ix) => {
						this.selectedModifiers[ix].forEach((m, mx) => {
							if (!this.selectedModifiers[ix][mx]) {
								allSelected = false;
								return;
							}
						});
					});

					this.allModifiersSelected = allSelected;
					this.modifierDelta = modDelta;
					this.selectedModifiers = selectedMods;
					this.calculateTotal();
				})
				.finally(() => this.loading = false);
		},
		getModifierSlots(modifier) {
			if (!this.calendarDataForCurrentMonth[modifier.product.event_group.id] || !this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate)) {
				return [];
			}

			return this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate).items.map(i => i.period);
		},
		updateSchedule(){
			this.applyingChanges = true;
			const addItems = [];

			// Iterate over items in the group
			this.selectedGroup.items.forEach((item, itmIdx) => {
				const modifiers = [];
				item.timeBasedModifiers.forEach((modifier, modifierIdx) => {
					modifiers.push({
						transactionDetailId: modifier.id,
						productId: modifier.product.id,
						eventId: this.selectedModifiers[itmIdx][modifierIdx],
					})
				});

				addItems.push({
					transactionDetailId: item.id,
					productId: item.product.id,
					eventId: this.selectedTimeslots[item.product.id]?.id ?? null,
					modifiers: modifiers,
				});
			});

			this.dateChangeCompleted[this.selectedTransaction.data.id].push(this.groupIndex);

			if (this.dateChangeCompleted[this.selectedTransaction.data.id].length === this.selectedTransaction.data.groups.length) {
				this.changesPending = false;
			}

			this.pendingTime = new Date().getTime() + 60000 * 30;

			return new EComService().addReschedulableItems(addItems)
				.then((model) => {
					this.pending = true;
					globalVariables.cart = model;
					EventBus.publish('CART_UPDATED', model);
					this.changeDateModal.visible = false;
					this.cartConfirm = true;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => {
					this.applyingChanges = false;
				});
		},
		finalizeTransaction() {
			if (this.$root.cart.data.subTotal > 0) {
				this.$router.push({ name: 'cart' });
			} else {
				this.saveInvoice();
			}
		},
		saveInvoice() {
			this.savingInvoice = true;
			return new EComService().patchCart({'account_id': this.$root.user.data.id})
				.then(() => {
					return new EComService().saveInvoice(this.$root.cart.data.id)
						.then(invoice => {
							//this.triggerGAEvent(invoice);
							const createCartCb = () => {
								return new EComService().createCart().then(cart => {
									this.$root.cart = cart;
									EventBus.publish('CART_UPDATED', cart);
									const nextStep = this.$root.websiteConfig.data.fields.active_reload === "1" ? 'reload' : 'confirmation';
									this.$router.push({ name: 'invoice_step', params: { uuid: invoice.data.invoice.uuid, step: nextStep } });
								});
							}
							this.cartConfirm = false;
							return invoice.data.need_fulfillment
								? new EComService().autoFulfillInvoice(invoice.data.invoice.uuid).then(createCartCb)
								: createCartCb();
						})
				})
				.catch(error => this.$handleError(this, error))
				.finally(() => this.savingInvoice = false);
		},
		async getReschedulableEvents(date) {
  			this.loadingColors.push(true);
  			const from = moment(date).startOf('month').startOf('day').unix();
  			const to = moment(date).endOf('month').endOf('day').unix();

  			const promises = this.transactionDetailIds.map(id =>
    			new EComService().getReschedulableEvents(
      				[id],
      				from,
      				to,
    			)
    			.then((data) => {
      				// Include itemId in each event
      				data.results.forEach(item => {
        				item.data?.events?.forEach(event => {
          					event.item_id = id;
        				});
      				});
      				return data.results;
    			})
  			);
  			try {
    			const allResults = await Promise.all(promises);
    			// Flatten the results array
    			this.productSlots = allResults.flat();
  			} finally {
    			this.loadingColors.pop();
  			}
		},
		getComboAvailabilities(date) {
			// Only for mocking purpose, will remove them later after comboId for Reaschedule is ready.
			const createMockData = (combo) => {
				return {
					...combo,
					data: {
						...combo.data,
						closed: false,
						color: 'green',
						available: 10,
						events: [],
					}
				}
			}
  			const from = moment(date).startOf('month').startOf('day').unix();
  			const to = moment(date).endOf('month').endOf('day').unix();
			const comboId = this.selectedGroup.items[0].comboId;
			this.loadingColors.push(true);
			return new EComService().getComboAvailabilities(
				comboId,
				from,
				to,
			)
      			.then(data => {
					this.comboSlots = data;
      			})
      			.finally(() => {
					this.loadingColors.pop();
      			});
		},
		getMappedData(productSlots = [], comboSlots = []) {
  			return comboSlots.map(comboSlot => {
    			const productSlotsPerDay = productSlots.filter(slot => slot?.data?.day === comboSlot.data.day);

    			return {
      				day: comboSlot.data.day,
      				state: comboSlot.data.closed ? 'closed' : 'open',
      				color: comboSlot.data.color,
      				items: productSlotsPerDay.flatMap(productSlot =>
						productSlot.data.events.map(event => ({
        					id: event.id,
        					day: comboSlot.data.day,
        					period: event.period,
        					amountLeft: event.available || 0,
        					totalAmount: event.available || 0,
        					price_delta: event.price_delta || 0,
							item_id: event.item_id
						})
					))
    			};
  			});
		},
		switchModal() {
			this.cartConfirm = false;
			this.changeDateModal.visible = false;
			for (let i = 0; i < this.selectedTransaction.data.groups.length; i++) {
				if (!this.dateChangeCompleted[this.selectedTransaction.data.id].includes(i)) {
					setTimeout(this.onChangeDateClick(this.selectedTransaction, this.selectedTransaction.data.groups[i], i), 1000); // Smoothen transition
					return;
				}
			}
		},
		load() {
			this.loading = true;

			return new EComService().getReschedulableInvoices()
				.then((transactions) => {
					Object.assign(this, { transactions });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => {
					this.loading = false;
					this.skeleton = false;
				});
		},
		calcUpdatedPrice(item) {
			return parseFloat(this.getProductPrice(item)) + (this.priceDelta[item.product.id] || 0);
		}
	},
	created() {
		this.reschedule_restrict_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_restrict_hour);
		this.reschedule_allow_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_allow_hour);
		this.$route.meta.section = this.$i18n.t('route.account_reschedule');
		this.load();
	},
	mounted() {
		this.timeSlotEventBus = EventBus.subscribe(
			"timeSlotPickedForGroup" + this.eventGroupId,
			this.eventSelected
		);
	},
	watch: {
		productSlots: {
			handler(slots) {
				this.mappedData = this.getMappedData(slots, this.comboSlots);
			},
			deep: true,
		},
		comboSlots: {
			handler(slots) {
				this.mappedData = this.getMappedData(this.productSlots, slots);
			},
			deep: true,
		},
		selectedDate: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					for (let product of this.transactionProducts) {
						if (product?.id) {
							this.$set(this.selectedTimeslots, product.id, null);
							this.$set(this.otherSelectedTimeslots, product.id, []);
						}
					}
				}
			},
		},
		selectedTimeslots: {
			deep: true,
			handler(newValue) {
				for (let product of this.transactionProducts) {
					if (product?.id) {
						this.$set(this.otherSelectedTimeslots, product.id, this.getOtherSelectedTimeslots(product.id));
					}
				}
			}
		},
		changeDateModal: {
			deep: true,
			handler(newValue) {
				if (newValue.visible) {
					this.getComboAvailabilities(this.selectedDate);
					this.getReschedulableEvents(this.selectedDate);
				}
			}
		}
	},
}
</script>

<style lang="scss">
#dialog .modal-header {
	.headline {
		font-size: 27px;
		font-weight: 600;
		line-height: 36px;
		text-align: left;
	}
	.subtitle {
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		text-align: left;
	}
}

</style>

<style scoped lang="scss">
.card {
	border-radius: 16px 16px 0px 0px;
	box-shadow: 0px 4px 8px 0px #6864641A;
}

.text--small {
	font-size: 11px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.1599999964237213px;
	text-align: left;
}

.left-column {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.left-column i {
	width: 30px;
	height: 30px;
	font-size: 50px;
}

.calendar__footer {
	font-size: 11px;
	font-weight: 600;
	line-height: 17px;
	text-align: left;
	color: #EF8686;
}

.right-column {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.container .row {
	gap: 24px;
}

.v-date-picker-table {
	min-height: 250px;
}

ul {
	display: flex;
	flex-direction: column;
	gap: 8px;
	li {
		list-style: none;
		.v-icon {
			font-size: 50px;
			width: 15px;
			height: 15px;
			margin-right: 12px;
		}
	}
}

::v-deep .calendar .calendar__btn {
	min-height: 50px !important;
	&--inner {
		gap: 4px;
	}
	.title {
		line-height: initial;
		font-size: 16px !important;
	}
}

.calander-center {
    margin: auto;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ul-list-padding {
	padding-left: 8px !important;
}

.v-card__text > .title {
	font-size: 16px !important;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0px;
}

.btn-container {
	width: 100%;
	gap: 16px;
	.v-btn {
		max-width: 235px;
		width: 100%;
		max-height: 40px;
		padding: 12px 24px 12px 24px;
		border-radius: 8px;
		line-height: 23px;
		text-align: center;
		text-transform: none;
		font-size: 18px;
		font-weight: 600;
		text-align: left;
	}
	.btn__confirm {
		color: #fff;
		padding: 8px 16px 8px 16px;
		border-radius: 4px;
		background-color: #009AA6;
	}

	.btn__cancel {
		border: 1px solid #828282;
		color: #828282;
	}
}

@media all and (max-width: 767px) {
	.column-mobile {
		flex-wrap: wrap;
		justify-content: center;
	}
	.column-mobile button{
		margin-top: 20px
	}
}



</style>
