<template>
	<v-form :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="create_account_form" v-model="formIsValid" @submit="validate" lazy-validation>

		<v-text-field
			data-testid="first_name"
			v-model="data.first_name"
			:label="$t('input.first_name')"
			:error-messages="formErrors.first_name"
			:rules="[rules.required]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-text-field
			data-testid="last_name"
			v-model="data.last_name"
			:label="$t('input.last_name')"
			:error-messages="formErrors.last_name"
			:rules="[rules.required]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-text-field
			data-testid="email_id"
			v-model="data.email"
			:label="$t('input.email')"
			:error-messages="formErrors.email"
			:rules="[rules.required, rules.email]"
			:disabled="registerAsGuest"
			outlined
			background-color="white"
			@input="formErrors = {}"
			data-cy="v-text-field-2"
		></v-text-field>

		<CustomField
			v-for="field in customFields.results"
			:value="data.fields[field.key]"
			v-model="data.fields[field.data.name]"
			:key="field.data.id"
			:field="field"
			:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
			:error-messages="formErrors[field.data.name]"
			:required="field.data.required"
			:rules="rules"
			class="pt-0 mt-0"
			outlined
			inset
			@input="formErrors = {}"
			data-cy="CustomField-0"/>

		<v-text-field
			data-testid="password"
			v-model="data.password"
			:label="$t('input.password')"
			:error-messages="formErrors.password"
			:rules="[rules.required, rules.min, rules.isValidPassword]"
			:type="showPassword ? 'text' : 'password'"
			:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
			autocomplete="password"
			outlined
			background-color="white"
			@click:append="showPassword = !showPassword"
			@input="formErrors = {}"
			data-cy="v-text-field-3">
		</v-text-field>

		<v-text-field
			data-testid="confirm_password"
			v-model="data.confirmation"
			:label="$t('input.confirmation')"
			:error-messages="formErrors.confirmation"
			:rules="[rules.required, rules.min, rules.password]"
			:type="showPassword ? 'text' : 'password'"
			:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
			autocomplete="confirmation"
			outlined
			background-color="white"
			@click:append="showPassword = !showPassword"
			@input="formErrors = {}"
			data-cy="v-text-field-4"></v-text-field>

		<StrengthIndicator data-testid="pwd_strength" class="my-4" :title="$t('createAccountForm.passwordMustContain')" :conditions="passwordConditions" data-cy="StrengthIndicator-0"></StrengthIndicator>

		<v-checkbox data-testid="rcv_promotion" :label="optInMessage" v-model="data.promotion" class="mt-0"  data-cy="v-checkbox-0"/>
		<v-text v-if="getTermsText" v-html="getTermsText"  data-cy="v-checkbox-0"></v-text>
		<v-btn class="mt-4" type="submit" color="button" data-test-selector="create_account_form_sign_int" :disabled="loading" :loading="loading" block data-cy="v-btn-0" @click="triggerGAEvent()">
			<span v-text="$t('createAccountForm.createBtn')" data-cy="span-0"></span>
		</v-btn>
	</v-form>
</template>

<script>
import StrengthIndicator from '@/components/StrengthIndicator';
import PasswordConditions from "@/mixins/PasswordConditions";
import CustomField from '@/components/CustomField';
import {EComService, PaginationModel, Rules} from '@connectngo/sdk';
import globalVariables from '@/global';
import ConfigFieldsMixin from "@/mixins/ConfigFieldsMixin";

export default {
	name: "CreateAccountForm",

	components: { StrengthIndicator, CustomField },

	mixins: [ PasswordConditions(), ConfigFieldsMixin ],

	emit: ['registerAsGuest'],

	data() {
		return {
			loading: false,
			formIsValid: false,
			formErrors: {},
			customFields: new PaginationModel(),
			data: {
				first_name: '',
				last_name: '',
				email: '',
				phone_number: '',
				password: '',
				confirmation: '',
				promotion: false,
				fields: {},
			},
			registerAsGuest: false,
			showPassword: false,
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
				password: () => Rules.identical(this.data.password, this.data.confirmation) || this.$t('rules.identical'),
				isValidPassword: () => this.passwordIsValid || this.$t('rules.passwordInvalid'),
			},
		};
	},
	computed: {
		getTermsText() {
			if (globalVariables.websiteConfig.data.fields['terms_and_conditions_text']) {
				return JSON.parse(globalVariables.websiteConfig.data.fields['terms_and_conditions_text'])?.[this.lang];
			}
			return null;
		},
		lang() {
			return this.$route.params?.lang || 'en';
		},
		optInMessage() {
			const customText = this.getTranslatedField('opt-in-message', this.$i18n.locale);
			return customText
				? customText.replace( /(<([^>]+)>)/ig, '')
				: this.$i18n.t('createAccountForm.receivePromotion');
		},
	},

	methods: {
		triggerGAEvent() {
			if (this.$gtm) {
				dataLayer.push({
					event: "guestcheckout_acc_creation_btn",
				});
			}
		},
		handleSubmitSuccess (account) {
			new EComService().patchAccount(account.data.id, {
				fields: this.data.fields
			}).then((account) => {
				let data;
				if(this.$route.params.resId) {
					data = {
						name : 'reservation',
						params : {
							lang: this.$route.params?.lang,
							resId : this.$route.params?.resId
						}
					};
				}
				Object.assign(this.$root.user.data = account.data);
				this.$emit('completed',data);
				this.loading = false

				/* Google Tag Manager */
				if (this.$gtm) {
					dataLayer.push({
						event: 'sign_up',
						method: 'ConnectnGo'
					});
				}
			})
			.catch(reason => this.$handleError(this, reason))
			.finally(() => (this.loading = false))
		},

		validate (event) {

			event.preventDefault();
			this.formErrors = {};

			if (this.$refs.form.validate()) {
				this.loading = true;
				const guestId = this.$route.params?.guest_id;
				const hash = this.$route.params?.hash;
				if (guestId) {
					new EComService().completeAsGuest(
						guestId,
						hash,
						this.data.email,
						this.data.password,
						this.$i18n.locale,
						this.data.first_name,
						this.data.last_name,
						this.data.phone_number,
						this.data.promotion
					).then((account) => {
						// Login after completing as guest successfully.
						return new EComService().login(
							this.data.email,
							this.data.password,
							this.$i18n.locale
					)})
					.then((account) => {
						this.handleSubmitSuccess(account);
					})
					.catch(error => this.$handleError(this, error, this.formErrors))
					.finally(() => this.loading = false);
					return;
				}
				new EComService().register(
					this.data.email,
					this.data.password,
					this.$i18n.locale,
					this.data.first_name,
					this.data.last_name,
					this.data.phone_number,
					this.data.promotion,
				)
					.then(account => {
						new EComService().patchCart({ account_id: account.data.id })
							.then((data) => {
								this.$root.cart = data;
							})
							.catch((e) => {
								this.$handleError(this, e);
							});
						this.handleSubmitSuccess(account);
					})
					.catch(error => {
						if (error.reason && error.reason === 'guest_account_exists_with_same_email') {
							this.$modal.show(
								this.$i18n.t('alert.forgotPasswordEmailSent'),
								error.errors.email,
								[
									{
										text: this.$i18n.t("btn.back"),
										attrs: {text: true},
										events: {
											click: () => {
												this.$modal.hide();
												this.$router.push({ name: 'home' });
											},
										},
									},
								],
								{ maxWidth: 400, persistent: true }
							);
							return ;
						}
						return this.$handleError(this, error, this.formErrors);
					})
					.finally(() => this.loading = false);
			}
		},

		load() {
			this.loading = true;
			new EComService().getCustomFields()
				.then(customFields => {
					Object.assign(this, { customFields });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
			// When the user visits the page via email as guest, the email field will be pre-filled and disabled.
			const params = this.$route.params;
			const guestEmail = params?.email;
			if (Rules.email(guestEmail)) {
				this.data.email = guestEmail;
			}
		},
	},

	created() {
		this.load();
	},
}
</script>
