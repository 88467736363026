<template data-cy="template-0">
	<v-banner v-model="visible" class="text-center text-md-left" v-bind="$attrs" v-on="$listeners" data-cy="v-banner-0">
		<i18n :path="hasLink ? 'cookieBanner.messageLink' : 'cookieBanner.message'" tag="label" for="tos" data-cy="i18n-0">
			<a data-test-selector="cookie_banner_link" :class="{
				'white--text': $attrs.dark,
				'black--text': !$attrs.dark,
				'text-decoration-underline': true,
			}" v-text="$t('cookieBanner.policy').toLowerCase()" @click="handleLinkClick" data-cy="a-0"></a>
		</i18n>

		<template v-slot:actions data-cy="template-1">
			<v-btn data-test-selector="cookie_banner_accept" :block="$vuetify.breakpoint.smAndDown" :dark="!$attrs.dark" :light="$attrs.dark" @click="handleAcknowledgeClick()" data-cy="v-btn-0">
				<span v-text="$t('cookieBanner.accept')" data-cy="span-0"></span>
			</v-btn>
		</template>
	</v-banner>
</template>

<script>
import { EventBus } from '@connectngo/sdk';

export default {
	name: "CookieBanner",

	data: () => ({
		visible: localStorage.getItem('cookieAcknowledged') === null,
	}),

	computed: {
		hasLink() {
			return this.$root.websiteConfig.data.menus.primary.find(link => link.type === 'privacy');
		}
	},

	methods: {
		handleAcknowledgeClick() {
			localStorage.setItem('cookieAcknowledged', 'true');
			this.visible = false;
		},
		handleLinkClick() {
			EventBus.publish('OPEN_PAGE', 'privacy');
		},
	},
}
</script>
