<template data-cy="template-0">
	<Page no-top-page data-cy="Page-0">
		<template #sidebar data-cy="template-1">
			<div class="pr-6 pl-4" data-cy="div-0">
				<Picture v-model="sharing.data.account.data.image" max-width="8rem" max-height="8rem" :aspect-ratio="1" :skeleton="skeleton" contain  data-cy="Picture-0"/>
				<v-skeleton-loader v-if="skeleton" type="card-heading" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
				<h3 v-else class="mt-4" v-text="completeName" data-cy="h3-0"></h3>
			</div>
		</template>

		<v-skeleton-loader v-if="skeleton" type="card-heading,list-item,image"></v-skeleton-loader>
		<v-card v-else>
			<v-card-title class="display-1 text-left" v-text="title"></v-card-title>
			<v-card-text>
				<p class="mt-n4" v-text="$t('socialSharing.' + type + 'TakenOn', {
					date: $options.filters.tsToFormat(sharing.data.created_at * 1000, 'YYYY-MM-DD HH:mm:ss')
				})"></p>

				<video v-if="type === 'video'" :src="sharing.data.url" class="w-100" controls></video>
				<img v-else-if="type === 'image'" :src="sharing.data.url" class="w-100" :alt="title" />
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" block to="/" :disabled="loading" x-large>
					<v-icon left>mdi-cart</v-icon>
					<span v-text="$t('socialSharing.visitStore')"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import Picture from '@/components/Picture';
import { EComService, SocialSharingModel } from '@connectngo/sdk';

export default {
	name: 'SocialSharing',

	components: { Page, Picture },

	metaInfo() {
		return {
			title: this.$i18n.t(this.title),
			meta: [
				{ property: 'og:title', content: this.title },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:image', content: this.sharing.data.url },
			],
		};
	},

	data: () => ({
		loading: false,
		skeleton: true,
		sharing: new SocialSharingModel(),
	}),

	computed: {
		title() {
			return this.$i18n.t('socialSharing.title', {
				name: this.sharing.data.account.data.firstname,
				type: this.$i18n.t('socialSharing.type.' + this.type),
			})
		},
		type() {
			return this.sharing.data.type.indexOf('video') !== -1
				? 'video'
				: 'image';
		},
		completeName() {
			return (this.sharing.data.account.data.firstname + ' ' + this.sharing.data.account.data.lastname).trim();
		},
	},

	methods: {
		load() {
			this.loading = true;
			return new EComService().getSocialSharing(this.$route.params.hash)
				.then(model => {
					this.sharing = model;
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
