<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item,list-item,list-item,list-item,list-item,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
		<v-card-title class="pb-0" style="justify-content: start !important" data-cy="v-card-title-0">
			<span v-text="$t('transactionHistoryListingCard.title')" data-cy="span-0"></span>
		</v-card-title>
		<v-card-text v-if="value.results.length === 0" class="mt-4" data-cy="v-card-text-0">
			<v-alert text prominent type="info" data-cy="v-alert-0">
				<span v-text="$t('transactionHistoryListingCard.empty')" data-cy="span-1"></span>
			</v-alert>
		</v-card-text>
		<v-list v-else data-cy="v-list-0">
			<v-list-item :key="itemIdx" v-for="(item, itemIdx) in value.results" :to="route(item)" data-cy="v-list-item-0">
				<v-list-item-icon data-cy="v-list-item-icon-0">
					<v-icon color="secondaryDark" x-large data-cy="v-icon-0">mdi-file-percent-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content data-cy="v-list-item-content-0">
					<div class="caption text--disabled" v-text="$options.filters.tsToFormat(item.data.created_at, $t('date.format.ymd'))" data-cy="div-0"></div>
					<h4 class="secondaryDark--text" v-text="$t('transactionHistoryListingCard.number', {
						number: item.data.transaction_id.toUpperCase(),
					})" data-cy="h4-0"></h4>
				</v-list-item-content>
				<v-list-item-action class="text-right" data-cy="v-list-item-action-0">
					<span v-text="$options.filters.currency(item.data.total)" data-cy="span-2"></span>
				</v-list-item-action>
			</v-list-item>

			<v-list-item class="justify-end" data-cy="v-list-item-4">
				<v-list-item-action-text data-cy="v-list-item-action-text-0">
					<router-link class="text-decoration-none d-flex align-center" :to="routeAll" @click.stop data-cy="router-link-0">
						<span v-text="$t('transactionHistoryListingCard.sellAll')" data-cy="span-3"></span>
						<v-icon right x-small data-cy="v-icon-1">mdi-arrow-right</v-icon>
					</router-link>
				</v-list-item-action-text>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'TransactionHistoryListingCard',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		route: {
			type: Function,
			default: (item) => ({
				name: 'account_section_sub',
				params: {
					section: 'transactions',
					sub: item.data.uuid
				}
			}),
		},
		routeAll: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'transactions' } }),
		},
	},
}
</script>
