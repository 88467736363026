<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-form v-else id="virtualWalletDetailsForm" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation
			@submit="handleSubmitForm" data-cy="v-form-0">
		<v-card v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
			<v-card-text data-cy="v-card-text-0">

				<v-expand-transition data-cy="v-expand-transition-0">
					<v-alert v-if="saved" type="success" prominent dismissible data-cy="v-alert-0">
						<span v-text="$t('alert.infoUpdated')" data-cy="span-0"></span>
					</v-alert>
				</v-expand-transition>

				<div class="d-flex justify-center" data-cy="div-0">
					<QrCode v-if="value.data.wallet_type.issuing === 'INTERNAL_BARCODE'" v-model="value.data.uid.uid"
							size="128" data-cy="QrCode-0"/>
					<v-avatar v-else size="128" color="secondaryLight" data-cy="v-avatar-0">
						<v-img :src="$root.websiteConfig.data.images['wallet-uid-image']" size="128" contain data-cy="v-img-0"/>
					</v-avatar>
				</div>

				<div class="d-flex" data-cy="div-1">
					<v-icon style="flex: 0" class="mr-3" data-cy="v-icon-0">mdi-pound</v-icon>
					<div class="py-3" data-cy="div-2">
						<h5 class="caption text-uppercase text--disabled"
							v-text="$options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale)" data-cy="h5-0"></h5>
						<strong v-text="$t('account.wallet.uid', {
							uid: value.getUidLabel()
						})" data-cy="strong-0"></strong>
					</div>
				</div>

				<div v-if="value.data.wallet_type.data.issuing === 'INTERNAL_BARCODE'" class="d-flex justify-center" data-cy="div-0">
					<DigitalWallet
						:source="'wallet'"
						:itemId="value.data.id"
						:iconHeight="40"
						class="mt-4 text-center"
					/>
				</div>

				<CreditCardDialog
					:title="$t('creditCardDialog.title' + (creditCardDialog.card === null ? 'New' : 'Edit'))"
					:visible.sync="creditCardDialog.visible"
					:manage-token="token && token.data.id !== null"
					:max-width="400"
					:wallet-id.sync="value.data.id"
					:token.sync="token"
					:credit-card-title="creditCardTitle"
					:credit-card-image="creditCardImage"
					:expiry-date="token ? token.data.data.expiryDate : ''"
					scrollable
					@on-token-change="handleToken"
				 data-cy="CreditCardDialog-0"/>

				<CreditCardPreview
					v-if="token && token.data.id !== null"
					:type="token.data.card_type"
					:credit-card-title="creditCardTitle"
					:credit-card-image="creditCardImage"
					:expiry-date="token ? token.data.data.expiryDate : ''"
					:skeleton="skeleton"
				 data-cy="CreditCardPreview-0"/>

				<v-btn v-if="token && token.data.id !== null" color="secondaryDark" class="mt-4" outlined block large
					   @click="handleManageCreditCardClick" data-cy="v-btn-0">
					<v-icon left data-cy="v-icon-1">mdi-credit-card-plus-outline</v-icon>
					<span v-text="$t('account.wallet.manageCreditCard')" data-cy="span-1"></span>
				</v-btn>


				<v-btn v-else-if="$root.websiteConfig.data.tokenization_module" color="secondaryDark" class="mt-4" outlined block large
					   @click="handleEditCreditCardClick" data-cy="v-btn-1">
					<v-icon left data-cy="v-icon-2">mdi-credit-card-plus-outline</v-icon>
					<span v-text="$t('account.wallet.addCreditCard')" data-cy="span-2"></span>
				</v-btn>

				<template v-if="cashless.results.length > 0" data-cy="template-1">
					<v-divider class="my-4" data-cy="v-divider-0"/>

					<div data-cy="div-3">
						<h5 class="text-uppercase font-weight-regular" v-text="$t('account.wallet.balanceMedia', {
							media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale)
						})" data-cy="h5-1"></h5>
						<h2 v-text="$t('virtualWalletDetailsCard.balance', {
							balance: cashless.results[0].data.balance,
							type: $options.filters.translatable(cashless.results[0].data.cashless_type.name, cashless.results[0].data.cashless_type.name_i18n, $i18n.locale),
						})" data-cy="h2-0"></h2>
					</div>
				</template>

			</v-card-text>
		</v-card>
	</v-form>
</template>

<script>
import QrCode from '@/components/QrCode'
import CreditCardDialog from '@/components/CreditCardDialog'
import CreditCardPreview from '@/components/CreditCardPreview'
import { WalletModel, Rules, PaginationModel, EComService } from '@connectngo/sdk'
import mastercard from '@/assets/images/mastercard.svg'
import visa from '@/assets/images/visa.svg'
import amex from '@/assets/images/amex.svg'
import jcb from '@/assets/images/jcb.svg'
import discover from '@/assets/images/discover.svg'
import dinersclub from '@/assets/images/dinersclub.svg'
import DigitalWallet from '@/components/DigitalWallet/DigitalWallet'

export default {
	name: 'VirtualWalletDetailsCard',

	components: { QrCode, CreditCardDialog, CreditCardPreview, DigitalWallet },

	props: {
		value: {
			type: WalletModel,
			default: () => new WalletModel(),
		},
		cashless: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		saved: false,
		formIsValid: true,
		formErrors: {},
		data: {
			first_name: '',
		},
		creditCardDialog: {
			visible: false,
		},
		walletId: null,
		token: {
			data: {
				id: null,
				card_type: null,
				data: {
					cardSummary: ''
				}
			}
		},
	}),

	computed: {

		creditCardTitle () {
			return (this.translateType(this.token.data.id != null ? this.token.data.card_type : '') + ' **** **** **** ' + this.token.data.data.cardSummary).trim()
		},

		creditCardImage () {
			switch (this.token.data.id != null ? this.token.data.card_type.toLowerCase() : '') {
				case 'master_card':
					return mastercard
				case 'visa':
					return visa
				case 'amex':
					return amex
				case 'jcb':
					return jcb
				case 'discover':
					return discover
				case 'dinersclub':
					return dinersclub
			}
			return null
		},

		canSave () {
			return !this.loading && this.formIsValid && !this.skeleton
		},

		canSubmit () {
			return !this.loading && !this.skeleton
		},

		rules () {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
			}
		},
	},

	methods: {
		translateType (type) {
			switch (type.toLowerCase()) {
				case 'master_card':
					return 'mastercard'
				case 'visa':
					return 'visa'
				case 'amex':
					return 'amex'
				case 'jcb':
					return 'jcb'
				case 'discover':
					return 'discover'
				case 'dinersclub':
					return 'dinersclub'
			}
			return null
		},

		getWalletToken () {
			this.loading = true
			return new EComService().getWalletToken(this.value.data.id).then(res => {
					this.token = res
				}
			).catch(reason => this.$handleError(this, reason)
			).finally(() => (this.loading = false))
		},

		handleEditCreditCardClick () {
			Object.assign(this.creditCardDialog, {
				visible: true,
			})
		},

		handleManageCreditCardClick () {
			Object.assign(this.creditCardDialog, {
				visible: true,
			})
		},

		handleInput () {
			this.formErrors = {}
		},

		handleToken () {
			if (this.value.data.id !== null) {
				this.getWalletToken()
			}
		},

		handleSubmitForm () {
			if (this.$refs.form.validate()) {
				this.loading = true
				return Promise.all([
					new EComService().updateWalletNickname(),
				])
					.then(([wallet]) => {
						this.saved = true
						this.loading = false
						this.$vuetify.goTo('#virtualWalletDetailsForm', {
							offset: this.$vuetify.application.top,
						})
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.loading = false))
			}
		},
	},

	watch: {
		value: {
			deep: true,
			handler () {
				if (this.value.data.id !== null) {
					this.getWalletToken()
				}
			},
		},
	},
}
</script>
