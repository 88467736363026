<template data-cy="template-0">
	<v-sheet
		data-testid="cart"
		color="transparent"
		v-bind="$attrs"
		v-on="$listeners"
		data-cy="v-sheet-0"
	>
		<v-alert
			data-testid="emptyCartAlert"
			v-if="!skeleton && _items.length === 0"
			type="info"
			class="mb-0"
			prominent
			text
			data-cy="v-alert-0"
		>
			<span v-text="$t('cart.welcome.noProducts')"></span>
		</v-alert>
		<v-row v-else data-cy="v-row-0">
			<v-col cols="12" md="6" data-cy="v-col-0">
				<ComboNotification
					v-if="ffCombo && combos.length > 0"
					:combos.sync="combos"
					:height="isDesktopBreakpoint ? 60 : 48"
					:border-radius="8"
					class="mb-4"
					textDecorationUnderline
					:textH5="isDesktopBreakpoint"
					:textH6="!isDesktopBreakpoint"
					fontWeightBold
					data-cy="ComboNotification-0"
				/>
				<template data-cy="template-1">
					<Product
						v-for="item in _items"
						:key="item.item.guid"
						:product="item.product"
						:unitPrice="item.unitPrice"
						:cart-item="item.item"
						:skeleton="skeleton"
						class="pa-1 rounded-1"
						in-cart
						dense
						data-cy="Product-0"
					/>
				</template>
			</v-col>
			<v-col cols="12" md="6">
				<RebateCard :cart.sync="cart" :skeleton="skeleton" />
				<SummaryOrderCard
					:cart.sync="cart"
					:payments.sync="payments"
					:skeleton="skeleton"
					class="mt-8"
				/>
			</v-col>
		</v-row>

		<template>
			<CrossSellModal
				v-if="isHardCrossSell"
				:visible.sync="crossSellModal.visible"
				:products="_crossSellProducts"
				:events="eventsForCrossSells"
				:fullscreen="$vuetify.breakpoint.smAndDown"
				scrollable
				max-width="1000"
				@continue="handleContinue"
			/>
			<div v-else class="mt-16">
				<h2
					class="display-1 font-weight-light text-center"
					v-text="$t('tag.otherOptions')"
					v-if="cart.data.items.length !== 0"
				></h2>

				<v-row class="mt-6">
					<v-col
						cols="12"
						md="6"
						:key="product.guid"
						v-for="product in _crossSellProducts.results"
						style="min-width: 20rem"
					>
						<Product
							:product="product"
							:skeleton="skeleton"
							:is-cross-sell="true"
							:event-id.sync="eventsForCrossSells[product.guid]"
						/>
					</v-col>
				</v-row>
			</div>
		</template>

		<div class="mt-8"></div>

		<div
			:class="[
				'sticky-container rounded py-5 px-5 mx-0',
				$root.cart.data.itemCount ? 'active' : '',
			]"
		>
			<div class="button-container">
				<v-btn
					color="primary"
					large
					outlined
					:to="{ name: 'home' }"
					exact
				>
					<v-icon left>mdi-cart-outline</v-icon>
					<span v-text="$t('cart.welcome.continueShop')"></span>
				</v-btn>
				<v-btn
					data-testid="checkoutBtn"
					color="primary"
					large
					:disabled="!canGoNext"
					@click="handleNextClick"
					class="ml-5"
				>
					<v-icon left>mdi-credit-card-outline</v-icon>
					<span v-text="$t('btn.checkout')"></span>
				</v-btn>
			</div>
		</div>

		<InteractiveMapCrossSellModal
			v-if="hasInteractiveMap"
			:next-action="handleContinue"
			:visible="showInteractiveMapModal"
			@modalClosed="showInteractiveMapModal = false"
		/>
	</v-sheet>
</template>

<script>
import Product from "@/components/Product";
import RebateCard from "@/components/RebateCard";
import SummaryOrderCard from "@/components/SummaryOrderCard";
import CrossSellModal from "@/components/CrossSellModal";
import {
	PaginationModel,
	CartModel,
	ProductModel,
	EComService,
	PaymentModel,
	EventBus,
} from "@connectngo/sdk";
import ComboNotification from "@/components/ComboNotification";
import globalVariables from "@/global";
import InteractiveMapMixin from "@/mixins/InteractiveMapMixin";
import InteractiveMapCrossSellModal from "@/components/InteractiveMap/InteractiveMapCrossSellModal";

export default {
	name: "Welcome",

	components: {
		InteractiveMapCrossSellModal,
		Product,
		RebateCard,
		SummaryOrderCard,
		CrossSellModal,
		ComboNotification,
	},

	mixins: [InteractiveMapMixin],

	props: {
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		skeleton: true,
		loading: true,
		crossSellProducts: new PaginationModel(),
		eventsForCrossSells: {},
		crossSellModal: {
			visible: false,
		},
		combos: [],
		comboUpdatedEventBus: null,
		ffCombo: false,
		showInteractiveMapModal: false,
	}),

	computed: {
		_cart: {
			get() {
				return this.cart;
			},
			set(value) {
				this.$emit("update:cart", value);
			},
		},
		isHardCrossSell() {
			return this.$root.websiteConfig.data.fields.hard_cross_sell === "1";
		},
		_crossSellProducts() {
			return this.skeleton
				? new PaginationModel([{}, {}, {}], ProductModel)
				: this.crossSellProducts;
		},
		_items() {
			return this._cart.data.items.map((item) => {
				if (item !== null) {
					const productModel =
						this.products.get(item.data.product.data.id) ||
						item.data.product;
					const unitPrice = item.data.unitPrice;
					productModel.data.product_group = item.data.productGroup;
					productModel.data.event_period = item.data.eventPeriod;
					return {
						item,
						product: productModel,
						unitPrice: unitPrice,
					};
				} else if (
					item.data.product != null &&
					+!!item.data.product.data.is_combo
				) {
					return {
						item,
						product: item.data.product,
						unitPrice: item.data.unitPrice,
					};
				} else {
					return { item, product: new ProductModel() };
				}
			});
		},
		canGoNext() {
			return this.cart.data.items.length > 0 && !this.loading;
		},
		products() {
			const map = new Map();
			(this.$root.tags || new PaginationModel()).results
				.map((tag) => tag.data.products)
				.flat()
				.forEach((product) => {
					map.set(product.data.id, product);
				});
			return map;
		},
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		},
	},

	methods: {
		buildGAItems() {
			const items = [];
			this.cart.data.items.forEach((item, index) => {
				items.push({
					item_id: item.data.product.data.id,
					item_name: this.$options.filters.translatable(
						item.data.product.data.name,
						item.data.product.data.name_i18n,
						this.$i18n.locale
					),
					coupon: item.data.hasDiscount
						? this.$options.filters.translatable(
								item.data.discount.name,
								item.data.discount.name_i18n,
								this.$i18n.locale
						  )
						: null,
					currency:
						this.$root.websiteConfig.data.tenant.currency.code,
					discount: item.data.discountTotal,
					index: index++,
					item_category: item.data.mainTag
						? this.$options.filters.translatable(
								item.data.mainTag.name,
								item.data.mainTag.name_i18n,
								this.$i18n.locale
						  )
						: "",
					price: item.data.total,
					quantity: item.data.quantity,
				});
			});
		},

		/* Google Tag Manager */
		triggerCartGAEvent() {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "view_cart",
					ecommerce: {
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						value: this.cart.data.total,
						items: this.buildGAItems(),
					},
				});
			}
		},

		/* Google Tag Manager */
		triggerCheckoutGAEvent() {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "begin_checkout",
					ecommerce: {
						items: this.buildGAItems(),
					},
				});
			}
		},

		handleContinue() {
			this.continue(true);
		},
		handleNextClick() {
			this.continue();
		},
		continue(force = false) {
			if (
				this.isHardCrossSell &&
				this._crossSellProducts.results.length > 0 &&
				!force
			) {
				Object.assign(this.crossSellModal, {
					visible: true,
				});
			} else if (
				!this.isHardCrossSell &&
				this.mustDisplayInteractiveMapCrossSellModal &&
				!force
			) {
				this.showInteractiveMapModal = true;
				globalVariables.interactiveMapModalShown = true;
			} else {
				this.triggerCheckoutGAEvent();
				this.$emit("next");
			}
		},
		load() {
			this.loading = true;
			return Promise.all([new EComService().getCartCrossSell()])
				.then(([crossSellProducts]) => {
					this.crossSellProducts = crossSellProducts;
					this.eventsForCrossSells = crossSellProducts.results.reduce(
						(a, product) => ({ ...a, [product.guid]: null }),
						{}
					);
					this.skeleton = false;
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},
	created() {
		this.combos = this.$root.combos;
		this.ffCombo =
			globalVariables.websiteConfig.featureFlagEnabled("combo-v2");
		this.triggerCartGAEvent();
		this.load();
	},
	mounted() {
		if (this.ffCombo) {
			let ref = this;
			this.comboUpdatedEventBus = EventBus.subscribe(
				"COMBO_UPDATED",
				function (combos) {
					ref.combos = combos;
				}
			);
		}
	},
	destroyed() {
		if (this.ffCombo) {
			this.comboUpdatedEventBus.unsubscribe();
		}
	},
};
</script>

<style>
.sticky-container {
	position: relative;
	background-color: #fff;
}

.sticky-container.active {
	position: sticky;
	position: -webkit-sticky;
	bottom: 0%;
	z-index: 2;
}

.sticky-container .button-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media all and (max-width: 620px) {
	.sticky-container .button-container button,
	.sticky-container .button-container a {
		font-size: 0.7rem;
		padding: 0 10px !important;
	}
}

@media all and (max-width: 430px) {
	.sticky-container .button-container {
		display: grid;
	}

	.sticky-container .button-container button {
		margin-left: 0 !important;
		margin-top: 10px;
	}
}
</style>
