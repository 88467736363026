import Vue from 'vue'
import globalVariables from './global'
import Loading from './routes/Loading.vue'
import App from './routes/App.vue'
import { routes as appRoutes } from './routes'
import ErrorPage from './routes/Error.vue'
import Confirm from './utils/Confirm'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify/lib/framework'
import VueTheMast from 'vue-the-mask'
import VueGooglePlaces from 'vue-google-places'
import messages, { rightToLeft } from './locales';
import Error from '@/utils/Error';
import CookieConsent from '@/plugins/cookie-consent';
import languageMapping from '@/locales/language-mapping';
import 'open-sans-fonts/open-sans.scss'
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss'
import '@mdi/font/scss/materialdesignicons.scss'
import './filters'
import './directives'
import '@connectngo/vue-components/global.scss'
import './styles/index.scss'
import packageJson from '../package.json'
import moment from "moment"
import '@adyen/adyen-web/dist/adyen.css'
import VueMeta from 'vue-meta'
import { EComService, Server, EventBus, Cookies, Storage, AccountModel } from '@connectngo/sdk'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

Vue.use(Vuetify)
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(Error)
Vue.use(VueTheMast)
Vue.use(Confirm)
Vue.use(VueGooglePlaces)
Vue.use(VueMeta)

Vue.config.productionTip = false

const gitVersion = localStorage.getItem('git_version');
if (!gitVersion) {
	localStorage.setItem('git_version', packageJson.version);
} else if (gitVersion !== packageJson.version) {
	localStorage.setItem('git_version', packageJson.version);
	window.location.href = window.location.href + '?v=' + packageJson.version;
}

let lang = Cookies.get('lang') ?? navigator.language.toLowerCase();

const i18n = new VueI18n({
	locale: lang,
	messages,
	fallbackLocale: 'en'
});
Server.setLocale(lang);

let vm;
let vuetify;
let router: VueRouter;
let component: any = App;
let routes = appRoutes;
const vuetifyOpts = {
	theme: {
		themes: {
			light: {
				success: '',
			},
			dark: {
				success: '',
			},
		},
	},
};

const callback = (path?: string) => {

	router = new VueRouter({
		mode: 'history',
		base: process.env.BASE_URL || '/',
		routes,
	});

	router.beforeEach((route, redirect, next) => {

		window.scrollTo(0, 0);

		if (!route.params.lang) {
			route.params.lang = vm ? vm.$i18n.locale : lang;
		}

		if (vm && route.params.lang !== vm.$i18n.locale) {
			let newLang = route.params.lang;
			if (!messages[newLang]) {
				newLang = 'en';
			}
			const cookieDate = new Date();
			cookieDate.setMonth(cookieDate.getMonth() + 12);
			Cookies.set("lang", newLang, cookieDate, '/', process.env.VUE_APP_COOKIE_DOMAIN);
			Server.setLocale(newLang);
			vm.$i18n.locale = newLang;
			moment.locale(newLang);
		}

		next();

	});

	if (path) {
		router.replace(path);
	}

	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN_ECOM_V2,
		environment: process.env.NODE_ENV,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [router.currentRoute.path, router.currentRoute.fullPath, /^\//],
			}),
		],
		initialScope: scope => {
			let sentryVar = globalVariables;
			scope.setTags({
				'tenant_id': sentryVar.websiteConfig.data.tenant.id,
				'tenant_name': sentryVar.websiteConfig.data.tenant.name,
			});
			return scope;
		},
		trackComponents: true,
		tracesSampleRate: 0.3,
	});

	const injectVue = function () {
		if (vm) {
			vm.$destroy();
		}
		vm = new Vue({
			router,
			vuetify,
			i18n,
			data: {
				...globalVariables
			},
			render: (h) => h(component),
		}).$mount('#app');

		globalVariables.vm = vm;
	}

	injectVue();
};
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
Vue.prototype.$sdk = new Storage('sdk');

const gotoApp = (path?: string) => {
	component = App;
	routes = appRoutes;
	localStorage.removeItem('redirect_uri');
	callback(path);
};

const gotoLoading = (path?: string) => {
	component = Loading;
	routes = [];
	callback(path);
};

const gotoError = (path?: string) => {
	component = ErrorPage;
	routes = [];
	callback(path);
};

const applyTheme = configs => {
	const configFields = [
		'primary-color',
		'secondary-color',
		'button-color',
		'secondaryLight-color',
		'secondaryDark-color',
		'text-color'
	];
	let key;
	for (const type in configs.data.fields.theme) {
		for (const key in configs.data.fields.theme[type]) {
			if (configs.data.fields.theme[type][key]) {
				vuetifyOpts.theme.themes[type][key] = configs.data.fields.theme[type][key];
			}
		}
	}
	for (const field in configs.data.fields) {
		if (configFields.includes(field)) {
			key = field.slice(0, -6);
			if (configs.data.fields[field] !== '') {
				vuetifyOpts.theme.themes.light[key] = configs.data.fields[field];
			}
		}
	}
	vuetify = new Vuetify(vuetifyOpts);
};

const applyFavicon = configs => {
	const links: NodeListOf<HTMLLinkElement> | null = document.querySelectorAll("link[rel~='icon']");
	if (links.length > 0) {
		links.forEach(link => {
			link.href = configs.data.images.favicon;
		});
	}
};

const applyTitle = configs => {
	document.title = configs.data.name;
};

const applyAdditionalHeaders = (configs, mustCookieConsent = false) => {
	if (!configs.data.fields['additional-headers']) return;

	try {
		const html = new DOMParser().parseFromString(configs.data.fields['additional-headers'], 'text/html');
		const snippets = html.querySelectorAll('head > *, body > *');
		if (snippets) {
			snippets.forEach(snippet => {
				if (snippet.nodeName === 'SCRIPT') {
					const src = snippet.getAttribute('src');
					if (mustCookieConsent) {
						snippet.setAttribute('type', 'text/plain');

						if (!snippet.getAttribute('cookie-consent')) {
							console.error('Warning: cookie-consent attribute not set on script tag');
						}
					}
					if (src) {
						fetch(src).then(response => response.text())
							.then(response => eval(response));
					} else {
						eval(snippet.innerHTML);
					}
				} else if (snippet.nodeName === 'LINK') {
					const href = snippet.getAttribute('href');
					if (href) {
						fetch(href).then(response => response.text())
							.then(response => eval(response))
					}
				} else {
					document.getElementsByTagName('head')[0].appendChild(snippet);
				}
			})
		}
	} catch (e) {
		console.log(e);
	}
};

const applyAdditionalFooters = (configs, mustCookieConsent = false) => {
	if (!configs.data.fields['additional-footers']) return;
	try {
		const html = new DOMParser().parseFromString(configs.data.fields['additional-footers'], 'text/html');
		const snippets = html.querySelectorAll('head > *, body > *');

		if (snippets) {
			snippets.forEach(snippet => {
				if (snippet.nodeName === 'SCRIPT') {
					const src = snippet.getAttribute('src');
					if (mustCookieConsent) {
						snippet.setAttribute('type', 'text/plain');

						if (!snippet.getAttribute('cookie-consent')) {
							console.error('Warning: cookie-consent attribute not set on script tag');
						}
					}
					if (src) {
						fetch(src).then(response => response.text())
							.then(response => eval(response))
					} else {
						eval(snippet.innerHTML)
					}
				} else {
					document.getElementsByTagName('body')[0].appendChild(snippet);
				}
			})
		}
	} catch (e) {
		console.log(e);
	}
};

const getCart = () => {
	new EComService().getCart()
		.then((cart) => {
			Sentry.configureScope(scope => {
				scope.setTag('cart_id', cart.data.id);
				scope.setExtras({ cartData: cart.data });
			});
			globalVariables.cart = cart;
			EventBus.publish('CART_UPDATED', cart);
		}).catch((reason) => {
			new EComService().logout()
				.then(() => {
					if (reason.response && reason.response.status === 422) {
						Vue.prototype.$snack('Session expired. Please log in again', 'warning');
					} else {
						Vue.prototype.$snack(reason.messages.error, 'error');
					}
					globalVariables.cart = null;
					globalVariables.user.data = new AccountModel().data;
					Vue.prototype.$sdk.remove('access_token');
					Vue.prototype.$sdk.remove('refresh_token');
					Vue.prototype.$sdk.remove('account');
					Vue.prototype.$sdk.remove('account_id');
					Vue.prototype.$sdk.remove('cartId');
					getCart();
				})
		});
};

const getAvailableCombos = () => {
	new EComService().getAvailableCombos()
		.then((combos) => {
			EventBus.publish('COMBO_UPDATED', combos.results);
		}).catch((reason) => {
			if (reason?.response?.status !== 404 && reason?.messages?.error) {
				Vue.prototype.$snack(reason?.messages?.error, 'error');
			}
			EventBus.publish('COMBO_UPDATED', []);
		});
};

applyTheme(globalVariables.websiteConfig);
applyFavicon(globalVariables.websiteConfig);
applyTitle(globalVariables.websiteConfig);

const applyLang = lang => {
	const cookieDate = new Date();
	cookieDate.setMonth(cookieDate.getMonth() + 12);
	Cookies.set("lang", lang, cookieDate, '/', process.env.VUE_APP_COOKIE_DOMAIN);
	Server.setLocale(lang);

	vm.$i18n.locale = lang;
	moment.locale(lang);

	vm.$route.params.lang = lang;
};

gotoLoading();
Promise.all([
	new EComService().getWebsite(),
	new EComService().getSession(),
]).then(([websiteConfig, user]) => {
	Sentry.configureScope(scope => {
		scope.setExtras({ userData: user.data });
	});
	Object.assign(globalVariables.user.data, user.data);
	globalVariables.tenantId = websiteConfig.data.tenant_id;
	globalVariables.salesgroupId = websiteConfig.data.salesgroup_id;
	globalVariables.pointOfSaleId = websiteConfig.data.pointofsale_id;
	globalVariables.websiteConfig.mergeData(websiteConfig.data);
	applyTheme(websiteConfig);
	applyFavicon(websiteConfig);
	applyTitle(websiteConfig);
	applyLang(websiteConfig.data.default_locale);

	const mustCookieConsent = true;
	if (mustCookieConsent) {
		CookieConsent.inject({
			siteName: websiteConfig.data.name,
			language: languageMapping[vm.$route.params.lang.substring(0, 2)].english,
			privacy_policy_url: "https://connectngo.com/privacy-policy",
		});
	}
	applyAdditionalHeaders(websiteConfig, mustCookieConsent);
	applyAdditionalFooters(websiteConfig, mustCookieConsent);
	getCart();
	getAvailableCombos();
	gotoApp();
}).catch((e) => {
	console.log(e);
	globalVariables.user = new AccountModel();
	gotoError();
});

Vue.prototype.$breadcrumbs = {
	refresh() {
		EventBus.publish('BREADCRUMB_REFRESH');
	},
};
Vue.prototype.$applyTheme = applyTheme;
Vue.prototype.$applyFavicon = applyFavicon;
Vue.prototype.$deepClone = function (obj) {
	return JSON.parse(JSON.stringify(obj));
};
Vue.prototype.$snack = (content, color = vuetifyOpts.theme.themes.light.success, icon = 'mdi-check') => {
	Object.assign(globalVariables.snack, { color, icon, content, visible: true });
};
Vue.prototype.$modal = {
	show: (title, body, buttons = [
		{ text: messages[lang].btn.close },
	], attrs: {}) => {
		Object.assign(globalVariables.modal, { title, body, buttons, attrs, visible: true });
	},
	hide: () => {
		Object.assign(globalVariables.modal, { visible: false });
	},
}
// EventBus.subscribe('LOGIN', gotoApp);
