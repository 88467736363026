import { render, staticRenderFns } from "./AdyenForm.vue?vue&type=template&id=5b6736ab&scoped=true&data-cy=template-0&"
import script from "./AdyenForm.vue?vue&type=script&lang=js&"
export * from "./AdyenForm.vue?vue&type=script&lang=js&"
import style0 from "./AdyenForm.vue?vue&type=style&index=0&id=5b6736ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6736ab",
  null
  
)

export default component.exports