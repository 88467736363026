<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item"  data-cy="v-skeleton-loader-0"/>
	<v-form v-else-if="step === 'start'" :disabled="loading" data-test-selector="gift_card_form_start" v-model="formStartValid" @submit.prevent="handleContinueClick" data-cy="v-form-0">
		<v-card data-cy="v-card-0">
			<v-card-title v-text="$t('cart.accountReceivable.accept')" data-cy="v-card-title-0"></v-card-title>
			<v-card-text data-cy="v-card-text-0">

				<v-checkbox v-model="data.readTerms" :disabled="loading" name="read_terms" class="mt-0" :rules="[rules.required]">
					<template v-slot:label>
				<span>
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
				</span>
					</template>
				</v-checkbox>

				<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canContinue" :loading="loading" block x-large>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</v-form>
</template>

<script>
import Vue from 'vue';
import { BaseModel, Rules, EComService, CartModel, EventBus, PaymentService, PaymentModel } from '@connectngo/sdk';

export default {
	name: 'AccountReceivableForm',

	props: {
		skeleton: {
			type: Boolean,
			default: false,
		},
		component: {
			type: Vue.component,
			default: null,
		},
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
		engine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		accountReceivableEngine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
	},

	data: () => ({
		loading: false,
		paying: false,
		success: false,
		error: false,
		step: 'start',
		formStartValid: false,
		formSummaryValid: false,
		formPaymentValid: false,
		details: new BaseModel(),
		data: {
			readTerms: false,
			cashless: {
				number: '',
				serial: '',
				balance: 0,
			},
		},
		formErrors: {},
		rules: {},
	}),

	computed: {
		canContinue() {
			switch (this.step) {
				case 'start':
					return this.formStartValid && !this.loading;
				case 'summary':
					return this.formSummaryValid && !this.loading;
			}
			return true;
		},
		balanceRemainingAfterPayment() {
			return this.details.data.balance - this.data.cashless.balance;
		},
		balanceRemainingToPay() {
			return this.payments.data.balance - this.data.cashless.balance;
		},
		checkBalanceRemainingToPay() {
			if(Object.keys(this.cart?.getAppliedDiscounts()).length > 0 && this.cart.data?.total <= 0) {
				return this.cart.data.total;
			} else {
				return this.balanceRemainingToPay;
			}
		}
	},

	created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			balance: value => (value <= this.details.data.balance) || this.$t('rules.balance'),
			summary: value => (value <= this.payments.data.balance || value === 0) || this.$t('rules.summary'),
		};
	},

	methods: {
		handleContinueClick() {
			this.pay();
		},
		handleCancelClick() {
			if (this.step === 'start') {
				this.$emit('cancel');
			} else {
				this.step = this.steps[this.steps.findIndex(item => item === this.step) - 1];
			}
		},
		handleTermsClick() {
			EventBus.publish('OPEN_PAGE', 'terms');
		},
		pay() {

			this.paying = true;
			Promise.all([
				new PaymentService().processAccountReceivable(
					this.accountReceivableEngine.data.id,
					this.cart.data.uuid,
					this.checkBalanceRemainingToPay,
				)
			])
				.then(([payment, cashless]) => {
					this.success = true;
					setTimeout(() => {
						this.success = false;
					}, 3000);
					this.$emit('completed', payment);
				})
				.catch(error => {
					this.error = true;
					this.$handleError(this, error);
				})
				.finally(() => this.paying = false);
		},
	},
}
</script>
