<template data-cy="template-0">
	<v-window v-model="sessionPanel" ref="window" data-cy="v-window-0">
		<v-window-item @click.stop @keydown.stop data-cy="v-window-item-0">
			<v-card-text data-cy="v-card-text-0" class="px-8 py-8">
				<LoginForm
					class="mb-4"
					@logged="handleCompleted"
					@forgotPassword="handleForgotPasswordClick()"
					:showContinueAsGuestTab="showContinueAsGuestTab"
				/>
				<div class="text-center mt-6" data-cy="div-0">
					<v-divider data-cy="v-divider-0"/>
					<div class="mt-n3 mb-3" data-cy="div-1">
						<v-sheet :color="backgroundColor" class="d-inline-block px-3" v-text="$t('loginForm.or')" data-cy="v-sheet-0"></v-sheet>
					</div>
				</div>
				<v-list color="transparent" rounded data-cy="v-list-1">
					<v-btn
						data-testid="createAccountBtn"
						id="createAccountBtn"
						style="background-color: white"
						color="button"
						outlined
						rounded
						large
						block
						@click="handleRegisterClick"
					>
						<v-icon left data-cy="v-icon-0">mdi-account-plus-outline mdi-flip-h</v-icon>
						<span v-text="$t('loginForm.createAccount')" data-cy="span-0"></span>
						<v-icon right data-cy="v-icon-1">mdi-arrow-right</v-icon>
					</v-btn>
				</v-list>
			</v-card-text>
		</v-window-item>
		<v-window-item @keydown.stop @click.stop data-cy="v-window-item-1">
			<v-card-text data-cy="v-card-text-1">
				<v-btn block text @click="sessionPanel = 0" data-cy="v-btn-2">
					<v-icon left data-cy="v-icon-4">mdi-arrow-left</v-icon>
					<span v-text="$t('loginForm.return')" data-cy="span-2"></span>
				</v-btn>
				<h3 class="font-weight-regular text-center ma-4" v-text="$t('loginForm.createAccount')" data-cy="h3-0"></h3>

				<CreateAccountForm @completed="handleCompleted"  data-cy="CreateAccountForm-0"/>
			</v-card-text>

			<v-card-text data-cy="v-card-text-2">
				<v-divider data-cy="v-divider-1"></v-divider>
			</v-card-text>
			<v-card-title class="text-center pt-0" style="word-break: break-word" v-text="$t('createAccountForm.alreadyHaveAccount')" data-cy="v-card-title-0"></v-card-title>
			<v-card-text data-cy="v-card-text-3">
				<v-btn color="button" data-test-selector="create_account_form_already_have_account" outlined dark block @click="sessionPanel = 2" data-cy="v-btn-3">
					<span v-text="$t('btn.login')" data-cy="span-3"></span>
				</v-btn>
			</v-card-text>
		</v-window-item>
		<v-window-item @keydown.stop @click.stop data-cy="v-window-item-2">
			<v-card-text data-cy="v-card-text-4" class="px-8 py-8">
				<LoginForm @logged="handleCompleted" @forgotPassword="handleForgotPasswordClick()"  data-cy="LoginForm-1" @onReturn="sessionPanel = 1"/>
			</v-card-text>
		</v-window-item>
		<v-window-item @keydown.stop @click.stop data-cy="v-window-item-4">
			<v-card-text data-cy="v-card-text-6">
				<v-btn block text @click="sessionPanel = 0" data-cy="v-btn-6">
					<v-icon left data-cy="v-icon-7">mdi-arrow-left</v-icon>
					<span v-text="$t('loginForm.return')" data-cy="span-6"></span>
				</v-btn>
				<ForgotPasswordForm @reset="sessionPanel = 2"  data-cy="ForgotPasswordForm-0"/>
			</v-card-text>
		</v-window-item>
		<v-window-item @keydown.stop @click.stop data-cy="v-window-item-5">
			<v-card-text data-cy="v-card-text-7">
				<v-btn block text @click="sessionPanel = 0" data-cy="v-btn-7">
					<v-icon left data-cy="v-icon-8">mdi-arrow-left</v-icon>
					<span v-text="$t('loginForm.return')" data-cy="span-7"></span>
				</v-btn>
				<h3 class="font-weight-regular text-center ma-4" v-text="$t('loginForm.completeProfileForm')" data-cy="h3-4"></h3>

				<CompleteProfileForm @completed="handleCompleted"  data-cy="CompleteProfileForm-0"/>
			</v-card-text>
		</v-window-item>
	</v-window>
</template>

<script>
import CreateAccountForm from '@/components/CreateAccountForm'
import LoginForm from '@/components/LoginForm'
import ForgotPasswordForm from '@/components/ForgotPasswordForm'
import CompleteProfileForm from '@/components/CompleteProfileForm'

export default {
	name: 'KYC',

	components: { CreateAccountForm, LoginForm, CompleteProfileForm, ForgotPasswordForm },

	props: {
		backgroundColor: {
			type: String,
			default: null,
		},
		registerPage: {
			type: Boolean,
			default: false,
		},
		route: {
			type: [Object, String],
			default: null,
		},
		showContinueAsGuestTab: {
			type: Boolean,
			default: false,
		}
	},

	data: () => ({
		sessionPanel: 0,
	}),
	created () {
		switch (this.route) {
			case 'register':
				this.sessionPanel = 1
				break
			case 'login':
				this.sessionPanel = 2
				break
			case 'password.forgot':
				this.sessionPanel = 3
				break
		}
	},

	methods: {
		handleRegisterClick () {
			if (this.registerPage && this.$route.params?.resId) {
				this.$router.push({ name: 'res-register', params: { resId: this.$route.params?.resId} })
			} else if (this.registerPage) {
				this.$router.push({ name: 'register' })
			} else {
				this.sessionPanel = 1
			}
			this.$refs.window.$el.click();
		},
		handleForgotPasswordClick () {
			this.sessionPanel = 3
		},
		handleCompleted (data) {
			if(data) {
				this.$router.push(data);
			}
			window.dispatchEvent(new Event('resize'))
			this.$emit('completed');
			this.$refs.window.$el.click();
		},
	},
}
</script>
