<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
		<v-card-title v-if="!hideTitle" class="text-left secondaryDark--text font-weight-bold" v-text="$t('addOnAssignCard.title')" data-cy="v-card-title-0"></v-card-title>
		<v-card-text data-cy="v-card-text-0">
			<v-alert v-if="value.results.length === 0" text prominent type="info" data-cy="v-alert-0">
				<span v-text="$t('addOnAssignCard.empty')" data-cy="span-0"></span>
			</v-alert>
			<div :key="itemIdx" v-for="(item, itemIdx) in value.results" data-cy="div-0">
				<v-divider v-if="itemIdx > 0" class="my-4"  data-cy="v-divider-0"/>

				<div class="d-flex align-center justify-content-between" data-cy="div-1">
					<v-icon class="mr-4" x-large data-cy="v-icon-0">mdi-star-circle-outline</v-icon>

					<h4 style="color: black;" v-text="$t('virtualWalletDetailsCard.balance', {
						type: $options.filters.translatable(item.data.addon_type.name, item.data.addon_type.name_i18n, $i18n.locale),
						//balance: item.data.balance > 0 ? ('+' + item.data.balance) : item.data.balance < 0 ? ('-' + item.data.balance) : item.data.balance,
					})" data-cy="h4-0"></h4>
					<strong class="secondaryDark--text" style="margin-left: auto;"  data-cy="strong-0">
						<span data-cy="span-1">
							{{item.data.balance}}
						</span>
					</strong>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'AddOnAssignCard',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		hideTitle: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
