var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"data-cy":"v-container-0"}},[_c('v-row',{attrs:{"data-cy":"v-row-0"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12","data-cy":"v-col-0"}},[_c('h2',{staticClass:"display-1 font-weight-light",attrs:{"data-cy":"h2-0"},domProps:{"textContent":_vm._s(_vm.$t('account.wallet.title', {
				name: _vm.$root.user.data.firstname,
			}))}})])],1),_c('v-row',{attrs:{"data-cy":"v-row-1"}},[_c('v-col',{attrs:{"cols":"12","lg":"5","data-cy":"v-col-1"}},[_c('VirtualWalletDetailsCard',{attrs:{"cashless":_vm.balances,"skeleton":_vm.skeleton,"data-cy":"VirtualWalletDetailsCard-0"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),(_vm.$root.websiteConfig.data.experiential_module)?_c('v-card',{staticClass:"mt-6",attrs:{"data-cy":"v-card-0"}},[_c('v-card-title',{staticClass:"pb-0",attrs:{"data-cy":"v-card-title-0"},domProps:{"textContent":_vm._s(_vm.$t('account.wallet.memories'))}}),_c('MediaGallery',{attrs:{"skeleton":_vm.skeleton,"dense":"","no-grouping":"","data-cy":"MediaGallery-0"},model:{value:(_vm.memories),callback:function ($$v) {_vm.memories=$$v},expression:"memories"}}),_c('v-card-actions',{attrs:{"data-cy":"v-card-actions-0"}},[_c('v-btn',{attrs:{"color":"primary","block":"","to":{
						name: 'account_section_sub_action',
						params: {
							section: 'wallet',
							sub: _vm.$route.params.sub,
							action: 'memories',
						}
					},"data-cy":"v-btn-0"}},[_c('span',{attrs:{"data-cy":"span-0"},domProps:{"textContent":_vm._s(_vm.$t('account.wallet.mediaGallery.goToBtn'))}})])],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"7","data-cy":"v-col-2"}},[_c('VirtualWalletContent',{attrs:{"admissions":_vm.admissions,"cashless":_vm.cashless,"addons":_vm.addons,"prePurchasedItems":_vm.prepurchasedItems,"skeleton":_vm.skeleton,"data-cy":"VirtualWalletContent-0"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }