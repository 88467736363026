<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row data-cy="v-row-0">
			<v-col cols="12" lg="6" data-cy="v-col-0">
				<AccountCard v-model="account" :skeleton="skeleton"  data-cy="AccountCard-0"/>
				<TransactionHistoryListingCard class="mt-8" v-model="transactionHistory" :skeleton="skeleton"  data-cy="TransactionHistoryListingCard-0"/>
			</v-col>
			<v-col cols="12" lg="6" data-cy="v-col-1">
				<VirtualWalletListingCard :value="virtualWallets" :skeleton="skeleton" :user="account" @created="handleVirtualWalletCreated"  data-cy="VirtualWalletListingCard-0"/>
				<ConnectedAccountListingCard class="mt-8" v-model="connectedAccounts" :skeleton="skeleton" @refresh="handleRefresh"  data-cy="ConnectedAccountListingCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AccountCard from '@/components/AccountCard';
import TransactionHistoryListingCard from '@/components/TransactionHistoryListingCard';
import VirtualWalletListingCard from '@/components/VirtualWalletListingCard';
import ConnectedAccountListingCard from '@/components/ConnectedAccountListingCard';
import { AccountModel, EComService, PaginationModel } from '@connectngo/sdk';

export default {
	name: 'Index',

	metaInfo() {
		return {
			title: this.$i18n.t('route.account'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	components: { AccountCard, TransactionHistoryListingCard, VirtualWalletListingCard, ConnectedAccountListingCard },

	data: () => ({
		skeleton: true,
		loading: false,
		account: new AccountModel(),
		transactionHistory: new PaginationModel(),
		virtualWallets: new PaginationModel(),
		connectedAccounts: new PaginationModel(),
	}),

	methods: {
		handleRefresh() {
			this.load();
		},
		handleVirtualWalletCreated(wallet) {
			this.$snack(this.$i18n.t('virtualWallet.created'));
			this.virtualWallets.results.unshift(wallet);
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getSession(),
				new EComService().getTransactionHistory(this.$root.user.data.id, {
					per_page: 5,
				}),
				new EComService().getVirtualWallets(this.$root.user.data.id, {
					per_page: 5,
				}),
				new EComService().getConnectedAccounts(this.$root.user.data.id),
			])
				.then(([account, transactionHistory, virtualWallets, connectedAccounts]) => {
					Object.assign(this, { account, transactionHistory, virtualWallets, connectedAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
