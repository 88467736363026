<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-form v-else id="editPasswordCard" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation @submit="handleSubmit" data-cy="v-form-0">
		<v-card v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">
			<v-card-title class="text-left" data-cy="v-card-title-0">
				<span v-text="$t('account.edit.password.title')" data-cy="span-0"></span>
			</v-card-title>
			<v-card-text data-cy="v-card-text-0">

				<p v-text="$t('account.edit.password.desc')" data-cy="p-0"></p>

				<v-expand-transition data-cy="v-expand-transition-0">
					<v-alert v-if="saved" type="success" prominent dismissible data-cy="v-alert-0">
						<span v-text="$t('alert.passwordUpdated')" data-cy="span-1"></span>
					</v-alert>
				</v-expand-transition>

<!--				<v-text-field-- data-cy="v-text-field---0">
<!--					v-model="data.current"-->
<!--					:label="$t('input.currentPassword')"-->
<!--					:error-messages="formErrors.current"-->
<!--					:rules="[rules.required]"-->
<!--					:loading="skeleton"-->
<!--					:type="showCurrent ? 'text' : 'password'"-->
<!--					:append-icon="!showCurrent ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--					outlined-->
<!--					@click:append="showCurrent = !showCurrent"-->
<!--					@input="handleInput"-->
<!--				></v-text-field>-->

				<v-text-field
					v-model="data.password"
					:label="$t('input.newPassword')"
					:error-messages="formErrors.password"
					:rules="[rules.required, rules.min]"
					:loading="skeleton"
					:type="showPassword ? 'text' : 'password'"
					:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					outlined
					@click:append="showPassword = !showPassword"
					@input="handleInput"
				 data-cy="v-text-field-1"></v-text-field>

				<v-text-field
					v-model="data.confirmation"
					:label="$t('input.confirmation')"
					:error-messages="formErrors.confirmation"
					:rules="[rules.required, rules.identical, rules.min, rules.isValidPassword]"
					:loading="skeleton"
					:type="showConfirmation ? 'text' : 'password'"
					:append-icon="!showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
					outlined
					@click:append="showConfirmation = !showConfirmation"
					@input="handleInput"
				 data-cy="v-text-field-2"></v-text-field>

				<v-expand-transition data-cy="v-expand-transition-1">
					<StrengthIndicator
						v-if="formIsDirty"
						class="my-4"
						:title="$t('createAccountForm.passwordMustContain')"
						:conditions="passwordConditions"
					 data-cy="StrengthIndicator-0"></StrengthIndicator>
				</v-expand-transition>

			</v-card-text>
			<v-card-actions data-cy="v-card-actions-0">
				<v-btn color="button" type="submit" :loading="loading" :disabled="!canSave" block @click.prevent="handleSubmit" data-cy="v-btn-0">
					<v-icon left data-cy="v-icon-0">mdi-content-save-outline</v-icon>
					<span v-text="$t('btn.confirm')" data-cy="span-2"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import {Rules, AccountModel, EComService} from '@connectngo/sdk';
import StrengthIndicator from "@/components/StrengthIndicator";
import PasswordConditions from "@/mixins/PasswordConditions";

export default {
	name: 'EditPasswordCard',

	mixins: [ PasswordConditions() ],

	components: { StrengthIndicator },

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		saved: false,
		formIsValid: true,
		showCurrent: false,
		showPassword: false,
		showConfirmation: false,
		formIsDirty: false,
		formErrors: {},
		data: {
			current: '',
			password: '',
			confirmation: '',
		},
	}),

	computed: {
		canSave() {
			return !this.loading && this.formIsValid && !this.skeleton;
		},
		canSubmit() {
			return !this.loading && !this.skeleton;
		},
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
				identical: () => Rules.identical(this.data.password, this.data.confirmation) || this.$t('rules.passwordConfirm'),
				isValidPassword: () => this.passwordIsValid || this.$t('rules.passwordInvalid'),
			};
		},
	},

	methods: {
		handleInput() {
			this.formIsDirty = true;
			this.formErrors = {};
		},
		handleSubmit() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				return Promise.all([
					new EComService().updatePassword(this.data.password),
					// new EComService().getTags()
				])
					.then(([response]) => {
						this.$snack(this.$i18n.t('alert.passwordUpdated'));
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			}
		},
	},

}
</script>
