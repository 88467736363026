<template data-cy="template-0">
	<v-icon v-if="!qrCode" :style="{
		minWidth: size + 'px',
		float: 'left',
	}" :size="size / 2" data-cy="v-icon-0">mdi-alert-outline</v-icon>
	<img v-else :src="qrCode" :width="size" :height="size" :style="{
		minWidth: size + 'px',
		float: 'left',
	}" alt="QR" v-bind="$attrs" v-on="$listeners"  data-cy="img-0"/>
</template>

<script>
import QRCode from 'qrcode'

export default {
	name: 'QrCode',

	props: {
		value: {
			type: String,
			default: '',
			required: true,
		},
		size: {
			type: Number,
			default: 128,
		},
	},

	data: () => ({
		qrCode: null,
	}),

	watch: {
		value() {
			this.generate();
		},
	},

	methods: {
		generate() {
			if (this.value) {
				QRCode.toDataURL(this.value)
					.then(url => this.qrCode = url)
					.catch(error => this.$handleError(this, error));
			}
		},
	},

	created() {
		this.generate();
	},
}
</script>
