<template data-cy="template-0">
	<v-form v-window-focus="focused" id="reload" ref="form" :disabled="loading" data-test-selector="cart_reload_form" v-model="formValid" @submit.prevent="handleSubmitForm" data-cy="v-form-0">
		<v-container data-cy="v-container-0">
<!--			<ProcessOverlay v-if="loadingProductPdf" data-cy="ProcessOverlay-0"/>-->

			<TransactionCard v-model="details" :skeleton="skeleton" :to="null" hide-qr-code  data-cy="TransactionCard-0"/>

			<!-- PDF MODAL -->
			<Modal v-model="showPdf" :title="pdfTitle ? pdfTitle : $t('account.transactions.details.pdfModal.unavailable')" max-width="800" scrollable data-cy="Modal-0">
				<template #body data-cy="template-1">
					<iframe v-if="pdfData" :src="'data:application/pdf;base64,' + pdfData.data.base_64" class="waiver_pdf" data-cy="iframe-0"></iframe>
				</template>
				<template #buttons>
					<v-btn :disabled="!pdfData" color="button" @click="onDownloadPdfClick">
						<span v-text="$t('btn.download')"></span>
					</v-btn>
					<v-btn @click="showPdf = false; pdfData = null; pdfTitle = null">
						<span v-text="$t('btn.cancel')"></span>
					</v-btn>
				</template>
			</Modal>

			<!-- Product PDF MODAL -->
			<Modal v-model="showProductsPdf" :title="$t('account.transactions.products.pdfModal.title')" max-width="800" scrollable>
				<template #body>
					<iframe v-if="productPdfUrl" :src="productPdfUrl" class="waiver_pdf"></iframe>
					<div v-if="loadingProductPdf" class="pa-3 ma-auto text-center">
						<p v-text="$t('paymentProcessOverlay.processing')"></p>
						<v-progress-circular indeterminate width="12" color="secondaryDark" size="128" />
					</div>
				</template>
				<template #buttons>
					<v-btn :disabled="!productPdfUrl" color="button" @click="onDownloadProductsPdfClick">
						<span v-text="$t('btn.download')"></span>
					</v-btn>
					<v-btn @click="showProductsPdf = false">
						<span v-text="$t('btn.cancel')"></span>
					</v-btn>
				</template>
			</Modal>

			<v-btn class="mt-8" color="button" :disabled="skeleton" :loading="skeleton" block x-large @click="onViewPdfClick('invoice')">
				<v-icon left>mdi-file-pdf</v-icon>
				<span v-text="$t('btn.invoicePdf')"></span>
			</v-btn>

			<v-btn v-if="productPdfUrl" class="mt-8" :disabled="skeleton" :loading="skeleton" block x-large @click="onViewProductsPdf()">
				<v-icon left>mdi-file-pdf</v-icon>
				<span v-text="$t('btn.viewPdf')"></span>
			</v-btn>

			<v-expansion-panels v-model="panel" class="mt-8" multiple>
				<v-expansion-panel class="px-0" :key="groupIdx" v-for="(group, groupIdx) in groups">
					<v-expansion-panel-header>
						<span v-text="group.name"></span>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row>
							<v-col :key="productIdx + '-' + groupIdx" v-for="(product, productIdx) in group.results" cols="12" lg="6" xl="4">
								<ProductWaiverCard :value="product" :skeleton="skeleton" flat tile />
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>
	</v-form>
</template>

<script>
import TransactionCard from '@/components/TransactionCard';
import ProductWaiverCard from '@/components/ProductWaiverCard';
import Modal from '@/components/Modal';
import { EComService, PaginationModel, TransactionModel, ProductModel, BaseModel } from '@connectngo/sdk';
import globalVariables from '@/global'
import ProcessOverlay from '@/components/ProcessOverlay'

export default {
	name: 'Details',

	metaInfo() {
		return {
			title: this.$i18n.t('account.transactions.details.title', { name: this.details.data.transaction_id }),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('account.transactions.details.title', { name: this.details.data.transaction_id }) },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	components: { ProcessOverlay, ProductWaiverCard, TransactionCard, Modal },

	data: () => ({
		loading: false,
		formValid: false,
		details: new TransactionModel(),
		products: new PaginationModel([{ product: {} }, { product: {} }, { product: {} }, { product: {} }], ProductModel),
		productsPdf: new BaseModel(),
		invoicePdf: new BaseModel(),
		transactionPdf: new BaseModel(),
		showPdf: false,
		skeleton: true,
		panel: [0],
		groups: [],
		pdfData : null,
		pdfTitle : null,
		pdfType: 'INVOICE',
		pdfCustomerType: 'INDIVIDUAL',
		productPdfUrl: null,
		showProductsPdf: false,
		loadingProductPdf: false
	}),

	computed: {
		canSubmit() {
			return !this.loading && this.formValid;
		},
	},

	methods: {
		focused() {
			this.load();
		},
		onViewPdfClick(type) {
			if(type === 'invoice') {
				if(globalVariables.websiteConfig.featureFlagEnabled('co-4175-transaction-pdfs')) {
					if (!this.transactionPdf) {
						this.$handleError(this,{ message:  this.$t('alert.genericError')});
						return;
					}

					window.open(this.transactionPdf.data.url);
				} else {
					if (!this.invoicePdf) {
						this.$handleError(this,{ message:  this.$t('alert.genericError')});
						return;
					}

					this.pdfData = this.invoicePdf
					this.pdfTitle = this.$t('account.transactions.details.pdfModal.title')
					this.showPdf = true;
				}
			} else if(type === 'products') {
				if (!this.productsPdf) {
					this.$handleError(this,{ message:  this.$t('alert.genericError')});
					return;
				}
				this.pdfData = this.productsPdf
				this.pdfTitle = this.$t('account.transactions.products.pdfModal.title')
				this.showPdf = true;
			}
		},
		onViewProductsPdf() {
			this.showProductsPdf = true;
		},
		onDownloadPdfClick() {
			if (!this.pdfData) return;
			const byteCharacters = atob(this.pdfData.data.base_64);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const file = new Blob([byteArray], { type: 'application/pdf;base64' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		},
		onDownloadProductsPdfClick() {
			window.open(this.productPdfUrl, '_blank');
		},
		formatGroups() {
			this.groups = [];
			this.products.results.forEach(product => {
				const found = this.groups.find(group =>
					group.name === this.$options.filters.translatable(product.data.product.name, product.data.product.name_i18n, this.$i18n.locale)
				);

				if (!found) {
					this.groups.push({
						name: this.$options.filters.translatable(product.data.product.name, product.data.product.name_i18n, this.$i18n.locale),
						results: [product],
					})
				} else {
					found.results.push(product);
				}
			});
		},
		load() {
			this.loading = true;
			if(this.$route.params.is_b2b) {
				this.pdfCustomerType = 'CORPORATE';
			}
			return Promise.all([
				new EComService().getTransactionDetails(this.$route.params.sub),
				new EComService().getTransactionProducts(this.$route.params.sub),
				new EComService().getProductsPdfUrl(this.$route.params.sub),
				new EComService().getInvoicePdf(this.$route.params.sub),
				new EComService().getTransactionPdf(this.$route.params.sub, this.pdfType, this.pdfCustomerType),
			])
				.then(([details, products, pdfStatus, invoicePdf, transactionPdf]) => {
					this.$route.meta.sub = details.data.transaction_id;
					this.$breadcrumbs.refresh();
					Object.assign(this, { details, products, invoicePdf, transactionPdf });
					this.formatGroups();
					if (pdfStatus !== null && pdfStatus.data?.url) {
						this.productPdfUrl = pdfStatus.data.url;
					}
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},
	created() {
		this.load();
	},
}
</script>
