<template data-cy="template-0">
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners" data-cy="Modal-0">
		<template #body data-cy="template-1">
			<v-card-text class="px-0" data-cy="v-card-text-0">
				<v-container class="px-8" data-cy="v-container-0">
					<v-row data-cy="v-row-0">
						<v-col data-cy="v-col-0">
							<h4 v-text="cart.data.reservation_id" data-cy="h4-0"></h4>
						</v-col>
						<v-col class="d-flex justify-end"  data-cy="v-col-1">
							<h3 v-text="cart.depositAmount > 0 ? $options.filters.currency(cart.data.depositAmount): $options.filters.currency(cart.data.balance)" data-cy="h3-0"></h3>
						</v-col>
					</v-row>
				</v-container>
				<PaymentForm
					:engine="engine"
					:cart="cart"
					:reservation=true
					@completed="handlePaymentCompleted"
				 data-cy="PaymentForm-0"/>
			</v-card-text>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal'
import { BaseModel, CartModel, PaymentModel } from '@connectngo/sdk';
import PaymentForm from '@/components/PaymentForm'

export default {
	name: 'SelfPayDialog',

	components: { Modal,  PaymentForm },

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},

	},

	data () {
		return {
			engine: new BaseModel(),
			loading: false,
			skeleton: false,
			rules: {},
			formErrors: {},
			toggleDetails: false,
			panel: [0],
			tokenizationEngine: null,
			creditCardUpdateFormValid: true,
			creditCardUpdateFormData: {
				name: '',
				exp: '',
			},
		}
	},

	computed: {

		_visible: {
			get () {
				return this.visible
			},
			set (card) {
				this.$emit('update:visible', card)
			},
		},

	},

	methods: {
		handlePaymentCompleted(args) {

			if(this.cart.deposit){
				const totalPayments = Number(this.cart.data.allPayments.reduce((total,payment) => total + payment.amount,0).toFixed(2));
				const paid = Number(totalPayments + Number(this.cart.data.depositAmount.toFixed(2))).toFixed(2);
				const amount = Number(this.cart.data.amount).toFixed(2);

				args.paid = paid === amount;
			}else{
				args.paid = true;
			}			

			this.$emit('completed', args);
		},
	},

	watch: {
		token: {
			deep: true,
			handler () {
			},
		},
	},
}
</script>
