<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,actions" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" data-cy="v-card-0">

		<AddSubAccountModal
			:visible.sync="addSubAccountModal"
			:parent-account="$root.user"
			scrollable
			max-width="500"
			@created="handleSubAccountCreated"
		 data-cy="AddSubAccountModal-0"/>

		<v-card-title class="d-flex align-center" style="justify-content: space-between !important;" data-cy="v-card-title-0">
			<span v-text="$t('connectedAccountListingCard.title')" data-cy="span-0"></span>
			<v-btn color="button" @click.stop="handleAddConnectedAccountClick" data-cy="v-btn-0">
				<v-icon left data-cy="v-icon-0">mdi-account-plus</v-icon>
				<span v-text="$t('btn.add')" data-cy="span-1"></span>
			</v-btn>
		</v-card-title>
		<v-card-text data-cy="v-card-text-0">
			<p v-text="$t('connectedAccountListingCard.desc')" data-cy="p-0"></p>
		</v-card-text>
		<div v-if="value.results.length === 0" class="px-4 pb-1" data-cy="div-0">
			<v-alert text prominent type="info" data-cy="v-alert-0">
				<span v-text="$t('connectedAccountListingCard.empty')" data-cy="span-2"></span>
			</v-alert>
		</div>
		<v-list v-else data-cy="v-list-0">
			<template v-for="(item, itemIdx) in value.results" data-cy="template-1">
				<v-divider :key="itemIdx + '_sep'"  data-cy="v-divider-0"/>
				<v-list-item :key="itemIdx" :to="{ name: 'account_section_sub', params: { section: 'connected', sub: item.data.id } }" data-cy="v-list-item-0">
					<v-list-item-icon data-cy="v-list-item-icon-0">
						<v-avatar size="64" color="secondaryLight" data-cy="v-avatar-0">
							<v-img :src="item.data.image" size="64" contain  data-cy="v-img-0"/>
						</v-avatar>
					</v-list-item-icon>
					<v-list-item-content data-cy="v-list-item-content-0">
						<h3 class="title mb-1" style="line-height: 1.5rem" v-text="item.data.firstname" data-cy="h3-0"></h3>
						<div class="overline font-weight-light" style="line-height: 1rem" v-text="$options.filters.completeUserName(item)" data-cy="div-1"></div>
					</v-list-item-content>
				</v-list-item>
			</template>

			<v-list-item class="justify-end">
				<v-list-item-action-text>
					<router-link class="text-decoration-none d-flex align-center" :to="routeAll">
						<span v-text="$t('connectedAccountListingCard.sellAll')"></span>
						<v-icon right x-small>mdi-arrow-right</v-icon>
					</router-link>
				</v-list-item-action-text>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';
import AddSubAccountModal from '@/components/AddSubAccountModal'

export default {
	name: 'ConnectedAccountListingCard',

	components: { AddSubAccountModal },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		routeAll: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'connected' } }),
		},
	},

	data: () => ({
		addSubAccountModal: false,
	}),

	methods: {
		handleAddConnectedAccountClick() {
			this.addSubAccountModal = true;
		},
		handleSubAccountCreated(account) {
			this.$emit('refresh');
		},
	},
}
</script>
