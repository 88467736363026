<template data-cy="template-0">
	<v-expansion-panels v-model="panel" multiple flat data-cy="v-expansion-panels-0">
		<v-expansion-panel class="px-0" data-cy="v-expansion-panel-1">
			<v-expansion-panel-header data-cy="v-expansion-panel-header-0">
				<span v-text="$t('virtualWallAdmissionListing.active')" data-cy="span-0"></span>
			</v-expansion-panel-header>
			<v-expansion-panel-content data-cy="v-expansion-panel-content-0">
				<AdmissionAssignCard :value="value" class="pa-0" flat hide-title  data-cy="AdmissionAssignCard-0"/>
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel data-cy="v-expansion-panel-4">
			<v-expansion-panel-header data-cy="v-expansion-panel-header-1">
				<span v-text="$t('virtualWallAdmissionListing.expired')" data-cy="span-1"></span>
			</v-expansion-panel-header>
			<v-expansion-panel-content class="opacity-66" data-cy="v-expansion-panel-content-1">
				<AdmissionAssignCard :value="value" class="pa-0" expired readonly flat hide-title  data-cy="AdmissionAssignCard-1"/>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import AdmissionAssignCard from '@/components/AdmissionAssignCard';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletAdmissionListing',

	components: { AdmissionAssignCard },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
	},

	data: () => ({
		panel: [0]
	}),
}
</script>
