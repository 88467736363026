<template>
	<div class="w-100 container px-0" :class="className">
		<template v-if="isDesktopBreakpoint && showTitle">
			<div v-if="showDetails">
				<h1>Your title here</h1>
				<h2 class="font-weight-bold">Choose your time of visit</h2>
			</div>
			<div
				v-else
				class="mb-2 select__title"
				v-if="isDesktopBreakpoint && !hideHeader"
			>
				<li
					class="ml-2 font-weight-bold"
					v-text="
						$t('route.tags.combos.select.choose_your_time_of_visit')
					"
				/>
				<span class="font-weight-bold"> ( {{ product.data.name }} )</span>
			</div>
			<!-- Add the row your first selection is.  -->
			<p class="text-previous-selections highlighted mb-0" v-for="(text) in otherSelectedTimeslotsText" :key="text">
				*{{ text }}
			</p>
			<v-expansion-panels v-model="panel" flat v-if="!showDetails">
				<v-expansion-panel class="elevation-0">
					<v-expansion-panel-header class="text-black">
						{{
							selectedTime
								? selectedTime?.period
								: "00:00am - 00:00pm"
						}}
					</v-expansion-panel-header>
					<v-expansion-panel-content class="px-0">
						<v-list class="d-flex flex-column">
							<v-list-item
								v-for="(timeSlot, index) in timeSlots"
								:key="index"
								@click="onSelectTimeslotAndCollapsePanel(timeSlot)"
								class="px-6"
							>
								<v-list-item-content>
									<div
										class="
											d-flex
											justify-space-between
											w-100
										"
										:class="{ highlighted: isHighlighted(timeSlot) }"
									>
										<div
											class="text-left flex-grow-1"
											v-text="timeSlot.period"
										></div>
										<div
											class="text-left flex-grow-1"
											v-text="
												`${timeSlot.amountLeft} left`
											"
										></div>
									</div>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-list v-else class="d-flex flex-column">
				<v-list-item
					v-for="(timeSlot, index) in timeSlots"
					:key="index"
					@click="selectTime(timeSlot)"
					class="px-6"
				>
					<v-list-item-content>
						<div class="d-flex justify-space-between w-100">
							<div
								class="text-left flex-grow-1"
								v-text="timeSlot.period"
							></div>
							<div
								class="text-left flex-grow-1"
								v-text="`${timeSlot.amountLeft} left`"
							></div>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<v-expansion-panels v-else v-model="panel" flat>
			<v-expansion-panel class="elevation-0 pb-3">
				<v-expansion-panel-header>
					<div>
						<v-icon
							v-if="selectedTime?.period"
							class="mr-1"
							color="primary"
							data-cy="v-icon-0"
						>
							mdi-check
						</v-icon>
						{{ product.data.name }} {{ progress }}
					</div>
					<template v-slot:actions>
						<v-icon
							v-if="panel === 0"
							:class="{
								'mdi-chevron-down': panel === 0,
								'mdi-chevron-right': panel !== 0,
							}"
						>
							mdi-chevron-up
						</v-icon>
						<v-icon v-else>mdi-chevron-right</v-icon>
					</template>
				</v-expansion-panel-header>
				<p :key="text" v-for="(text, index) in otherSelectedTimeslotsText" class="text-previous-selections highlighted">*{{ text }}</p>
				<v-divider class="mx-6" />
				<v-expansion-panel-content>
					<div
						class="
							checkbox-container
							pl-6
							py-1
							pr-4
							d-flex
							justify-space-between
							align-center
						"
						v-for="(timeSlot) in timeSlots"
						:key="timeSlot.period"
						:class="{
							highlighted: isHighlighted(timeSlot),
							highlightedRow: checked[timeSlot.id] && isDesktopBreakpoint,
						}"
					>
						<v-checkbox
							type="checkbox"
							:label="`${timeSlot.period}`"
							v-model="checked[timeSlot.id]"
							hide-details
							@change="onCheckboxSelectTime(timeSlot)"
						/>
						<span>{{ getAmountLeftText(timeSlot) }}</span>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	name: "ProductTimeslotPicker",
	props: {
		otherSelectedTimeslots: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			required: false,
			default: "Select Time",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		timeSlots: {
			type: Array,
			default: () => [],
		},
		selectedIndex: {
			type: Number,
			default: 0,
		},
		product: {
			type: Object,
			default: () => {},
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		selectedDay: {
			type: String,
			default: "",
		},
		showDetails: {
			type: Boolean,
			default: false,
		},
		className: {
			type: String,
			default: "",
		},
		progress: {
			type: String,
			default: "0/0",
		},
	},
	data: () => ({
		selectedTime: false,
		panel: null,
		checked: {},
	}),
	watch: {
		selectedDay: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && newVal && this.timeSlots.length > 0) {
					this.resetCheckboxValues();
					this.selectedTime = null;
					this.panel = 0;
				}  else if (newVal !== oldVal && newVal && this.timeSlots.length === 0) {
					this.resetCheckboxValues();
					this.selectedTime = null;
					this.panel = null;
				} else if (newVal !== oldVal && !newVal) {
					this.resetCheckboxValues();
					this.selectedTime = null;
					this.panel = null;
				}
			},
			deep: true,
		},
	},
	computed: {
		otherSelectedTimeslotsText() {
			return this.otherSelectedTimeslots.map((timeslot) => {
				const ordinal = this.ordinalSuffixOf(timeslot.index + 1);
				return `${ordinal} time selected is ${timeslot.period}`;
			});
		},
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		},
	},
	methods: {
		selectTime(timeSlot) {
			this.selectedTime = timeSlot;
			this.$emit("option-selected", timeSlot);
		},
		getAmountLeftText(timeSlot) {
			if (!timeSlot) return '';
			if (this.isDesktopBreakpoint) {
				return `${timeSlot.amountLeft} spaces left`;
			} else {
				return `${timeSlot.amountLeft} remaining spaces`;
			}
		},
		onCheckboxSelectTime(timeSlot) {
			if (!Object.keys(this.checked).length) {
				this.resetCheckboxValues();
			}
			for (let id in this.checked) {
    			if (Number(id) !== Number(timeSlot.id)) {
      				this.checked[id] = false;
    			}
  			}
			if (this.checked?.[timeSlot.id]) {
				this.selectTime(timeSlot);
			} else {
				this.selectTime(null);
			}
			this.panel = null;
		},
		resetCheckboxValues() {
			this.timeSlots.forEach(timeSlot => {
    			this.$set(this.checked, timeSlot.id, false);
  			});
		},
		isHighlighted(timeslot) {
			return this.otherSelectedTimeslots.some((ts) => ts.period === timeslot.period);
		},
		onSelectTimeslotAndCollapsePanel(timeSlot) {
			this.selectTime(timeSlot);
			this.panel = null;
		},
		ordinalSuffixOf(i) {
  			const j = i % 10,
    			k = i % 100;
  			if (i === 1) {
    			return "First";
  			}
  			if (i === 2) {
    			return "Second";
  			}
  			if (i === 3) {
    			return "Third";
  			}
  			if (j == 1 && k != 11) {
    			return i + "st";
  			}
  			if (j == 2 && k != 12) {
    			return i + "nd";
  			}
  			if (j == 3 && k != 13) {
    			return i + "rd";
  			}
  			return i + "th";
		},
	},
	created() {
		this.resetCheckboxValues();
	},
};
</script>
<style scoped lang="scss">
.container {
	padding: 0px !important;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.checkbox-container {
	.v-input {
		margin-top: 0px;
	}
	i.v-icon {
		font-size: 20px;
	}
	::v-deep label,
	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}
}
.v-expansion-panel-header {
	min-height: 45px;
	padding: 16px 24px;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	text-align: left;
	color: #009aa6;
	.v-icon {
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		color: #009aa6 !important;
	}
	&.text-black {
		color: #000000;
	}
}

.text-previous-selections {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	text-align: left;
	padding-left: 24px;
}

.highlighted, .highlighted ::v-deep .v-label {
	color: #E37222;
}

.highlightedRow {
	background-color: #FEEBDD;
}

// The checkbox.
::v-deep i.v-icon {
	font-size: 20px;
}

::v-deep .v-input--selection-controls__ripple {
	height: 25px;
	width: 25px;
	left: -7px;
	top: calc(50% - 20px);
}

.v-expansion-panel-content {
	padding: 16px 0px 0px 0px !important;
	label {
		height: 24px;
		padding: 4px 0px 4px 0px;
		gap: 16px;
		color: #686a71;
	}
}

.select__title li {
	font-size: 16px;
	display: inline list-item;
	font-weight: 500;
}

.v-list-item:hover {
	background-color: #e0f5f7 !important;
}

.selection-info {
	color: #eb5757;
}

.v-expansion-panel {
	border: 1px solid #e3e3e3;
	border-radius: 4px;
}

::v-deep .v-expansion-panel-content__wrap {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.v-list-item {
	height: 25px;
}

@media screen and (max-width: 600px) {
	.checkbox-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		gap: 8px;
		label {
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
		}
		span {
			font-size: 11px;
			font-weight: 400;
			line-height: 17px;
			text-align: left;
			color: #686A71;
		}
	}

}
</style>
