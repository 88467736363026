<template data-cy="template-0">
	<v-form v-window-focus="load" id="reload" ref="form" :disabled="loading" data-test-selector="cart_reload_form" v-model="formValid" @submit.prevent="handleSubmitForm" data-cy="v-form-0">
		<v-container data-cy="v-container-0">
			<TransactionCard v-model="details" :skeleton="skeleton" :to="null" hide-qr-code  data-cy="TransactionCard-0"/>

			<v-expansion-panels v-model="panel" class="mt-8" multiple data-cy="v-expansion-panels-0">
				<v-expansion-panel class="px-0" :key="groupIdx" v-for="(group, groupIdx) in groups" data-cy="v-expansion-panel-1">
					<v-expansion-panel-header data-cy="v-expansion-panel-header-0">
						<span v-text="group.name" data-cy="span-0"></span>
					</v-expansion-panel-header>
					<v-expansion-panel-content data-cy="v-expansion-panel-content-0">
						<v-row data-cy="v-row-0">
							<v-col :key="product.data.id" v-for="product in group.results" cols="12" lg="6" xl="4" data-cy="v-col-0">
								<ProductWaiverCard :value="product" :skeleton="skeleton" flat tile  data-cy="ProductWaiverCard-0"/>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>
	</v-form>
</template>

<script>
import TransactionCard from '@/components/TransactionCard';
import ProductWaiverCard from '@/components/ProductWaiverCard';
import { EComService, PaginationModel, TransactionModel, ProductModel } from '@connectngo/sdk';

export default {
	name: 'TransactionDetails',

	components: { ProductWaiverCard, TransactionCard },

	metaInfo() {
		return {
			title: this.$i18n.t('account.transactions.details.title', { name: this.details.data.transaction_id }),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('account.transactions.details.title', { name: this.details.data.transaction_id }) },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	data: () => ({
		loading: false,
		formValid: false,
		details: new TransactionModel(),
		products: new PaginationModel([{ product: {} }, { product: {} }, { product: {} }, { product: {} }], ProductModel),
		skeleton: true,
		panel: [0],
	}),

	computed: {
		canSubmit() {
			return !this.loading && this.formValid;
		},
		groups() {
			const groups = [];
			this.products.results.forEach(product => {
				const found = groups.find(group => group.name === product.data.product.name);
				if (!found) {
					groups.push({
						name: this.$options.filters.translatable(product.data.product.name, product.data.product.name_i18n, this.$i18n.locale),
						results: [product],
					})
				} else {
					found.results.push(product);
				}
			});
			return groups;
		},
	},

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getAccount(this.$route.params.sub),
				new EComService().getTransactionDetails(this.$route.params.param1),
				new EComService().getTransactionProducts(this.$route.params.param1),
			])
				.then(([account, details, products]) => {
					this.$route.meta.sub = this.$options.filters.shortUserName(account);
					this.$route.meta.param1 = details.data.transaction_id;
					this.$breadcrumbs.refresh();

					Object.assign(this, { details, products });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		}
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.$route.meta.param1 = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
