<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row class="mb-8" data-cy="v-row-0">
			<v-col cols="12" class="d-flex flex-column justify-center" data-cy="v-col-0">
				<h2 class="display-1 font-weight-light" v-text="$t('account.wallet.title', {
					name: account.data.firstname,
				})" data-cy="h2-0"></h2>
			</v-col>
		</v-row>
		<v-alert v-if="items.results.length === 0" text prominent type="info" data-cy="v-alert-0">
			<span v-text="$t('account.wallet.empty')" data-cy="span-0"></span>
		</v-alert>
		<v-row data-cy="v-row-1">
			<v-col :key="item.guid" v-for="item in items.results" cols="12" lg="6" data-cy="v-col-1">
				<VirtualWalletCard :value="item" :user-name="account.data.firstname" :skeleton="skeleton" :route="{
					name: 'account_section_sub_action_param1', params: {
						section: 'connected',
						sub: account.data.id,
						action: 'wallet',
						param1: item.data.id,
					}
				}"  data-cy="VirtualWalletCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VirtualWalletCard from '@/components/VirtualWalletCard';
import { PaginationModel, EComService, WalletModel, AccountModel } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { VirtualWalletCard },

	metaInfo() {
		return {
			title: this.$i18n.t('account.wallet.title', { name: this.account.data.firstname }),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('account.wallet.title', { name: this.account.data.firstname }) },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	data: () => ({
		account: new AccountModel(),
		skeleton: true,
		loading: false,
		items: new PaginationModel([{}, {}, {}], WalletModel),
	}),

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getAccount(this.$route.params.sub),
				new EComService().getVirtualWallets(this.$route.params.sub),
			])
				.then(([account, walletAccounts]) => {
					this.$route.meta.sub = this.$options.filters.shortUserName(account);
					this.$breadcrumbs.refresh();
					Object.assign(this, { account, items: walletAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
