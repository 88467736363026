var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',_vm._g(_vm._b({directives:[{name:"window-focus",rawName:"v-window-focus",value:(_vm.handleWindowFocus),expression:"handleWindowFocus"}],attrs:{"data-cy":"v-container-0"}},'v-container',_vm.$attrs,false),_vm.$listeners),[_c('Modal',{attrs:{"title":_vm.$t('productWaiverCard.pdfModal.title'),"max-width":"800","data-cy":"Modal-0"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('iframe',{staticClass:"waiver_pdf",attrs:{"src":_vm.waiverPdf.data.base_64,"data-cy":"iframe-0"}})]},proxy:true}]),model:{value:(_vm.showPdf),callback:function ($$v) {_vm.showPdf=$$v},expression:"showPdf"}}),_c('v-form',{ref:"form",attrs:{"id":"reload","disabled":_vm.loading,"data-test-selector":"cart_reload_form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleContinueClick.apply(null, arguments)}},model:{value:(_vm.formReloadValid),callback:function ($$v) {_vm.formReloadValid=$$v},expression:"formReloadValid"}},[_c('h1',{class:{ 'text-h4 text-center': true, 'mb-8': true },attrs:{"data-testid":"reloadTitle"},domProps:{"textContent":_vm._s(
				_vm.$t('cart.reload.mediaTitle', {
					media: _vm.$options.filters.translatable(
						_vm.$root.websiteConfig.data.fields.media_name,
						_vm.$root.websiteConfig.data.fields.media_name_i18n,
						_vm.$i18n.locale
					),
				})
			)}}),(_vm.haveMedia && _vm.hasWaiver)?_c('v-alert',{staticClass:"text-center my-md-12",attrs:{"color":"info","text":"","outlined":""}},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.avoidDelays.title'))}}),_c('p',{staticClass:"mt-2 mb-0"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.avoidDelays.desc'))}}),_c('br'),_c('strong',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.avoidDelays.emphasis'))}})])]):_vm._e(),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.almostDone.title'))}}),_c('v-card-text',[_c('p',{domProps:{"textContent":_vm._s(
								_vm.$t('cart.reload.almostDone.desc', {
									media: _vm.$options.filters.translatable(
										_vm.$root.websiteConfig.data.fields
											.media_name,
										_vm.$root.websiteConfig.data.fields
											.media_name_i18n,
										_vm.$i18n.locale
									),
								})
							)}}),_c('v-img',{attrs:{"src":_vm.$root.websiteConfig.data.images[
									'almost-done-page'
								],"max-height":"300","contain":""}})],1)],1),(!_vm.haveMedia)?_c('v-card',{staticClass:"text-center mt-8"},[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.choseOption.title'))}}),_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.$root.websiteConfig.data.images.media,"max-height":"300","contain":""}}),_c('v-btn',{staticClass:"mt-8",attrs:{"data-testid":"haveWristband","color":"button","block":""},on:{"click":_vm.handleHaveMediaClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-right")]),_c('span',{domProps:{"textContent":_vm._s(
									_vm.$t(
										'cart.reload.choseOption.haveMediaButton',
										{
											media: _vm.$options.filters.translatable(
												_vm.$root.websiteConfig.data
													.fields.media_name,
												_vm.$root.websiteConfig.data
													.fields.media_name_i18n,
												_vm.$i18n.locale
											),
										}
									)
								)}})],1),_c('p',{staticClass:"mt-4",domProps:{"textContent":_vm._s(
								_vm.$t('cart.reload.choseOption.haveDesc', {
									media: _vm.$options.filters.translatable(
										_vm.$root.websiteConfig.data.fields
											.media_name,
										_vm.$root.websiteConfig.data.fields
											.media_name_i18n_pluralize,
										_vm.$i18n.locale
									),
								})
							)}}),_c('div',{staticClass:"my-8 text-center"},[_c('v-divider'),_c('div',{staticClass:"mt-n3 mb-3"},[_c('v-sheet',{staticClass:"d-inline-block px-3",domProps:{"textContent":_vm._s(
										_vm.$t('cart.reload.choseOption.or')
									)}})],1)],1),_c('v-btn',{attrs:{"data-testid":"noWristband","color":"button","block":"","to":{
								name: 'invoice_step',
								params: { step: 'confirmation' },
							}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-right")]),_c('span',{domProps:{"textContent":_vm._s(
									_vm.$t(
										'cart.reload.choseOption.dontHaveMediaButton',
										{
											media: _vm.$options.filters.translatable(
												_vm.$root.websiteConfig.data
													.fields.media_name,
												_vm.$root.websiteConfig.data
													.fields.media_name_i18n,
												_vm.$i18n.locale
											),
										}
									)
								)}})],1),_c('p',{staticClass:"mt-4",domProps:{"textContent":_vm._s(
								_vm.$t('cart.reload.choseOption.dontHaveDesc', {
									media: _vm.$options.filters.translatable(
										_vm.$root.websiteConfig.data.fields
											.media_name,
										_vm.$root.websiteConfig.data.fields
											.media_name_i18n,
										_vm.$i18n.locale
									),
								})
							)}})],1)],1):[_c('v-card',{staticClass:"text-center mt-8"},[_c('v-card-title',{domProps:{"textContent":_vm._s(
								_vm.$t('cart.reload.location.title', {
									media: _vm.$options.filters.translatable(
										_vm.$root.websiteConfig.data.fields
											.media_name,
										_vm.$root.websiteConfig.data.fields
											.media_name_i18n,
										_vm.$i18n.locale
									),
								})
							)}}),_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.$root.websiteConfig.data.images[
										'wallet-uid-image'
									],"max-height":"300","contain":""}})],1)],1),_c('SummaryOrderCard',{staticClass:"mt-8",attrs:{"cart":_vm.invoice,"payments":_vm.payments,"skeleton":_vm.skeleton},on:{"update:cart":function($event){_vm.invoice=$event},"update:payments":function($event){_vm.payments=$event}}})]],2),(_vm.haveMedia)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.assign.title'))}}),_c('v-card-text',[(_vm.reloadItems.results.length === 0)?_c('v-alert',{staticClass:"ma-0",attrs:{"type":"warning","text":""}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.noProduct'))}})]):_vm._e(),_vm._l((_vm.reloadItems.results),function(reloadItem,index){return _c('div',{key:reloadItem.data.id},[(index > 0)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),_c('Product',{attrs:{"product":reloadItem.data.product,"skeleton":_vm.skeleton,"minimal":"","flat":""}}),_c('ReloadAssigner',{staticClass:"mt-4",attrs:{"wallets":_vm.wallets,"reloadItem":reloadItem,"subAccounts":_vm.subAccounts,"rules":[_vm.rules.required],"loading":_vm.loading,"skeleton":_vm.skeleton,"outlined":""},on:{"update:wallets":function($event){_vm.wallets=$event}},model:{value:(_vm.wallets[reloadItem.data.id]),callback:function ($$v) {_vm.$set(_vm.wallets, reloadItem.data.id, $$v)},expression:"wallets[reloadItem.data.id]"}}),_vm._l((reloadItem.data.waivers),function(waiver){return _c('div',{key:waiver.hash,staticClass:"mt-2"},[_c('v-btn',{attrs:{"color":"button","block":"","href":waiver.signed ? null : waiver.url,"target":"_blank","loading":_vm.loadingWaiver === waiver.hash,"disabled":_vm.loadingWaiver === waiver.hash ||
										_vm.loading},on:{"click":function($event){waiver.signed
											? _vm.handleShowWaiverClick(waiver)
											: null}}},[_c('span',{domProps:{"textContent":_vm._s(
											_vm.$t(
												'waiver.' +
													(waiver.signed
														? 'see'
														: 'sign')
											)
										)}})])],1)})],2)})],2)],1)],1):_vm._e()],1),(_vm.haveMedia)?_c('div',[_c('v-btn',{staticClass:"mt-8",attrs:{"color":"button","block":"","large":"","disabled":!_vm.canContinue,"loading":_vm.loading},on:{"click":_vm.handleContinueClick}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.continue'))}}),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"button","outlined":"","block":"","large":"","disabled":_vm.loading},on:{"click":_vm.handleBuyAtTicketBoothClick}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cart.reload.buyAtTickerBooth'))}})])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }