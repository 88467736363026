<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item"  data-cy="v-skeleton-loader-0"/>
	<v-form v-else-if="step === 'start'" :disabled="loading" data-test-selector="gift_card_form_start" v-model="formStartValid" @submit.prevent="handleContinueClick" data-cy="v-form-0">
		<v-card data-cy="v-card-0">
			<v-card-title v-text="$t('cart.gift.enterDetails')" data-cy="v-card-title-0"></v-card-title>
			<v-card-text data-cy="v-card-text-0">
				<v-text-field
					name="number"
					v-model="data.giftCard.number"
					:rules="[rules.required]"
					:label="$t('giftCard.inputNumber')"
					:error-messages="formErrors.number"
					@input="formErrors = {}"
					:disabled="loading"
					:suffix="suffixRequired"
					outlined
				 data-cy="v-text-field-0">
				</v-text-field>

				<v-text-field
					name="serial"
					v-model="data.giftCard.serial"
					:label="$t('giftCard.inputSerial')"
					:error-messages="formErrors.serial"
					@input="formErrors = {}"
					:disabled="loading"
					:suffix="suffixOptional"
					outlined
				>
				</v-text-field>

				<v-checkbox v-model="data.readTerms" :disabled="loading" name="read_terms" class="mt-0" :rules="[rules.required]">
					<template v-slot:label>
				<span>
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
				</span>
					</template>
				</v-checkbox>

				<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canContinue" :loading="loading" block x-large>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</v-form>

	<v-form v-else-if="step === 'summary'" :disabled="loading" data-test-selector="gift_card_form_summary" v-model="formSummaryValid" @submit.prevent="handleContinueClick" lazy-validation>
		<v-card>
			<v-card-title v-text="$t('cart.gift.summary')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="mb-4" v-text="$options.filters.currency(payments.data.balance)"></h3>
			</v-card-text>
		</v-card>
		<v-card class="mt-8">
			<v-card-title v-text="$t('cart.gift.balance')"></v-card-title>
			<v-card-text>
				<v-text-field
					name="balance"
					type="number"
					:prefix="$options.filters.currencySign(123)"
					v-model="details.data.balance"
					:rules="[rules.required]"
					:label="$t('giftCard.balance')"
					:error-messages="formErrors.balance"
					@input="formErrors = {}"
					disabled
					outlined
				></v-text-field>

				<v-text-field
					name="useBalance"
					type="number"
					:prefix="$options.filters.currencySign(123)"
					v-model="data.giftCard.balance"
					:rules="[rules.required, rules.balance, rules.summary, rules.notNegative]"
					:label="$t('giftCard.useBalance')"
					:error-messages="formErrors.useBalance"
					@input="formErrors = {}"
					:disabled="loading"
					outlined
				></v-text-field>

				<v-checkbox v-model="data.readTerms" :disabled="loading" name="read_terms" class="mt-0" :rules="[rules.required]">
					<template v-slot:label>
						<span>
							<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
							&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
						</span>
					</template>
				</v-checkbox>

				<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canContinue" :loading="loading" block x-large>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</v-form>

	<div v-else-if="step === 'pay'">
		<PaymentProcessOverlay :loading="savingInvoice" data-cy="PaymentProcessOverlay-0"/>
		<v-card class="mt-8">
			<v-card-title v-text="$t('cart.gift.partialPayment')"></v-card-title>
			<v-card-text class="text-center">
				<h4 class="text--disabled" v-text="$t('cart.gift.cardNumber', {
					number: data.giftCard.number.toUpperCase()
				})"></h4>
			</v-card-text>
			<v-card-title v-text="$t('cart.gift.amountToUse')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="font-weight-light" v-text="$options.filters.currency(data.giftCard.balance)"></h3>
			</v-card-text>
			<v-card-title v-text="$t('cart.gift.balanceRemainingAfterPayment')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="font-weight-light" v-text="$options.filters.currency(balanceRemainingAfterPayment)"></h3>
			</v-card-text>
			<v-card-title v-text="$t('cart.gift.balanceRemainingToPay')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="mb-4" v-text="$options.filters.currency(balanceRemainingToPay)"></h3>
			</v-card-text>
			<v-card-text>
				<v-btn v-if="balanceRemainingToPay === 0" class="mt-4" x-large color="button" :disabled="paying" :loading="paying" block @click="handlePaymentCompleted">
					<span v-text="$t('btn.pay')"></span>
				</v-btn>
			</v-card-text>
		</v-card>

		<v-card v-if="balanceRemainingToPay > 0" class="mt-8">
			<v-card-text>
				<component
					:is="component"
					:skeleton="skeleton"
					:cart="cart"
					:engine="engine"
					:balance="balanceRemainingToPay"
					@completed="$emit('completed')"
				/>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</div>
</template>

<style>
	.v-text-field__suffix {
		color: rgba(0, 0, 0, 0.6);
	}
</style>

<script>
	import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";
	import Vue from "vue";
	import {
		BaseModel,
		Rules,
		CartModel,
		EventBus,
		PaymentService,
		EComService,
		PaymentModel,
	} from "@connectngo/sdk";

	export default {
		name: "GiftCardForm",

		components: {
			PaymentProcessOverlay,
		},

		props: {
			skeleton: {
				type: Boolean,
				default: false,
			},
			component: {
				type: Vue.component,
				default: null,
			},
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
			engine: {
				type: BaseModel,
				default: () => new BaseModel(),
			},
			cashlessEngine: {
				type: BaseModel,
				default: () => new BaseModel(),
			},
		},

		data: () => ({
			loading: false,
			paying: false,
			success: false,
			error: false,
			step: "start",
			formStartValid: false,
			formSummaryValid: false,
			formPaymentValid: false,
			details: new BaseModel(),
			data: {
				readTerms: false,
				giftCard: {
					number: "",
					serial: "",
					balance: 0,
				},
			},
			steps: ["start", "summary", "pay"],
			formErrors: {},
			rules: {},
			suffix: {
				optional: "(optional)",
				required: "(required)",
			},
			savingInvoice: false,
		}),

		watch: {
			step(newValue, oldValue) {
				if (newValue == 'pay') {
					this.continue();
				}
			},
		},

		computed: {
			canPay() {
				return this.formPaymentValid && !this.paying;
			},
			canContinue() {
				switch (this.step) {
					case "start":
						return this.formStartValid && !this.loading;
					case "summary":
						return this.formSummaryValid && !this.loading;
				}
				return true;
			},
			balanceRemainingAfterPayment() {
				return this.details.data.balance - this.data.giftCard.balance;
			},
			balanceRemainingToPay() {
				return this.payments.data.balance - this.data.giftCard.balance;
			},
			suffixOptional() {
				if (this.data.giftCard.serial === "") {
					return `(${this.$t("suffix.optional")})`;
				}

				return "";
			},
			suffixRequired() {
				if (this.data.giftCard.number === "") {
					return `(${this.$t("suffix.required")})`;
				}

				return "";
			},
		},

		created() {
			this.rules = {
				required: (value) =>
					Rules.required(value) || this.$t("rules.required"),
				balance: (value) =>
					value <= this.details.data.balance || this.$t("rules.balance"),
				summary: (value) =>
					value <= this.payments.data.balance ||
					value === 0 ||
					this.$t("rules.summary"),
				notNegative: (value) => value >= 0 || this.$t("rules.notNegative"),
			};
		},

		methods: {
			handleContinueClick() {
				this.continue();
			},
			handleCancelClick() {
				if (this.step === "start") {
					this.$emit("cancel");
				} else {
					this.step =
						this.steps[
							this.steps.findIndex((item) => item === this.step) - 1
						];
				}
			},
			triggerGAEvent(invoice) {
				const items = [];
				this.cart.data.items.forEach((item, index) => {
					items.push({
						item_id: item.data.product.data.id,
						item_name: this.$options.filters.translatable(
							item.data.product.data.name,
							item.data.product.data.name_i18n,
							this.$i18n.locale
						),
						coupon: item.data.hasDiscount
							? this.$options.filters.translatable(
									item.data.discount.name,
									item.data.discount.name_i18n,
									this.$i18n.locale
							  )
							: null,
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						discount: item.data.discountTotal,
						index: index++,
						item_category: item.data.mainTag
							? this.$options.filters.translatable(
									item.data.mainTag.name,
									item.data.mainTag.name_i18n,
									this.$i18n.locale
							  )
							: null,
						price: item.data.total,
						quantity: item.data.quantity,
					});
				});
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "purchase",
						ecommerce: {
							transaction_id: invoice.data.invoice.transaction_id,
							value: invoice.data.invoice.amount,
							currency:
								this.$root.websiteConfig.data.tenant.currency.code,
							items: items,
						},
					});
				}
			},
			handlePaymentCompleted() {
				this.saveInvoice();
			},
			saveInvoice() {
				this.savingInvoice = true;
				return new EComService()
					.saveInvoice(this.cart.data.id)
					.then((invoice) => {
						this.triggerGAEvent(invoice);
						const createCartCb = () => {
							return new EComService().createCart().then((cart) => {
								this.$root.cart = cart;
								EventBus.publish("CART_UPDATED", cart);
								const nextStep =
									this.$root.websiteConfig.data.fields
										.active_reload === "1"
										? "reload"
										: "confirmation";
								this.$router.push({
									name: "invoice_step",
									params: {
										uuid: invoice.data.invoice.uuid,
										step: nextStep,
									},
								});
							});
						};
						return invoice.data.need_fulfillment
							? new EComService()
									.autoFulfillInvoice(invoice.data.invoice.uuid)
									.then(createCartCb)
							: createCartCb();
					})
					.catch((error) => this.$handleError(this, error))
					.finally(() => (this.savingInvoice = false));
			},
			handleTermsClick() {
				EventBus.publish("OPEN_PAGE", "terms");
			},
			continue() {
				switch (this.step) {
					case "start":
						this.loading = true;
						new PaymentService()
							.getCashlessBalance(
								this.data.giftCard.number,
								this.data.giftCard.serial,
								this.cashlessEngine.data.id,
								this.cart.data.uuid
							)
							.then((balance) => {
								this.details = balance;
								this.step = "summary";
							})
							.catch((error) => this.$handleError(this, error))
							.finally(() => (this.loading = false));
						break;
					case "summary":
						this.pay();
						break;
				}
			},
			pay() {
				this.loading = true;
				this.paying = true;
                Promise.all([
                    new PaymentService().processCashless(
                        this.data.giftCard.number,
                        this.data.giftCard.serial,
                        this.cashlessEngine.data.id,
                        this.cart.data.uuid,
                        this.data.giftCard.balance
                    ),
                ])
                .then(() => {
					this.cart.data.amountToPay = (this.cart.data.total - this.data.giftCard.balance);
					this.cart.data.total = this.cart.data.amountToPay;
					EventBus.publish('CART_UPDATED', this.cart);
					this.$snack(this.$i18n.t('cart.gift.applied'));
					this.step = "pay";
                })
                .catch((error) => {
                    this.error = true;
                    this.$handleError(this, error);
                })
				.finally(() => {
					this.loading = false;
					this.paying = false;
				});
            },
		},
	};
</script>
