<template>
	<Modal v-model="showModal" hide-header :max-width="product.data.image ? 1200 : 600" :persistent="productLoading">
		<template #body>
			<v-container>
				<v-row>
					<v-col sm="6" class="text-right" v-if="product.data.image" >
						<v-img width="100%"
							   :src="product.data.image" class="d-flex" data-cy="v-img-0">
						</v-img>

						<v-btn depressed class="mr-2 d-sm-none d-flex sm-image-close-btn"
							   icon @click="showModal = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>


					<v-col :sm="product.data.image ? 6 : 12">
						<div class="d-flex flex-nowrap text-left transparent" style="justify-content: space-between !important">
							<span class="headline text-truncat" v-text="productTitle"></span>
							<v-btn class="mr-2" :class="{'d-none d-sm-block' : product.data.image}" icon @click="showModal = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
						<div v-if="formattedDescription"  class="mt-4">
							<p class="body-1 font-weight-light">
								<span v-html="formattedDescription"></span>
								<template>
									&nbsp;<a
									class="secondaryDark--text"
									href
									@click.stop.prevent="showAllDesc = !showAllDesc"
									v-text="!showAllDesc ? $t('product.readMore') : $t('product.readLess')"
								></a>
								</template>
							</p>
						</div>
						<v-skeleton-loader v-if="loading" type="card" />
						<ProductCard v-else :product.sync="product"
									 :event-group-ids="eventGroupIds"
									 :global-date="daySelected"
									 @onProductLoading="onProductLoading"
						/>
					</v-col>
				</v-row>

			</v-container>
		</template>
	</Modal>
</template>

<script>
import moment from "moment";
import Modal from "../Modal";
import ProductMixin from "@/mixins/ProductMixin";
import Product from "@/components/Product";
import Text from "@/utils/Text";
import ProductCard from "@/components/ProductCard";
import {EComService, EventBus} from "@connectngo/sdk";

export default {
	name: "MapProductModal",
	mixins: [ProductMixin],
	components: {ProductCard, Product, Modal},
	props : {
		visible : {
			type : Boolean,
			required : true
		},
	},
	mounted() {
		this.eventSubscriber = EventBus.subscribe("CART_UPDATED", (cart) =>
			this.showModal = false
		);
	},
	destroyed() {
		this.eventSubscriber.unsubscribe()
	},

	watch : {
		visible(val) {
			this.showModal= val;
		},
		showModal(val){
			if(!val) {
				this.$emit("mapProductModalClosed");
			}
		},
		daySelected(val) {
			this.$emit("mapProductModalDateChange", val);
		}
	},
	data() {
		return {
			showModal : this.visible,
			pickerDate: null,
			minDate : moment().format('YYYY-MM-DD'),
			showAllDesc: false,
			eventGroupIds: [],
			loading: true,
			productLoading: false,
			eventSubscriber : null,
		}
	},
	created() {
		this.load();
	},
	computed : {
		productTitle() {
			return this.$options.filters.translatable(
				this.product.data.name,
				this.product.data.name_i18n,
				this.$i18n.locale
			)
		},
		formattedDescription() {
			const description = this.$options.filters.translatable(
				"",
				this.product.data.description_i18n,
				this.$i18n.locale
			);
			const trimmed = Text.trimNotWithinWords(
				description.replace(/<\/?[^>]+(>|$)/g, ""),
				250
			);
			return  !this.showAllDesc
				? trimmed.length === description.length
					? trimmed
					: trimmed + "..."
				: description;
		},
	},
	methods : {

		load() {
			this.loading = true;
			new EComService().getCrossSell(this.product.data.slug)
				.then(crossSellProducts => {
				this.crossSellProducts = crossSellProducts;
				this.skeleton = false;

				const eventGroupIdArray = [];
				if (this.product.data.event_group) {
					eventGroupIdArray.push(
						this.product.data.event_group.id
					);
				}
				this.eventGroupIds = eventGroupIdArray;
				if (this.eventGroupIds.length > 0) {
					this.showCalendar = true;
				}

			})
			.catch((reason) => this.$handleError(this, reason))
			.finally(() => (this.loading = false));
		},
		onProductLoading(status) {
			this.productLoading = status;
		},

	}

}
</script>

<style scoped>
	.sm-image-close-btn {
		position: absolute;
		top:16px;right: 10px;
		color:white!important;
		background-color: rgba(0,0,0,0.25);
	}
</style>
