<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item"  data-cy="v-skeleton-loader-0"/>
	<div v-else data-cy="div-0">
		<PaymentProcessOverlay
			:paying.sync="paying"
			:success.sync="success"
			:error.sync="error"
		 data-cy="PaymentProcessOverlay-0"/>
		<v-skeleton-loader v-if="formRequestSent" type="card"  data-cy="v-skeleton-loader-1"/>
		<iframe
			ref="amazon-payment-frame"
			id="amazon-payment-frame"
			name="amazon-payment-frame"
			class="amazon-payment-frame-invisible"
			@load="iframeLoaded"
		 data-cy="iframe-0"></iframe>

		<form ref="amazon-payment-form" :action="formConfig.url" method="post" id="" target="amazon-payment-frame" data-cy="form-0">
			<input type="hidden" name="service_command" :value="formConfig.service_command"  data-cy="input-0"/>
			<input type="hidden" name="language" :value="formConfig.language"  data-cy="input-1"/>
			<input type="hidden" name="merchant_identifier" :value="formConfig.merchant_identifier"  data-cy="input-2"/>
			<input type="hidden" name="access_code" :value="formConfig.access_code"  data-cy="input-3"/>
			<input type="hidden" name="signature" :value="formConfig.signature"  data-cy="input-4"/>
			<input type="hidden" name="return_url" :value="formConfig.return_url"  data-cy="input-5"/>
			<input type="hidden" name="merchant_reference" :value="formConfig.merchant_reference"  data-cy="input-6"/>
		</form>

		<v-checkbox v-model="data.readTerms" :disabled="paying" name="read_terms" class="mt-0" @click.prevent.stop="acceptTermsAndConditions" data-cy="v-checkbox-0">
			<template v-slot:label data-cy="template-1">
				<span data-cy="span-0">
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')" data-cy="span-1"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')" data-cy="a-0"></a>
				</span>
			</template>
		</v-checkbox>
	</div>
</template>

<script>
import { PaymentService, EventBus, CartModel, BaseModel } from '@connectngo/sdk';
import PaymentProcessOverlay from '@/components/PaymentProcessOverlay';

export default {
	name: 'AmazonPaymentForm',

	components: { PaymentProcessOverlay },

	props: {
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		engine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		balance: {
			type: Number,
			default: null
		},
	},

	data: () => ({
		paying: false,
		success: false,
		error: false,
		data: {
			readTerms: false,
		},
		formConfig: {
			access_code: '',
			language: '',
			merchant_identifier: '',
			merchant_reference: '',
			service_command: '',
			signature: '',
			return_url: '',
			url: '',
		},
		formErrors: {},
		acceptedTermsAndConditions: false,
		formRequestSent: false,
		initialIframeLoaded: false,
	}),

	computed: {
		canPay() {
			return this.formPaymentValid && !this.paying;
		},
	},

	methods: {
		handleTermsClick() {
			EventBus.publish('OPEN_PAGE', 'terms');
		},
		acceptTermsAndConditions() {
			this.acceptedTermsAndConditions = !this.acceptedTermsAndConditions;
			if (this.acceptedTermsAndConditions) {
				this.getFormParams();
			} else {
				this.hideIframe();
			}
		},
		receiveMessage (event) {
			if (event.data.status === 'success') {
				this.success = true;

				setTimeout(() => {
					this.success = false;
					this.$emit('completed');
				}, 3000);
			} else if (event.data.status === 'error') {
				this.error = true;
				this.$handleError(this, {errors: [event.data.message]}, this.formErrors, false);
				this.getFormParams();
			}
		},
		getFormParams() {
			this.formRequestSent = true;
			
			new PaymentService().getAmazonPaymentFormValues(
				this.engine.data.id,
				this.cart.data.uuid,
				this.cart.data.amountToPay,
				this.cart.deposit
			)
				.then(response => {
					this.formConfig = response.data.params;

					setTimeout(() => {
						this.$refs['amazon-payment-form'].submit();
					}, 200);
				})
				.catch(error => {
					this.error = true;
					this.$handleError(this, error, this.formErrors, false);
				})
				.finally(() => this.paying = false);
		},
		iframeLoaded() {
			if (this.initialIframeLoaded) {
				this.showIframe();
				this.formRequestSent = false;
			}

			this.initialIframeLoaded = true;
		},
		showIframe() {
			this.$refs['amazon-payment-frame'].className = 'amazon-payment-frame-visible';
		},
		hideIframe() {
			this.$refs['amazon-payment-frame'].className = 'amazon-payment-frame-invisible';
		}
	},

	mounted() {
		window.addEventListener('message', this.receiveMessage)
	},
	beforeDestroy () {
		window.removeEventListener('message', this.receiveMessage)
	}
}
</script>
<style lang="scss" scoped>
	.amazon-payment-frame-visible {
		width: 100%;
		height: 440px;
	}

	.amazon-payment-frame-invisible {
		visibility: hidden;
		width: 0;
		height: 0;
	}
</style>
