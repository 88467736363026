<template data-cy="template-0">
	<v-container data-cy="v-container-0">
		<v-row data-cy="v-row-0">
			<v-col cols="12" lg="6" data-cy="v-col-0">
				<EditContactInfoCard  :value="user" :skeleton="skeleton" @input="handleUpdateContactInfo"  data-cy="EditContactInfoCard-0"/>
				<EditProfileCard :value="user" :skeleton="skeleton" class="mt-8"  data-cy="EditProfileCard-0"/>
			</v-col>
			<v-col cols="12" lg="6" data-cy="v-col-1">
				<EditBillingInfoCard :value="user" :skeleton="skeleton"  data-cy="EditBillingInfoCard-0"/>
				<EditPasswordCard :value="user" :skeleton="skeleton" class="mt-8" data-cy="EditPasswordCard-0"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EditContactInfoCard from '@/components/EditContactInfoCard';
import EditBillingInfoCard from '@/components/EditBillingInfoCard';
import EditPasswordCard from '@/components/EditPasswordCard';
import { PaginationModel, AccountModel, EComService } from '@connectngo/sdk';
import EditProfileCard from '@/components/EditProfileCard';
// import ConnectSocialNetworksCard from '@/components/ConnectSocialNetworksCard';
// import VirtualWalletListingCard from '@/components/VirtualWalletListingCard';

export default {
	name: 'Edit',

	components: { EditContactInfoCard, EditBillingInfoCard, EditProfileCard, EditPasswordCard },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_edit'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_edit') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		skeleton: true,
		loading: false,
		user: new AccountModel(),
		networks: new PaginationModel(),
		virtualWallets: new PaginationModel(),
	}),

	methods: {
		handleUpdateContactInfo(account) {
			Object.assign(this.$root.user.data, account.data);
		},
		load() {
			this.loading = true;
			return Promise.all([
				// new EComService().getVirtualWallets(),
				new EComService().getSession(),
			])
				.then(([user]) => {
				// .then(([virtualWallets, user]) => {
				// 	Object.assign(this, { virtualWallets });
					this.user = user;
					this.networks = new PaginationModel([
						{ text: 'Facebook', icon: 'mdi-facebook', color: 'facebook', active: true },
						{ text: 'Twitter', icon: 'mdi-twitter', color: 'twitter', active: false },
						{ text: 'Linkedin', icon: 'mdi-linkedin', color: 'linkedin', active: false },
					]);
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
