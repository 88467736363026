import { render, staticRenderFns } from "./AdmissionAssignCard.vue?vue&type=template&id=90c44460&scoped=true&data-cy=template-0&"
import script from "./AdmissionAssignCard.vue?vue&type=script&lang=js&"
export * from "./AdmissionAssignCard.vue?vue&type=script&lang=js&"
import style0 from "./AdmissionAssignCard.vue?vue&type=style&index=0&id=90c44460&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c44460",
  null
  
)

export default component.exports