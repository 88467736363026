<template data-cy="template-0">
	<v-sheet color="transparent" data-cy="v-sheet-0">
		<slot v-if="!inProduct" name="prepend-month" data-cy="slot-0"></slot>
		<v-container v-if="!hideDaySelection" :class="{'px-0': !inProduct}" fluid data-cy="v-container-0">
			<v-row data-cy="v-row-0">
				<v-col cols="2" data-cy="v-col-0">
					<v-btn color="secondaryDark" icon :disabled="!canGoBack" @click="handlePreviousClick" data-cy="v-btn-0">
						<v-icon data-cy="v-icon-0">mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8" data-cy="v-col-1">
					<v-dialog
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						:return-value.sync="month"
						transition="scale-transition"
						offset-y
						width="290px"
					 	data-cy="v-dialog-0"
					>
						<template v-slot:activator="{ on, attrs }" data-cy="template-1">
							<v-btn class="d-flex align-center justify-space-between mx-auto" color="secondaryDark" @click="() => monthlyMappedData.length <= 0 && handleDatePickerMonthlyData(tenantNow)" :disabled="!canChangeDate" text v-bind="attrs" v-on="on" data-cy="v-btn-1">
								<span v-text="formattedMonth" data-cy="span-0"></span>
								<v-icon class="pl-1" small data-cy="v-icon-1">mdi-calendar-month</v-icon>
							</v-btn>
						</template>
						<v-date-picker
							v-model="date"
							ref="mainDatePicker"
							:min="formattedTodayDate"
							:events="colorCodeEventDates"
							:disabled="loading || loadingColors"
							:locale="$i18n.locale"
							:allowed-dates="allowedDates"
							scrollable
							color="primary"
							header-color="secondaryDark"
							:picker-date.sync="pickerDate"
							@update:picker-date="handleMonthChange"
							@change="handleDayChange"
						>
							<div class="w-100">
								<v-expand-transition>
									<v-progress-linear v-if="loadingColors" indeterminate class="mb-2"></v-progress-linear>
								</v-expand-transition>
								<v-btn text :disabled="loading || loadingColors" block @click="menu = false">
									<span v-text="$t('btn.close')"></span>
								</v-btn>
							</div>
						</v-date-picker>
					</v-dialog>
				</v-col>
				<v-col cols="2" class="d-flex align-center justify-end">
					<v-btn color="secondaryDark" icon :disabled="!canGoNext" @click="handleNextClick">
						<v-icon>mdi-arrow-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row class="mt-4 d-flex align-center justify-space-between" dense>
				<v-col v-for="(item, index) in dates" :key="index" style="flex: 1">
					<v-btn
						class="pa-0 elevation-0"
						style="min-height: 4.33rem"
						block
						:color="selectedDay !== item.formattedDay ? 'secondaryDark' : 'primary'"
						:outlined="selectedDay !== item.formattedDay"
						:disabled="checkClosedSlot(item)"
						@click="handleDaySelectClick(item)"
					>
						<div class="text-center d-flex flex-column justify-center">
							<div class="mb-2" style="font-size: 0.6rem" v-text="item.date.locale(false).format('ddd')"></div>
							<div class="title font-weight-bold mt-n3 mb-n1" v-text="item.date.locale(false).format('DD')"></div>
							<v-skeleton-loader v-if="skeleton" type="text" height="13"></v-skeleton-loader>
							<div v-else-if="checkClosedSlot(item)" style="font-size: 0.6rem" v-text="$t('calendar.closed')"></div>
							<div v-else-if="formattedTodayDate === item.date.format('YYYY-MM-DD')" class="mx-n3" style="font-size: 0.5rem" v-text="$t('calendar.today')"></div>
						</div>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<slot v-if="!inProduct" name="append-month"></slot>

		<slot v-if="!inProduct"  name="prepend-day"></slot>
		<v-container v-if="!hideTimeSlots" :class="{'px-0': !inProduct}" fluid>
			<v-row v-if="skeleton">
				<v-col cols="12" sm="6" :key="index" v-for="index in [1,2]">
					<v-skeleton-loader type="image" height="70"></v-skeleton-loader>
				</v-col>
			</v-row>
			<v-row v-else-if="currentSlots.length > 0 && currentState !== 'closed' && currentSlots.length <= 4" dense>
				<v-col cols="12" md="6" :key="slot.id" v-for="slot in currentSlots">

				<CalendarSlot
						:period="slot.from.format('LT') + ' - ' + slot.to.format('LT')"
						:remaining="slot.amountLeft"
						:total="slot.totalAmount"
						:selected="value === slot.id"
						:color="value !== slot.id ? 'secondaryDark' : 'primary'"
						:outlined="value !== slot.id"
						:showAvailability="showAvailability"
						:isCrossSale="inProduct"
						:disableSlot="isSlotIdDisabled(slot.id, disabledSlot)"
						@slotMounded="handleMountedSlot(slot)"
						@click="handleSelectTimeSlotClick(slot)"
					/>
				</v-col>
			</v-row>
			<v-row v-else-if="currentSlots.length > 0 && currentState !== 'closed' && currentSlots.length > 4" dense>
				<v-col  cols="12">
					<v-select  :label="$t('select.timeOfVisit')"
								v-model="selectOption"
								:item-disabled="(item)=>isSlotIdDisabled(item.value, disabledSlot)"
							   :items="slotsForSelectDisplay"
							   @change="handleSelectTimeSlotSelect"
							   @click="handleItemMounted"/>
				</v-col>
			</v-row>
		</v-container>
		<slot v-if="!inProduct" name="append-day"></slot>
	</v-sheet>
</template>

<script>
import moment from 'moment';
import momentTZ from 'moment-timezone';
import CalendarSlot from '@/components/CalendarSlot';
import {EComService, EventBus, 	ProductModel} from '@connectngo/sdk';
import globalVariables from "@/global";
import { DebounceMixin } from "@/mixins/ProductMixin";

export default {
	name: 'Calendar',

	mixins: [DebounceMixin],

	components: { CalendarSlot },

	props: {
		value: {
			type: Number,
			default: null,
		},
		eventGroupIds: {
			type: Array,
			default: () => ([]),
		},
		unavailableSlots: {
			type: Array,
			default: () => ([]),
		},
		inProduct: {
			type: Boolean,
			default: false
		},
		hideDaySelection: {
			type: Boolean,
			default: false
		},
		daySelected: {
			type: String,
			default: ''
		},
		hideTimeSlots: {
			type: Boolean,
			default: false
		},
		globalDate: {
			type: String,
			default: ''
		},
		product: {
			type: ProductModel,
			default: () => new ProductModel(),
		},
	},

	data: () => ({
		menu: false,
		month: null,
		selectedDay: null,
		date: null,
		dates: [],
		currentSlots: [],
		slots: [],
		populatedMonth: null,
		eventsPerDate: [],
		loading: false,
		skeleton: true,
		loadingColors: false,
		mappedData: [],
		monthlyMappedData: [],
		currentState: null,
		pickerDate: null,
		disabledSlot: [],
		selectFirstAvailSlot: null,
		selectOption: null,
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		_eventGroupIds() {
			return this.eventGroupIds.filter(eventGroupId => eventGroupId !== 'no_event');
		},
		_unavailableSlots() {
			return this.unavailableSlots.sort((x,y)=> {
				if (x.unavailable_before > y.unavailable_before) {
					return 1;
				}
				if (x.unavailable_before < y.unavailable_before) {
					return -1;
				}
				return 0;
			})[0];
		},
		tenantNow() {
			return this.dateToTenantTimezone().locale('en');
		},
		todayOpenHour() {
			const todayData = this.monthlyMappedData.find(data => data.date === this.formattedTodayDate);
			return todayData?.items?.length > 0 && this.hourDiff(this.tenantNow, todayData.items[todayData.items.length - 1].to);
		},
		formattedTodayDate() {
			return this.tenantNow.clone().startOf('day').format('YYYY-MM-DD');
		},
		formattedMonth() {
			return moment(this.date).format('MMMM YYYY');
		},
		canGoBack() {
			return !this.loading && this.dates.length > 0 && this.dates[0].date.format('YYYY-MM-DD') > moment(this.tenantNow.clone().startOf('day')).locale('en').format('YYYY-MM-DD');
		},
		canGoNext() {
			return !this.loading;
		},
		canChangeDate() {
			return !this.loading;
		},
		showAvailability() {
			return parseInt(globalVariables.websiteConfig.data.fields.show_tickets_availabilities) ? true : false;
		},
		slotsForSelectDisplay() {
			return this.currentSlots.map(item => {
				let label = item.from.format('LT') + ' - ' + item.to.format('LT');
				if(this.showAvailability) {
					label += ` (${this.$tc('calendar.places', item.amountLeft, {
						amount: item.amountLeft
					})})`;
				}

				return {
					value : item.id,
					disabled : item.amountLeft <= 0,
					text : label,
				}
			})
		}

	},

	created() {
		if (this.daySelected) {
			this.populateEventsColorCodes(this.daySelected);
			this.getEventAvailabilities(
				this.dateToTenantTimezone(moment(this.daySelected).toDate()).startOf('day').unix(),
				this.dateToTenantTimezone(moment(this.daySelected).toDate()).endOf('day').unix(),
				true
			);
		} else if(this.globalDate) {
			this.populateEventsColorCodes(this.globalDate);
			this.getEventAvailabilities(
				this.dateToTenantTimezone(moment(this.globalDate).toDate()).startOf('week').unix(),
				this.dateToTenantTimezone(moment(this.globalDate).toDate()).add(1, 'week').endOf('week').unix(),
				true
			);
		}else {
			this.populateEventsColorCodes();
			this.initCalendar();
		}
	},

	methods: {
		handleItemMounted() {
			this.debounce(()=> this.currentSlots.map(item => {
				if(!this.disabledSlot.includes(item.id)) {
					this.handleMountedSlot(item);
				}
			}),500);
		},
		dateToTenantTimezone(date = new Date()) {
			const now = momentTZ(date);
			const serverOffset = now.utcOffset();
			now.tz(this.$root.websiteConfig.data.tenant.timezone);

			const localOffset = now.utcOffset();
			const diffInMinutes = serverOffset - localOffset;
			const tenantDate = moment(date);
			tenantDate.add(-diffInMinutes, 'minutes');

			return tenantDate.clone();
		},
		hourDiff(currentTime, eventEndDate) {
			return eventEndDate.isAfter(currentTime)
		},
		availableSlot(item, slot) {
			return (
				item?.date === this.formattedTodayDate
				&& this.hourDiff(this.tenantNow, slot?.to)
			)
			|| item?.date !== this.formattedTodayDate;
		},
		allowedDates(val) {
			let allowDate;
			if(this.monthlyMappedData.length > 0) {
				if(this.todayOpenHour || val > this.formattedTodayDate) {
					allowDate = this.monthlyMappedData.find(data => data.date === val);
				}
			}
			return allowDate && allowDate.state !== 'closed';
		},
		handlePreviousClick() {
			const date = moment(this.date).subtract(1, 'week');

			// Select day in prev week only if global set for calendar display
			this.selectedDay = this.globalDate === ""  ? null : date.format('YYYY-MM-DD');

			this.getEventAvailabilities(
				Math.max(date.startOf('week').unix(), moment().startOf('day').unix()),
				date.endOf('week').unix(),
				true
			).then(() => this.applyDate(date.format('YYYY-MM-DD')));
		},
		handleNextClick() {
			const date = moment(this.date).add(1, 'week');

			// Select day in next week only if global set for calendar display
			this.selectedDay = this.globalDate === ""  ? null : date.format('YYYY-MM-DD');

			this.getEventAvailabilities(
				date.clone().startOf('week').unix(),
				date.clone().add(1, 'week').endOf('week').unix(),
				true,
			).then(() => this.applyDate(date.format('YYYY-MM-DD')));
		},
		handleDatePickerMonthlyData(date) {
			const Date = moment(date);
			this.getEventAvailabilities(
				Date.startOf('month').unix(),
				Date.endOf('month').unix(),
				true
			);
		},
		handleDayChange(date) {
			this.selectedDay = moment(date).locale('en').format('YYYY-MM-DD');
			this.getEventAvailabilities(
				moment(this.selectedDay).startOf('week').unix(),
				moment(this.selectedDay).add(1, 'week').endOf('week').unix(),
				true
			)
				.then(() => {
					this.selectedDay = moment(date).locale('en').format('YYYY-MM-DD');
					this.date = this.selectedDay;
					this.applyDate(this.date);
					this.$emit('daySelect', this.selectedDay);
					this.menu = false;
				});
		},
		handleDaySelectClick(item) {
			this.selectedDay = item.date.locale('en').format('YYYY-MM-DD');
			this.applyDate(this.selectedDay);
			this.$emit('daySelect', this.selectedDay);
		},
		handleMountedSlot(slot) {
			if (globalVariables.websiteConfig.featureFlagEnabled('co-4126-offset-event-product-availablity')) {
				if (this._unavailableSlots && this._eventGroupIds.includes(parseInt(this._unavailableSlots.eventGroupId))) {
					const disabledSlotTest = slot
						.from
						.isAfter(
							moment.unix(this._unavailableSlots.unavailable_before)
						);
						if (this._unavailableSlots.isUnavailableBefore || disabledSlotTest) {
							this.disabledSlot.push(slot.id);
						}
						if ((this._unavailableSlots.isUnavailableBefore || disabledSlotTest) && (!this.selectFirstAvailSlot || this.selectFirstAvailSlot === slot.id)) {
							this.handleSelectTimeSlotClick(slot);
							this.selectFirstAvailSlot = slot.id;
						}
				}  else if(!this._unavailableSlots) {
					this.disabledSlot.push(slot.id);
				}
				this.disabledSlot = [...new Set(this.disabledSlot)];
			}
		},
		handleSelectTimeSlotClick(slot) {
			let group = this.hideDaySelection || (!this.hideDaySelection && !this.hideTimeSlots) ?
				this._eventGroupIds[0] : null;
			const eventInfo = {
				id: slot.id,
				eventGroup: group,
				slot: slot
			};

			EventBus.publish('timeSlotPickedForGroup'+group, slot);
			EventBus.publish('event', eventInfo);
			this._value = slot.id;

			let label = slot.from.format('LT') + ' - ' + slot.to.format('LT');
			this.selectOption = {
					value : slot.id,
					disabled : slot.amountLeft <= 0,
					text : label,
				};
		},
		handleSelectTimeSlotSelect(id) {
			const slot = this.currentSlots.find(item => item.id === id);
			this.handleSelectTimeSlotClick(slot);
		},
		init() {
			const slot = this.mappedData.find(slot => slot.items.find(item => item.id === this._value));
			if (slot) {
				this.selectedDay = slot.date;
			} else {
				if(this.globalDate !== "" && !this.selectedDay) {
						this.selectedDay = this.globalDate;
				}

				// Go to next available event only if no global date set
				if(this.globalDate === "") {
					const availableSlots = this.mappedData.filter(data => {
						return data.state !== 'closed' && data.items.length > 0;
					});
					if (availableSlots.length > 0) {
						this.selectedDay = availableSlots[0].date;
					} else {
						this.selectedDay = this.mappedData[0]?.date;
					}
				}
			}

			if (!this.eventGroupId) {
				this.$emit('daySelect', this.selectedDay);
			} else {
				this.$emit('daySelect', this.selectedDay, this.eventGroupId);
			}

			if (slot) {
				this.$emit('change', slot);
				this._value = slot.id;
			}
			this.applyDate(this.selectedDay);
		},
		handleMonthChange() {
			if(this.$refs.mainDatePicker) {
				this.populateEventsColorCodes(this.$refs.mainDatePicker.tableDate);
			}
		},
		populateEventsColorCodes(date){
			if(!date) {
				date =  this.month;
			}
			date = moment(date);
			if(this.populatedMonth === date.format('YYYY-MM')) {
				return
			}
			this.populatedMonth = date.locale('en').format('YYYY-MM');
			this.eventsPerDate = [];
			this._eventGroupIds.forEach(eventGroupId => {
				this.loadingColors = true;
				new EComService().getEventAvailabilities(eventGroupId, date.startOf('month').unix(), date.endOf('month').unix())
					.then((response) => {
						this.monthlyMappedData = this.getMappedData(response);
						response.forEach((eventAvailability) => {
							if (eventAvailability.data) {
								this.eventsPerDate[eventAvailability.data.day] = eventAvailability.data.color;
							}
						});
					})
					.finally(() => this.loadingColors = false)
			});
		},
		colorCodeEventDates(date) {
			const eventsPerDate = this.eventsPerDate[date];
			if(this.tenantNow.clone().startOf('day').unix() > moment(date).startOf('day').unix() || !eventsPerDate) {
				return false;
			} else if (eventsPerDate === 'red' || (!this.todayOpenHour && date === this.formattedTodayDate)) {
				return this.$vuetify.theme.themes.light.error;
			} else if (eventsPerDate === 'warning' || eventsPerDate === 'orange' || eventsPerDate === 'yellow') {
				return this.$vuetify.theme.themes.light.warning;
			} else if (eventsPerDate === 'green')  {
				return this.$vuetify.theme.themes.light.success;
			}
		},
		applyDate(date) {
			const selectedDate = this.daySelected ? this.daySelected : this.selectedDay;

			this.date = date;
			this.pickerDate = date;
			this.month = moment(date).locale('en').format('YYYY-MM');
			this.dates = this.getDatesFromWeek(date);

			this.currentSlots = this.mappedData.filter(slot => slot.date === selectedDate).map(item => ({
				...item,
				slots: item.items.filter(slot => this.availableSlot(item, slot)),
			})).map(slot => slot.items).flat();

			if (this.currentSlots.length === 1) {
				this._value = this.currentSlots[0].id;

				if (globalVariables.websiteConfig.featureFlagEnabled('co-4126-offset-event-product-availablity')) {
					let group = this.hideDaySelection ? this._eventGroupIds[0] : null;

					EventBus.publish('timeSlotPickedForGroup'+group, this.currentSlots[0]);
				}
			}
			const filteredMappedData = this.mappedData.filter(slot => slot.date === selectedDate);
			this.currentState = filteredMappedData.length > 0 ? filteredMappedData[0].state : 'closed';

			if(date) {
				this.populateEventsColorCodes(date);
				this._eventGroupIds.forEach(id => {
					EventBus.publish('eventGroupState'+ id, {
						eventGroupState: this.currentState
					});
				});
			}
		},
		getDatesFromWeek(date) {
			const days = [];
			const weekStart = moment(date).clone().locale('en').startOf('week');
			for (let i = 0; i <= 6; i++) {
				const day = moment(weekStart).locale('en').add(i, 'days');
				const slots = day.locale('en').format('YYYY-MM-DD') >= this.formattedTodayDate && this.mappedData.filter(slot => slot.date === day.locale('en').format('YYYY-MM-DD')) || [];

				days.push({
					date: day,
					formattedDay:day.locale('en').format('YYYY-MM-DD'),
					state: (slots[0] || {}).state,
					slots,
				});
			}
			return days;
		},
		getMappedData(slots = []) {
			return slots.map(item => {
				const mappedItem = {
					date: item.data.day,
					state: item.data.closed ? 'closed' : 'open',
					items: item.data.events.map(event => {
						return {
							id: event.data.id,
							from: this.dateToTenantTimezone(new Date(event.data.start_ts * 1000)),
							to: this.dateToTenantTimezone(new Date(event.data.end_ts * 1000)),
							amountLeft: event.data.available,
							totalAmount: item.data.available,
						};
					})
				};

				mappedItem.items = mappedItem.items.filter(item => this.hourDiff(this.tenantNow, item.to));
				mappedItem.state = mappedItem.items.length === 0 ? 'closed' : mappedItem.state;
				return mappedItem;
			});
		},
		getEventAvailabilities(from_date, to_date, applySlots = false) {
			if (isNaN(from_date) || isNaN(to_date)) {
				return;
			}

			const promises = this._eventGroupIds.map(eventGroupId => new EComService().getEventAvailabilities(
				eventGroupId,
				from_date,
				to_date,
				this.product.data ? this.product.data.id : null,
			));

			this.loading = true;
			this.skeleton = true;
			return Promise.all(promises)
				.then(response => {
					if (applySlots) {
						this.slots = response.flat();
					}

					this.skeleton = false;
					return response.flat();
				})
				.finally(() => this.loading = false);
		},
		getEventNextAvailability() {
			return this.getEventAvailabilities(
				this.tenantNow.clone().startOf('day').unix(),
				this.tenantNow.clone().add(1, 'week').endOf('week').unix(),
				true
			)
				.catch(() => {
					this.loadEventAvailabilities();
				})
				.finally(() => {this.loading = false;});
		},
		initCalendar() {
			this.getEventAvailabilities(
				this.tenantNow.clone().startOf('day').unix(),
				this.tenantNow.clone().add(1, 'week').endOf('week').unix(),
				true
			).then(res => {
				this.loading =true;
				const emptyEvents = res.every(item => item.data.events.length === 0);
				if(this._eventGroupIds.length === 1 && (res.length === 0 || emptyEvents)) {
					this.searchForNextEventAvailable();
				} else {
					this.loading = false
				}
			}).catch(() => {
				this.loadEventAvailabilities();
			});
		},
		searchForNextEventAvailable() {
			new EComService().getEventNextAvailability(this._eventGroupIds[0])
				.then(res => {
					if (res.data.start_datetime){
						const nextEventDate = this.dateToTenantTimezone(new Date(res.data.start_datetime * 1000));
						this.selectedDay = nextEventDate.format('YYYY-MM-DD');
						this.handleDayChange(this.selectedDay);
					}
				})
				.catch(err => {
					// No events available in future
				}).finally(() => {this.loading = false;});
		},
		loadEventAvailabilities() {
			const from_date = this.dateToTenantTimezone(this.tenantNow.clone().startOf('week').toDate());
			const to_date = this.tenantNow.clone().endOf('week').unix();
			return this.getEventAvailabilities(from_date, to_date);
		},
		checkClosedSlot(item) {
			return item.slots.length === 0 && item.date.format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
			? item.state === 'closed'
			: !item.slots.find(event => event.state === 'open');
		}
	},

	watch: {
		slots: {
			immediate: true,
			deep: true,
			handler(value) {
				const eventAvailableData = [...new Set(value?.map(data => data.data.day))];
				if(eventAvailableData?.length > 27) {
					this.monthlyMappedData = this.getMappedData(value);
					return;
				}
				this.mappedData = this.getMappedData(value)?.filter(data => {
					return data.date === this.formattedTodayDate
						? data.items.length > 0
						: true
				});

				this.init();
			}
		},
		daySelected: {
			handler(value) {
				this.selectedDay = value;

				this.getEventAvailabilities(
					moment(value).startOf('week').unix(),
					moment(value).add(1, 'week').endOf('week').unix(),
					true,
				)?.then(() => this.applyDate(value));
			}
		}
	},
}
</script>
