<template data-cy="template-0">
	<v-sheet color="systemLight" :class="{
		'fill-height d-flex flex-column': showSidebar,
	}" v-bind="$attrs" v-on="$listeners" data-cy="v-sheet-0">
		<slot name="prepend" data-cy="slot-0"></slot>
		<v-container v-if="showSidebar" :class="{
			'pa-0 fill-height': true,
			'px-6 py-12 pa-print-0': !showSidebar && $vuetify.breakpoint.mdAndUp,
		}" data-cy="v-container-0">
			<v-row no-gutters class="fill-height" data-cy="v-row-0">
				<v-col class="d-print-none" :cols="_sidebarAttrs.cols || 12" :md="_sidebarAttrs.md || 4" :lg="_sidebarAttrs.lg || 3" :xl="_sidebarAttrs.xl || 3" v-bind="sidebarAttrs" style="position: relative" data-cy="v-col-0">
					<v-sheet class="fill-height" data-cy="v-sheet-1">
						<Sticky class="py-6" data-cy="Sticky-0">
							<slot name="sidebar" data-cy="slot-1">
								<Categories v-model="navigableTags" :skeleton="$root.tags === null"  data-cy="Categories-0"/>
							</slot>
						</Sticky>
					</v-sheet>
				</v-col>
				<v-col :cols="_contentAttrs.cols || 12" :md="_contentAttrs.md || 8" :lg="_contentAttrs.lg || 9" :xl="_contentAttrs.xl || 9" v-bind="contentAttrs" class="px-6 py-12" data-cy="v-col-1">
					<ComboNotification v-if="showComboNotification" :combos.sync="combos" textDecorationUnderline fontWeightBold :textH5="isDesktopBreakpoint" :textH6="!isDesktopBreakpoint" :height="isDesktopBreakpoint ? 46 : 48" data-cy="ComboNotification-0"/>

					<v-container class="py-0 d-print-none" data-cy="v-container-1">
						<Breadcrumbs v-if="breadcrumbs" class="pa-0 mb-6"  data-cy="Breadcrumbs-0"/>
					</v-container>

					<slot data-cy="slot-2"></slot>

					<!-- TOP OF THE PAGE -->
					<div v-if="!noTopPage" class="text-center mt-12 d-print-none" data-cy="div-0">
						<GoBackUp data-cy="GoBackUp-0" />
					</div>
				</v-col>
			</v-row>
		</v-container>
		<template v-else data-cy="template-1">
			<ComboNotification v-if="showComboNotification" :combos.sync="combos" textDecorationUnderline fontWeightBold :textH5="isDesktopBreakpoint" :textH6="!isDesktopBreakpoint" :height="isDesktopBreakpoint ? 46 : 48" data-cy="ComboNotification-1"/>
			<Breadcrumbs v-if="breadcrumbs" class="mb-md-6"  data-cy="Breadcrumbs-1"/>

			<slot data-cy="slot-3"></slot>

			<!-- TOP OF THE PAGE -->
			<div v-if="!noTopPage" class="text-center py-12 d-print-none" data-cy="div-1">
				<GoBackUp data-testid="scrollToTop" data-cy="GoBackUp-1" />
			</div>
		</template>
	</v-sheet>
</template>


<script>
import Categories from './Categories';
import GoBackUp from '@/components/GoBackUp'
import Breadcrumbs from '@/components/Breadcrumbs'
import Sticky from '@/components/Sticky'
import { CartModel, EventBus, PaginationModel } from '@connectngo/sdk'
import ComboNotification from '@/components/ComboNotification'
import ComboModal from '@/components/Modals/ComboModal'
import globalVariables from '@/global'
import InteractiveMapCrossSellModal from "@/components/InteractiveMap/InteractiveMapCrossSellModal";

export default {
	name: 'Page',

	components: {InteractiveMapCrossSellModal, ComboModal, ComboNotification, Categories, GoBackUp, Breadcrumbs, Sticky },

	props: {
		noSidebar: {
			type: Boolean,
			default: false,
		},
		noTopPage: {
			type: Boolean,
			default: false,
		},
		breadcrumbs: {
			type: Boolean,
			default: false,
		},
		contentAttrs: {
			type: Object,
			default: () => ({}),
		},
		sidebarAttrs: {
			type: Object,
			default: () => ({}),
		},
		forceShowingSidebar: {
			type: Boolean,
			default: false,
		},
		showCombos: {
			type: Boolean,
			required: false,
			default: false
		},
	},

	data: () => ({
		combos: [],
		comboUpdatedEventBus: null,
		ffCombo: false,
	}),

	computed: {
		showSidebar() {
			return !this.noSidebar && this.$vuetify.breakpoint.mdAndUp
				&& (
					(this.$root.tags !== null && this.navigableTags.results.length > 0)
					|| this.$slots.sidebar
				);
		},
		navigableTags() {
			return new PaginationModel(this.$root.tags.results.filter(result => {
				return result.data.navigable;
			}));
		},
		_contentAttrs() {
			return !this.$slots.sidebar && this.navigableTags.results.length === 0 ? {
				cols: 12,
				md: 12,
				lg: 12,
				xl: 12,
			} : this.contentAttrs;
		},
		_sidebarAttrs() {
			return !this.$slots.sidebar && this.navigableTags.results.length === 0 ? {
				cols: 12,
				md: 12,
				lg: 12,
				xl: 12,
			} : this.sidebarAttrs;
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		},
		showComboNotification() {
			return this.ffCombo && this.showCombos && this.combos.length > 0;
		}
	},
	mounted() {
		if(this.ffCombo) {
			let ref = this;
			this.comboUpdatedEventBus = EventBus.subscribe('COMBO_UPDATED', function (combos) {
				ref.combos = combos;
			});
		}
	},
	created() {
		if (this.$root.combos.length > 0) {
			this.combos = this.$root.combos;
		}

		this.ffCombo = globalVariables.websiteConfig.featureFlagEnabled('combo-v2');
	},
	destroyed () {
		if (this.ffCombo) {
			this.comboUpdatedEventBus.unsubscribe()
		}
	},
}
</script>
