<template data-cy="template-0">
	<Modal v-model="_visible" :title="pdfTitle ? pdfTitle : $t('account.transactions.details.pdfModal.unavailable')"
		max-width="800" scrollable data-cy="Modal-0">
		<template #body data-cy="template-1">
			<iframe v-if="pdfData" :src="'data:application/pdf;base64,' + pdfData.data.base_64"
				class="waiver_pdf" data-cy="iframe-0"></iframe>
		</template>
		<template #buttons>
			<v-btn :disabled="!pdfData" color="button" @click="downloadPdf">
				<span v-text="$t('btn.download')"></span>
			</v-btn>
			<v-btn @click="cancelModal">
				<span v-text="$t('btn.cancel')"></span>
			</v-btn>
		</template>
	</Modal>

</template>

<script>
import Modal from '@/components/Modal';
export default {
	name: 'PdfModal',
	components: { Modal },

	props: {
		showPdf: {
			type: Boolean,
			default: false,
		},
		pdfTitle: {
			type: String,
			default: null,
		},
		pdfData: {
			type: Object,
			default: null,
		},
	},

	methods: {
		downloadPdf() {
			this.$emit('handleDownloadClick');
		},

		cancelModal() {
			this.$emit('handleCancelClick');
		}
	},

	computed: {
		_visible: {
			get() {
				return this.showPdf;
			},
			set(value) {
				this.$emit('handleCancelClick');
			},
		},
	},
}
</script>

<style lang="sass" scoped>

</style>
