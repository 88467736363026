<template data-cy="template-0">
	<v-sheet color="secondaryLight" style="gap: 1rem" class="pa-4 d-flex align-center" v-bind="$attrs" v-on="$listeners"
			 flat data-cy="v-sheet-0">
		<div v-if="creditCardImage" data-cy="div-0">
			<img :src="creditCardImage" style="width: 4rem; float: left" :alt="$t('creditCardPreview.creditCard')" data-cy="img-0"/>
		</div>
		<div class="d-flex flex-column justify-center" style="flex: 1" data-cy="div-1">
			<h5 class="caption text--disabled" v-text="$t('creditCardPreview.creditCard')" data-cy="h5-0"></h5>
			<h3 class="body-1 font-weight-bold text-uppercase" v-text="creditCardTitle" data-cy="h3-0"></h3>
			<h5 class="caption text--disabled" v-text="$t('creditCardPreview.expiresOn', {date:expiryDate})" data-cy="h5-1"></h5>
		</div>
	</v-sheet>
</template>

<script>
export default {
	name: 'CreditCardPreview',

	props: {
		creditCardTitle: {
			type: String,
			default: '',
		},
		creditCardImage: {
			type: String,
			default: null,
		},
		expiryDate: {
			type: String,
			default: null,
		},
		skeleton: {
			type: Boolean,
			default: false,
		}
	},
}

</script>
