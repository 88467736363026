<template data-cy="template-0">

	<v-container data-cy="v-container-0">

		<v-text-field
			v-model="_card.cardHolderName"
			name="ccname"
			prepend-inner-icon="mdi-account"
			:rules="[rules.required]"
			:label="$t('input.ccName')"
			:error-messages="formErrors.name"
			:autofocus="$vuetify.breakpoint.mdAndUp"
			:disabled="loading || manageToken"
			autocomplete="cc-name"
			outlined
			@input="formErrors = {}"
		 data-cy="v-text-field-0"></v-text-field>

		<v-text-field
			v-model="_card.number"
			v-mask="'#### #### #### ####'"
			name="ccnumber"
			prepend-inner-icon="mdi-credit-card-outline"
			:rules="[rules.required, rules.creditCardNumber]"
			:label="$t('input.ccNumber')"
			:error-messages="formErrors.card_number"
			:disabled="loading || manageToken"
			autocomplete="cc-number"
			outlined
			@input="formErrors = {}"
		 data-cy="v-text-field-1"></v-text-field>

		<v-row data-cy="v-row-0">
			<v-col data-cy="v-col-0">
				<v-text-field
					v-model="_card.expiryDate"
					v-mask="'##/##'"
					name="exp"
					prepend-inner-icon="mdi-calendar-blank-outline"
					:rules="[rules.required, rules.creditCardExp]"
					:label="$t('input.ccExp')"
					:error-messages="formErrors.expiration"
					:disabled="loading || manageToken"
					autocomplete="cc-exp"
					outlined
					@input="formErrors = {}"
				 data-cy="v-text-field-2"></v-text-field>
			</v-col>
			<v-col data-cy="v-col-1">
				<v-text-field
					v-model="_card.cvc"
					v-mask="'####'"
					name="cvc"
					prepend-inner-icon="mdi-shield-key-outline"
					:rules="[rules.required, rules.creditCardCvc]"
					:label="$t('input.ccCvc')"
					:error-messages="formErrors.security"
					:disabled="loading || manageToken"
					autocomplete="cc-csc"
					outlined
					@input="formErrors = {}"
				 data-cy="v-text-field-3"></v-text-field>
			</v-col>
		</v-row>

		<v-btn v-if="manageToken" type="submit" color="button" data-test-selector="creditCardDialog_delete" block :disabled="loading" :loading="loading" @click="handleDelete" data-cy="v-btn-0">
			<span v-text="$t('btn.delete')" data-cy="span-0"></span>
		</v-btn>
	</v-container>
</template>

<script>
import { Rules, EComService} from '@connectngo/sdk';

export default {
	name: 'TokenForm',


	props: {
		card: {
			type: Object,
			default: () => ({}),
		},
		manageToken : {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loading: false,
			skeleton: false,
			rules: {},
			formErrors: {},
			toggleDetails: false
		};
	},

	computed: {
		_card: {
			get() {
				return this.card;
			},
			set(card) {
				this.$emit('input', card);
			},
		},
		hideDetails() {
			return ! this.manageToken || this.toggleDetails;
		}
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},
		handleDelete() {
			this.loading = true;
			new EComService().deleteToken(this.card.id, this.card.walletId)
				.then()
				.catch()
				.finally(() => this.loading = false);
		},
		load() {
			this.skeleton = false;
			this.loading = false;

		},
	},

	created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			creditCardNumber: value => Rules.creditCardNumber(value) || this.$t('rules.creditCardNumber'),
			creditCardCvc: value => Rules.creditCardCvc(value) || this.$t('rules.creditCardCvc'),
			creditCardExp: value => Rules.creditCardExp(value) || this.$t('rules.creditCardExp'),
			zipPostal: value => Rules.zipCode(value) || Rules.postalCode(value) || this.$t('rules.creditCardZipPostal'),
		};
		this.load();
	},
}
</script>
