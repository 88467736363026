<template data-cy="template-0">
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners"
		@close="cancelModal"
		:max-width="modalMaxWidth" data-cy="Modal-0">

		<template #body data-cy="template-1">
			<div v-if="!loading" data-cy="div-0">
				<div style="margin:0 auto;" id="combo-carousel-experimental" :class="{ 'px-8': isDesktopBreakpoint, 'px-4': !isDesktopBreakpoint && showDetail }" data-cy="div-1" :key="componentKey">
					<h3 v-if="combos.length > 1 && !showDetail"  class="mb-6 text-center" data-cy="h3-0">{{$t('comboModal.modal_title_multiple')}}</h3>
					<component
						:key="componentKey"
						:is="currentComponent"
						v-bind="currentProps"
						:cancelModal="cancelModal"
						@showList="showList"
						@showDetail="showDetailHandler"
					/>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ComboItem from '@/components/ComboItemExperimental';
import { Carousel, Slide } from 'vue-carousel';
import CombosList from '@/components/CombosList';

export default {
	name: 'ComboModalExperimental',
	components: {
		Modal,
		ComboItem,
		Carousel,
		Slide,
		CombosList
	},
	props: {
		showComboModal: {
			type: Boolean,
			default: false,
		},
		combos: {
			type: Array,
			required: false,
			default: [],
		},
	},

	data() {
		return {
			loading: false,
			allDone: false,
			currentComponent: 'CombosList',
			selectedCombo: null,
			showDetail: false,
			componentKey: 0,
		}
	},

	mounted() {
		if (this.combos.length === 1) {
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos[0];
			this.showDetail = true;
		}
	},

	methods: {
		cancelModal() {
			this.currentComponent = 'CombosList';
			this.$emit('handleComboModalCloseClick');
		},
		refreshCarousel() {
    		this.$nextTick(() => {
      			const event = new Event('resize');
      			window.dispatchEvent(event);
    		});
  		},
		showDetailHandler(comboId) {
			this.showDetail = true;
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos.find(combo => combo.data.id === comboId);
		},
		showList() {
			this.currentComponent = 'CombosList';
			this.showDetail = false;
			this.componentKey++;
		},
	},

	computed: {
		_visible: {
			get() {
				return this.showComboModal;
			},
			set() {
				this.$emit('handleComboModalCloseClick');
			},
		},
		modalMaxWidth() {
			if (!this.showDetail && this.isDesktopBreakpoint) {
				return 1300
			} else if (this.showDetail && this.isDesktopBreakpoint) {
				return 969
			} else {
				return 500
			}
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		},
		currentProps() {
			if (this.currentComponent === 'ComboItem') {
				return {
					combo: this.selectedCombo.data,
					showDetail: this.showDetail,
					cancelModal: this.cancelModal,
					isSingleCombo: this.combos.length === 1,
				}
			} else if (this.currentComponent === 'CombosList') {
				return {
					combos: this.combos,
					showComboModal: this.showComboModal,
					cancelModal: this.cancelModal,
					key: this.componentKey
				}
			}
		}
	},
	updated() {
		this.refreshCarousel()
	},
	created() {
		if (this.combos.length === 1) {
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos[0];
		}
	},
	watch: {
		modalMaxWidth: {
			handler: function (val) {
				this.refreshCarousel();
				this.componentKey++;
			},
		}
	}
}
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 95%;
}

#combo-carousel-experimental {
	.combo-container {
		> h3 {
			font-size: 22px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
		}
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	.combo-title {
		font-size: 22px;
		font-weight: 600;
		line-height: 28px;
		text-align: center;
	}

	@media screen and (max-width: 600px) {
		h3 {
			padding-left: 24px;
			padding-right: 24px;
			font-size: 22px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
		}
	}
}
</style>
