<template data-cy="template-0">
	<Page color="neutralLight" no-sidebar data-cy="Page-0">
		<v-container class="py-md-0 pt-md-6 pa-print-0" data-cy="v-container-0">
			<v-stepper v-model="step" class="d-print-none" data-cy="v-stepper-0">
				<v-stepper-header data-cy="v-stepper-header-0">
					<template v-for="(item, itemIdx) in _steps" data-cy="template-1">
						<v-divider :key="itemIdx + '_div'" v-if="itemIdx > 0" data-cy="v-divider-0"></v-divider>
						<v-stepper-step :editable="!_steps[itemIdx].disabled && step > itemIdx + 1" edit-icon="mdi-check" :key="itemIdx + '_item'" :complete="step > itemIdx + 1" :step="itemIdx + 1" data-cy="v-stepper-step-0">
							<span v-text="$t('cart.' + item.key + '.title')" data-cy="span-0"></span>
						</v-stepper-step>
					</template>
				</v-stepper-header>
			</v-stepper>

			<v-skeleton-loader v-if="skeleton" type="image" class="mt-8"></v-skeleton-loader>
			<component v-else v-bind="_steps[step -1]" :invoice.sync="invoice" :payments.sync="payments" @next="handleNext"></component>
		</v-container>
	</Page>
</template>

<script>
import Page from '@/components/Page'
import Payment from '@/routes/App/Invoice/Payment'
import Reload from '@/routes/App/Invoice/Reload'
import Confirmation from '@/routes/App/Invoice/Confirmation'
import { EComService, TransactionModel, PaymentModel } from '@connectngo/sdk'

export default {
	name: "Invoice",

	components: { Page },

	metaInfo() {
		return {
			title: this.$i18n.t('route.invoice'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.invoice') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		loading: false,
		skeleton: true,
		step: 4,
		invoice: new TransactionModel(),
		payments: new PaymentModel(),
	}),

	computed: {
		_steps() {
			return this.steps.filter(step => step.enabled !== false);
		},
		steps() {
			const reloadEnabled = this.$root.websiteConfig.data.fields.active_reload === "1";
			return [
				{ key: 'welcome', disabled: true },
				{ key: 'identification', disabled: true },
				{ key: 'assignment', disabled: true },
				{ key: 'payment', is: Payment, class: "mt-8 pa-0"},
				{ key: 'reload', is: Reload, class: "mt-8 pa-0", enabled: reloadEnabled},
				{ key: 'confirmation', is: Confirmation, class: "mt-8 pa-0 text-center" },
			];
		}
	},

	watch: {
		step(value) {
			const step = this._steps[value - 1] || {};
			if (step.key !== this.$route.params.step && step.enabled) {
				this.$router.push({ name: 'invoice_step', params: { step: step.key } });
			}
		},
	},

	methods: {
		handleNext() {
			const step = this._steps[this.step];
			if (step) {
				this.$router.push({ name: 'invoice_step', params: { step: step.key } });
			}
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getTransactionDetails(this.$route.params.uuid),
			])
				.then(([invoice]) => {
					Object.assign(this, { invoice, payments: new PaymentModel({
						payments: invoice.data.payments,
					}) });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load().then(() => {
			let stepIdx = this._steps.findIndex(step => step.key === this.$route.params.step);
			if (stepIdx !== -1) {

				if (this._steps[stepIdx].enabled === false) {
					stepIdx--;
				}

				this.step = stepIdx + 1;
			}
		});
	},
}
</script>

