<template data-cy="template-0">
	<div data-cy="div-0">
		<v-row v-if="_max === _min" v-bind="$attrs" class="py-5 ma-0 mx-1" data-cy="v-row-0">
			<v-col cols="6" class="d-flex justify-left align-center pa-0 pl-3" data-cy="v-col-0">
				<span class="body-1 font-weight-black text-capitalize-fl" data-cy="span-0">
					<span v-text="$options.filters.translatable(value.data.name, value.data.name_i18n)" data-cy="span-1"></span>
					<span data-cy="span-2">&nbsp;x &nbsp;{{ _quantity }} </span>
					<span v-if="_hasError" class="error--text" data-cy="span-3">&nbsp;*</span>
				</span>
			</v-col>
			<v-col cols="6" v-if="!checkDisabledModifier"  data-cy="v-col-1">
				<h4 class="red--text" v-text="'*'+$t('slot.noModifiers') + availableAfter">
				</h4>
			</v-col>
			<v-col cols="6" v-else-if="value.data.price > 0" class="d-flex justify-end pa-0 ma-0 pr-4" data-cy="v-col-1">
				<span class="body-1 font-weight-black text-capitalize-fl" v-text="$options.filters.currency(value.data.price)" data-cy="span-4">
				</span>
			</v-col>
		</v-row>
		<v-row v-else-if="!(_max === _min)" data-cy="v-row-1">
			<v-col cols="6" md="7" class="d-flex flex-column justify-center text-left" data-cy="v-col-2">
				<span class="body-1 font-weight-black text-capitalize-fl" data-cy="span-5">
					<span v-text="$options.filters.translatable(value.data.name, value.data.name_i18n)" data-cy="span-6"></span>
					<span v-if="_hasError" class="error--text" data-cy="span-7">&nbsp;*</span>
				</span>
				<span class="font-italic" data-cy="span-8">
					<span v-if="value.data.msrp" class="text-decoration-line-through mr-1" v-text="$options.filters.currency(value.data.msrp)" data-cy="span-9"></span>
					<span v-text="$t('product.priceEach', {
						price: $options.filters.currency(value.data.price)
					})" data-cy="span-10"></span>

					<v-expand-transition v-if="ready" data-cy="v-expand-transition-0">
						<h5 v-if="_quantity > 0" class="text-h5 font-weight-bold" v-text="$options.filters.currency(value.data.price * _quantity)" data-cy="h5-0"></h5>
					</v-expand-transition>
				</span>
			</v-col>
			<v-col cols="6" md="5" class="d-flex flex-column align-end justify-center" data-cy="v-col-3">

				<!-- CHECKBOX -->
				<template v-if="!checkDisabledModifier">
				<h4 class="red--text" v-text="'*'+$t('slot.noModifiers') + availableAfter">
				</h4>
				</template>
				<template v-else-if="selectable" data-cy="template-1">
					<v-checkbox
						v-model="_selected"
						:disabled="!canSelect"
						background-color="white"
						class="ma-2 mt-1 mr-4"
						style="width: 1.5rem"
						hide-details
						data-cy="v-checkbox-0"></v-checkbox>
				</template>

				<!-- INPUT -->
				<template v-else>
					<InputNumericLogic
						v-if="!readonly"
						v-model="_quantity"
						:hasError="hasQuantityError"
						:has-quantity="hasQuantity"
						:min="_min"
						:max="_max"
						:readonly="_readonly"
						:loading="loading"
					/>
					<v-chip v-else class="px-6 title font-weight-black" large v-text="_quantity"></v-chip>
				</template>
			</v-col>
		</v-row>

		<!-- MIN/MAX ERRORS -->
		<template v-if="ready && !(_max ===_min)">
			<v-expand-transition>
				<div v-if="required && quantity === 0">
					<v-alert color="error" class="ma-0 mt-2" text dense>
						<span v-text="$t('modifier.error.required')"></span>
					</v-alert>
				</div>
			</v-expand-transition>
			<v-expand-transition>
				<div v-if="hasQuantityError && _min !== null && _min > _quantity">
					<v-alert color="error" class="ma-0 mt-2" text dense>
						<span v-text="$tc('product.error.needAtLeast', _min, { quantity: _min })"></span>
					</v-alert>
				</div>
			</v-expand-transition>
			<v-expand-transition>
				<div v-if="hasQuantityError && _max !== null && _quantity > _max">
					<v-alert color="error" class="ma-0 mt-2" text dense>
						<span v-text="$tc('product.error.atMaximum', _max, { quantity: _max })"></span>
					</v-alert>
				</div>
			</v-expand-transition>
		</template>
	</div>
</template>

<script>
import { InputNumericLogic } from '@/mixins/ProductMixin';
import { BaseModel } from '@connectngo/sdk';

export default {
	name: 'Modifier',

	components: { InputNumericLogic },

	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel()
		},
		quantity: {
			type: Number,
			default: 0,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		disableModifier: {
			type: Array,
			default: () => ([])
		},
		modifierCutoff: {
			type: String,
			default: null
		}
	},

	data: () => ({
		ready: false,
	}),

	computed: {
		checkDisabledModifier() {
			const modifier = this.disableModifier?.find(data => data.slotId === this.value.data.id)
			if (modifier) {
				return modifier.available;
			}
			return true;
		},
		availableAfter() {
			if (this.disableModifier?.length > 0 && !this.checkDisabledModifier) {
				return this.modifierCutoff;
			}

			return '';
		},
		canSelect() {
			return !this.loading && !this.readonly;
		},
		_readonly() {
			//TODO: remove max_quantity for modifiers since it doesnt actually exists especially in event groups
			// this.value.data.max_quantity = 1000;
			return this.readonly || this.loading
				|| (this.value.data.checkbox && !this._selected)
				|| (this.value.data.min_quantity === 0 && this.value.data.max_quantity === 0);
		},
		_quantity: {
			get() {
				let quantity = (this.quantity === 0 && this.required)
					? this.value.data.min_quantity
					: this.quantity;

				this.$emit('update:quantity', quantity)
				return quantity;
			},
			set(quantity) {
				this.$emit('update:quantity', quantity);
				this.$forceUpdate();
			},
		},
		_selected: {
			get() {
				return this.selected;
			},
			set(selected) {
				this.$emit('update:selected', selected);
				this.$emit('update:quantity', selected ? 1 : 0);
			},
		},

		hasQuantity() {
			return this._min !== null && this._max !== null;
		},
		_min() {
			return this.value.data.min_quantity;
		},
		_max() {
			return this.value.data.max_quantity;
		},
		_hasError() {
			return this.hasQuantityError || this.hasError;
		},
		hasQuantityError() {
			return (this._min !== null && this._min > this.quantity)
				|| (this._max !== null && this.quantity > this._max)
				|| (this.required && this.quantity === 0);
		}
	},

	created() {
		if (this._quantity === 0) {
			this._quantity = this.value.data.min_quantity;
		}

		// Hack: ProductMixin quantity is set after a timeout to solve another issue, and it's to intertwined
		// to fix it properly.
		setTimeout(() => {
			this.ready = true;
		})
	},
}
</script>
<style lang="scss" scoped>
.top-border {
	border-top: 1px solid #E0E0E0;
}

.bottom-border {
	border-bottom: 1px solid #E0E0E0;
}
</style>
