<template data-cy="template-0">
	<v-card color="#333" class="fill-height" :height="height" flat v-on="$listeners" data-cy="v-card-0">
		<video v-if="playing" ref="video" :src="video" height="100%" v-bind="$attrs" data-test-selector="media_video_tag" autoplay @ended="handleVideoEnded" class="v-responsive w-100" data-cy="video-0"></video>
		<v-img v-else :src="image" height="100%" contain data-cy="v-img-0">
			<v-overlay v-if="type === 'video' && purchased" absolute opacity=".3" data-cy="v-overlay-0">
				<div style="border-radius: 50%; backdrop-filter: blur(0.25rem)" data-cy="div-0">
					<v-btn v-if="playable" icon x-large @click="handlePlayClick" data-cy="v-btn-0">
						<v-icon color="white" :size="96" data-cy="v-icon-0">mdi-play-circle-outline</v-icon>
					</v-btn>
					<v-icon v-else color="white" :size="96" data-cy="v-icon-1">mdi-play-circle-outline</v-icon>
				</div>
			</v-overlay>
		</v-img>
	</v-card>
</template>

<script>
import { MediaModel } from '@connectngo/sdk';

export default {
	name: 'Media',

	props: {
		value: {
			type: MediaModel,
			default: () => new MediaModel(),
		},
		thumbnail: {
			type: Boolean,
			default: false,
		},
		height: {
			type: [Number,String],
			default: null,
		},
		playable: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		playing: false,
	}),

	computed: {
		image() {
			return this.thumbnail
				? this.value.data.url_thumbnail
				: this.purchased
					? this.type === 'video'
						? this.value.data.url_thumbnail
						: this.value.data.url
					: this.value.data.url_preview
		},
		video() {
			return this.purchased
				? this.value.data.url
				: this.value.data.url_preview;
		},
		purchased() {
			return this.value.data.status === 'PURCHASED';
		},
		type() {
			return this.value.data.media_type.indexOf('video') === 0
				? 'video'
				: 'image';
		},
	},

	methods: {
		handleVideoEnded() {
			this.playing = false;
		},
		handlePlayClick() {
			this.play();
		},
		handleVisibilityChange() {
			if (typeof this.$refs.video !== 'undefined') {
				const hidden = document.hidden || document.msHidden || document.webkitHidden || document.mozHidden;
				hidden ? this.stop() : this.play();
			}
		},
		play() {
			this.playing = true;
		},
		stop() {
			this.playing = false;
		},
	},

	created() {
		document.addEventListener("msvisibilitychange", this.handleVisibilityChange, false);
		document.addEventListener("webkitvisibilitychange", this.handleVisibilityChange, false);
	},

	destroyed() {
		document.removeEventListener('msvisibilitychange', this.handleVisibilityChange, false);
		document.removeEventListener('msvisibilitychange', this.handleVisibilityChange, false);
	},
}
</script>
