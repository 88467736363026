<template data-cy="template-0">
	<v-skeleton-loader v-if="skeleton" type="card-heading,card-heading,card-heading,card-heading,card-heading" data-cy="v-skeleton-loader-0"></v-skeleton-loader>
	<v-list v-else v-bind="$attrs" v-on="$listeners" data-cy="v-list-0">
		<template v-for="(item, itemIdx) in value.results" data-cy="template-1">
			<v-divider v-if="itemIdx > 0" :key="itemIdx + '_div'" data-cy="v-divider-0"></v-divider>
			<v-list-group :value="itemIdx === index" v-if="item.data.products?.length > 0" :key="itemIdx + '_group'" no-action data-cy="v-list-group-0">
				<template #activator data-cy="template-2">
					<v-list-item :key="itemIdx + '_uniq'" :to="getTagLink(item)" data-cy="v-list-item-0">
						<v-list-item-content data-cy="v-list-item-content-0">
							<v-list-item-title class="flexible text-capitalize-fl" v-text="$options.filters.translatable(item.data.name, item.data.name_i18n, $i18n.locale)" data-cy="v-list-item-title-0"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
				<v-list-item v-for="(child, childIdx) in item.data.products" :to="getTagChildLink(item, child)" :key="childIdx">
					<v-list-item-content>
						<v-list-item-title class="flexible text-capitalize-fl" v-text="$options.filters.translatable(child.data.name, child.data.name_i18n, $i18n.locale)"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</template>
	</v-list>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'Categories',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		index: null,
	}),

	computed: {
		_index: {
			get() {
				return this.index;
			},
			set(value) {
				this.index = value;
			},
		},
		isTimeBasedCombos() {
			return this.$root.websiteConfig.data.feature_flags.includes('co-5754-time-based-combos');
		}
	},

	created() {
		if (!this.value) {
			return;
		}

		loopMain: for (let i = 0; i < this.value.results.length; i++) {
			const result = this.value.results[i];

			if (this.$route.name === 'tag'
			&& result.data.slug === this.$route.params.key) {
				this._index = i;
				break loopMain;
			}

			if (result.data.products.length > 0) {
				for (let y = 0; y < result.data.products.length; y++) {
					const product = result.data.products[y];
					if (product.data.slug === this.$route.params.slug) {
						this._index = i;
						break loopMain;
					}
				}
			}
		}
	},
	methods: {
		getTagLink(item) {
			return {
				name: 'tag',
				params: {
					key: item.data?.slug
				}
			}
		},
		getTagChildLink(item, child) {
			return {
				name: child.data.is_combo && this.isTimeBasedCombos ? 'combo' : 'product',
				params: {
					slug: child.data.slug,
				},
			};
		}
	}
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader ::v-deep .v-skeleton-loader__heading {
	width: 80%;
}
</style>
