<template data-cy="template-0">
	<v-container v-bind="$attrs" v-on="$listeners" data-cy="v-container-0">
		<v-row data-cy="v-row-0">
			<v-col cols="12" md="6" data-cy="v-col-0">
				<v-card data-cy="v-card-0">
					<v-card-text data-cy="v-card-text-0">
						<v-alert text prominent type="success" class="mb-0" data-cy="v-alert-0">
							<span
								v-text="$t('invoice.payment.completed')"
							 data-cy="span-0"></span>
						</v-alert>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" data-cy="v-col-1">
				<SummaryOrderCard
					:cart.sync="invoice"
					:payments.sync="payments"
					:skeleton="skeleton"
				 data-cy="SummaryOrderCard-0"/>
			</v-col>
		</v-row>

		<v-btn
			class="mt-8"
			color="button"
			block
			large
			:disabled="!canContinue"
			:loading="loading"
			@click="handleContinueClick"
		 data-cy="v-btn-0">
			<span v-text="$t('btn.continue')" data-cy="span-1"></span>
			<v-icon right data-cy="v-icon-0">mdi-arrow-right</v-icon>
		</v-btn>
	</v-container>
</template>

<script>
import SummaryOrderCard from "@/components/SummaryOrderCard";
import { TransactionModel, PaymentModel } from "@connectngo/sdk";

export default {
	name: "Payment",

	components: { SummaryOrderCard },

	props: {
		invoice: {
			type: TransactionModel,
			default: () => new TransactionModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		loading: false,
		skeleton: true,
	}),

	computed: {
		_invoice: {
			get() {
				return this.invoice;
			},
			set(value) {
				this.$emit("update:invoice", value);
			},
		},
		canContinue() {
			return !this.loading && !this.skeleton;
		},
	},

	methods: {
		handleContinueClick() {
			this.$router.push({
				name: "invoice_step",
				params: { uuid: this.invoice.data.uuid, step: "reload" },
			});
		},
		load() {
			this.skeleton = false;
		},
	},

	created() {
		this.load();
	},
};
</script>
